import { Button, Col, Form, Input, message, Row, Space, Switch, Select } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useState } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Status } from '../../../../common/constants';
import ImageUpload from '../../../common/imageUpload';
import { property } from 'lodash';
const { TextArea } = Input;
const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 13 },
};

const tailLayout = {
  wrapperCol: { offset: 5, span: 16 },
};

const CheckInProcessForm: React.FC<any> = ({ setFormOpen, checkInProcessDetails, checkInProcessId }): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  // const [status, setStatus] = useState<string>('active');
  const [displayWorkingHours, setDisplayWorkingHours] = useState<string>('inactive');
  // const [checkValue, setCheckValue] = useState<boolean>(true);
  const [displayWorkingHoursCheckValue, setDisplayWorkingHoursCheckValue] = useState<boolean>(true);
  const [icon, setIcon] = useState();
  const [iconUpdated, setIconUpdated] = useState(false);
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { createCheckInProcessDetail, updateCheckInProcessDetail, getCheckInProcessDetail } = useStoreActions<any>((actions) => ({
    createCheckInProcessDetail: actions.property.createCheckInProcessDetail,
    updateCheckInProcessDetail: actions.property.updateCheckInProcessDetail,
    getCheckInProcessDetail: actions.property.getCheckInProcessDetail,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const {
    getCheckInProcessListSuccess,
    getCheckInProcessListError,
    createCheckInProcessSuccess,
    createCheckInProcessError,
    updateCheckInProcessSuccess,
    updateCheckInProcessError,
  } = useStoreState<any>((state) => ({
    createCheckInProcessSuccess: state.property.createCheckInProcessSuccess,
    createCheckInProcessError: state.property.createCheckInProcessError,
    updateCheckInProcessSuccess: state.property.updateCheckInProcessSuccess,
    updateCheckInProcessError: state.property.updateCheckInProcessError,
    getCheckInProcessListSuccess: state.property.getCheckInProcessListSuccess,
    getCheckInProcessListError: state.property.getCheckInProcessListError,
  }));

  const displayWorkingHoursChange = useCallback((value) => {
    setDisplayWorkingHoursCheckValue(value);
    if (value) {
      setDisplayWorkingHours('active');
    }
    else {
      setDisplayWorkingHours('inactive');
    }
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const renderType = useCallback(() => {
    return <Select  >
      <Option value="both">Both</Option>
      <Option value="serviced_accommodation">Serviced Accommodation</Option>
      <Option value="hotels">Hotels </Option>
    </Select>
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (checkInProcessId) {
      // setCheckValue((checkInProcessDetails.status === 'active') ? true : false);
      // setStatus(checkInProcessDetails.status);
      setDisplayWorkingHours(checkInProcessDetails.displayWorkingHours);
      form.setFieldsValue({
        name: checkInProcessDetails.name,
        icon: checkInProcessDetails.iconLocation,
        // status: checkInProcessDetails.status,
        displayWorkingHours: checkInProcessDetails.displayWorkingHours,
        alternativeCheckInProcess: checkInProcessDetails.alternativeCheckInProcess,
        propertyType: checkInProcessDetails.propertyType
      });

      setDisplayWorkingHoursCheckValue((checkInProcessDetails.displayWorkingHours === Status.ACTIVE) ? true : false);

    } else {

      setDisplayWorkingHoursCheckValue(false);
      // setStatus('active');
      setDisplayWorkingHours('inactive');
      // setCheckValue(true);
    }
  }, [checkInProcessDetails, checkInProcessId, form]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getCheckInProcessDetail();
  }, [getCheckInProcessDetail]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getCheckInProcessListSuccess) {

    }
    if (getCheckInProcessListError) {
      message.error(getCheckInProcessListError.message)
    }
  }, [getCheckInProcessListError, getCheckInProcessListSuccess]);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (createCheckInProcessSuccess) {
      message.success('check-in process created successfully');
      setTimeout(() => setFormOpen(false), 1000);
      getCheckInProcessDetail();
    }

    if (updateCheckInProcessSuccess) {
      message.success('check-in process updated successfully');
      setTimeout(() => setFormOpen(false), 1000);
      getCheckInProcessDetail();
    }

    if (createCheckInProcessError) {
      message.error(createCheckInProcessError.message)
    }

    if (updateCheckInProcessError) {
      message.error(updateCheckInProcessError.message)
    }
    setLoading(false);
  }, [createCheckInProcessSuccess, createCheckInProcessError, updateCheckInProcessSuccess, updateCheckInProcessError, getCheckInProcessDetail, setFormOpen]);

  ///////////////////////////////////////////////////////////////////////////////////////////
  const getImageBlob = useCallback((imageBlob) => {
    setIcon(imageBlob);
    setIconUpdated(true);
  }, []);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onFinish = useCallback((values) => {
    setLoading(true);
    if (checkInProcessId) {
      values.id = checkInProcessId;
      const data = {
        id: checkInProcessId,
        name: values.name,
        icon: iconUpdated ? icon : values.icon,
        // status: values.status ? status : 'inactive',
        displayWorkingHours: values.displayWorkingHours ? displayWorkingHours : 'inactive',
        alternativeCheckInProcess: values.alternativeCheckInProcess,
        propertyType: values.propertyType
      }
      updateCheckInProcessDetail(data);

    } else {
      values.icon = icon;
      values.displayWorkingHours = displayWorkingHours;
      createCheckInProcessDetail(values);
    }

  }, [checkInProcessId, createCheckInProcessDetail, displayWorkingHours, icon, iconUpdated, updateCheckInProcessDetail]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="user-Form w-1/2">
      <Form
        {...layout}
        form={form}
        onFinish={onFinish}
      >
        <Row>
          <Col span={16}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: 'Please enter a name!' },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Property Type"
              name="propertyType"
              rules={[
                { required: true, message: 'Please select property type' },
              ]}
            >
              {renderType()}
            </Form.Item>
            <Form.Item
              label="Icon"
              name="icon"
            >
              <ImageUpload getImageBlob={getImageBlob} type="image" value={icon} disabled={false} />
            </Form.Item>

            <Form.Item
              label="Display working hours"
              name="displayWorkingHours">
              <Switch size="small" checked={displayWorkingHoursCheckValue} onChange={displayWorkingHoursChange} />
            </Form.Item>
          </Col >
          <Col span={8}>

            <p className="text-xl mb-5" style={{ width: '250px' }}>Alternative Check-in Process</p>
            <Form.List name="alternativeCheckInProcess"    {...layout}   >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(field => (

                    <Row>
                      <Space key={field.key} align="baseline" >
                        <Form.Item
                          {...field}
                          label="Name"
                          name={[field.name, 'name']}
                        >
                          <Input style={{ width: '210px' }} />
                        </Form.Item>
                        <div style={{ width: '50px' }}></div>
                        <DeleteOutlined onClick={() => remove(field.name)} style={{ marginLeft: '-55px' }} />
                      </Space>
                    </Row>

                  ))}

                  <Form.Item  {...tailLayout}>
                    <Button type="primary" className='btn_design' onClick={() => add()} icon={<PlusOutlined />} >
                      Add new check-in process
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>

          </Col>
        </Row>
        <Row>
          <Col span={16}>
            <Form.Item {...tailLayout}>
              <Button loading={loading} type="primary" className='btn_design' htmlType="submit">
                {checkInProcessId ? 'Update' : 'Create'}
              </Button>
              {/* <Button onClick={() => setFormOpen(false)} className="ml-5">
                Close
              </Button> */}
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </div>
  )
};

export default CheckInProcessForm;