import { message } from 'antd';
import { Breadcrumb, Button } from 'react-bootstrap';
import { FC, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useStoreActions, useStoreState } from "easy-peasy";
import RfpProcessList from './RfpProcessList';
import { getStorage } from "../../../common/storage";
import moment from 'moment';
import { RfpProcessStatus, UserGroups } from '../../../common/constants';
import { getLoggedUserType } from '../../../common/functions';
import { useHistory } from 'react-router-dom';
// import RfpProcessDetails from './form/RfpProcessDetails';
// import AccessDenied from '../../common/AccessDenied';

import RfpProcessForm from './form/RfpProcessForm';

const RfpProcessHome: FC<any> = ({ location }): JSX.Element => {

	const [selectedClientName, setSelectClientName] = useState<String>("");
	// const [clientId, setClientId] = useState<String>("");
	const [programmeName, setProgrammeName] = useState<String>("");
	const [programmeStatus, setProgrammeStatus] = useState<boolean>(false);
	const [formOpen, setFormOpen] = useState<boolean>(false);
	const [rfpProcessesList, setRfpProcessesList] = useState<[]>([]);
	const [rfpProcessId, setRfpProcessId] = useState<any>(null);
	const [selectedProgramme, setSelectedProgramme] = useState<any>(null);
	// const [statusChangeNeededRfpProcesses, setStatusChangeNeededRfpProcesses] = useState<[]>([]);
	const [completeStatusChangeNeededRfpProcesses, setCompleteStatusChangeNeededRfpProcesses] = useState<[]>([]);
	const [disableCreateNewRfpProcess, setDisableCreateNewRfpProcess] = useState<boolean>(false);
	const [startDate, setStartDate] = useState<any>(null);
	const [rfpName, setRfpName] = useState<any>(null);
	const [endDate, setEndDate] = useState<any>(null);
	const [refreshRfpProcessesList, setRefreshRfpProcessesList] = useState<boolean>(false);
	// const [loading, setLoading] = useState<boolean>(false);
	const [programmeId, setProgrammeId] = useState<any>(null);
	const [selectedClient, setSelectedClient] = useState<any>(null);
	const [rfpStatus, setRfpStatus] = useState<string>('');
	const today = moment(new Date());
	const [updatedData, setUpdatedData] = useState<any>(null);
	const history = useHistory<any>();

	//actions
	const {
		getCorporateProgrammeById,
		getRfpProcessByProgrammeId,
		updateRfpProcess,
		updateCertificateTemplate,
		getTemplate,
		getTemplateData
	} = useStoreActions<any>((actions) => ({
		getRfpProcessList: actions.rfpProcess.getRfpProcessList,
		getCorporateProgrammeById: actions.client.getCorporateProgrammeById,
		getRfpProcessByProgrammeId: actions.rfpProcess.getRfpProcessByProgrammeId,
		updateRfpProcess: actions.rfpProcess.updateRfpProcess,
		updateCertificateTemplate: actions.myoManagementModel.updateCertificateTemplate,
		getTemplate: actions.myoManagementModel.getTemplate,
		getTemplateData: actions.myoManagementModel.getTemplateData,
	}));

	//state
	const {
		getCorporateProgrammeByIdError,
		getCorporateProgrammeByIdSuccess,
		rfpProcessByProgrammeIdSuccess,
		rfpProcessByProgrammeIdError,
		rfpProcessByProgrammeIdLoading,
		updateRfpProcessSuccessResponse,
		updateRfpProcessErrorResponse,
		getTemplateSuccess,
		getTemplateDataSuccess
	} = useStoreState<any>((state) => ({

		rfpProcessByProgrammeIdSuccess: state.rfpProcess.rfpProcessByProgrammeIdSuccess,
		rfpProcessByProgrammeIdError: state.rfpProcess.rfpProcessByProgrammeIdError,
		rfpProcessByProgrammeIdLoading: state.client.rfpProcessByProgrammeIdLoading,
		getTemplateSuccess: state.myoManagementModel.getTemplateSuccess,
		getCorporateProgrammeByIdSuccess: state.client.getCorporateProgrammeByIdSuccess,
		getCorporateProgrammeByIdError: state.client.getCorporateProgrammeByIdError,
		getTemplateDataSuccess: state.myoManagementModel.getTemplateDataSuccess,
		updateRfpProcessSuccessResponse: state.rfpProcess.updateRfpProcessSuccessResponse,
		updateRfpProcessErrorResponse: state.rfpProcess.updateRfpProcessErrorResponse,

	}));

	///////////////////////////////////////////////////////////////////
	const callTemplate = useCallback((data) => {
		getTemplate({
			security: {
				apiKey: process.env.REACT_APP_AUDIT_API_KEY,
				user: "superadmin@mysa.global"
			},
			id: data?.certificateTemplateId
		});
	}, [getTemplate]);

	/////////////////////////////////////////////////////////////////////
	const callTemplateData = useCallback((data) => {
		setTimeout(() => getTemplateData({
			security: {
				apiKey: process.env.REACT_APP_AUDIT_API_KEY,
				user: "superadmin@mysa.global"
			},
			id: data?.certificateTemplateId
		}), 1000);

	}, [getTemplateData]);

	///////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getTemplateDataSuccess) {
			const templateList = getTemplateDataSuccess?.template;
			if (templateList) {
				const templateData = {
					security: {
						apiKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
						user: "superadmin@mysa.global"
					},
					template: {
						id: templateList?.id,
						rfpStatus: RfpProcessStatus.INPROGRESS,
						name: templateList?.name,
						description: templateList?.description,
						corporate_client: templateList?.corporate_client,
						programme: templateList?.programme,
						rfp_date: templateList?.rfp_date,
						rfp_EndDate: templateList?.rfp_EndDate,
						useExpectedCompletionPeriod: templateList?.useExpectedCompletionPeriod,
						expectedCompletionPeriod: templateList?.expectedCompletionPeriod,
						external: templateList?.external,
						clientID: process.env.REACT_APP_MYO_CLIENT_ID,
						surveys: templateList?.surveys
					}
				}
				updateCertificateTemplate(templateData);
			}

		}

	}, [getTemplateDataSuccess, updateCertificateTemplate]);

	///////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getTemplateSuccess) {
			const templateList = getTemplateSuccess?.template;
			if (templateList) {
				if (updatedData) {
					const templateData = {
						security: {
							apiKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
							user: "superadmin@mysa.global"
						},
						template: {
							id: templateList?.id,
							rfpStatus: updatedData?.rfpStatus,
							name: templateList?.name,
							description: templateList?.description,
							corporate_client: templateList?.corporate_client,
							programme: templateList?.programme,
							rfp_date: templateList?.rfp_date,
							rfp_EndDate: templateList?.rfp_EndDate,
							useExpectedCompletionPeriod: templateList?.useExpectedCompletionPeriod,
							expectedCompletionPeriod: templateList?.expectedCompletionPeriod,
							external: templateList?.external,
							clientID: process.env.REACT_APP_MYO_CLIENT_ID,
							surveys: templateList?.surveys
						}
					}
					updateCertificateTemplate(templateData);
				}

			}

		}

	}, [getTemplateSuccess, updateCertificateTemplate, updatedData]);

	// console.log(templateList,'templateList');
	// set the selected client to the state //////////////////////////////////////////////////////
	useEffect(() => {
		if (location) {
			setSelectedClient(location?.state?.selectedClient);
			setProgrammeId(location?.state?.programmeId);
			setProgrammeName(location?.state?.selectedProgramme?.programmeName);
			setSelectedProgramme(location?.state?.selectedProgramme);
		}
	}, [location]);

	/////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {

		if (rfpProcessesList?.length > 0) {
			const filteredArray = rfpProcessesList.filter((process: any) => {
				return process?.rfp?.rfpStatus !== RfpProcessStatus.COMPLETED
			})
			if (filteredArray?.length > 0) {
				setDisableCreateNewRfpProcess(true);
			} else {
				setDisableCreateNewRfpProcess(false);
			}
		} else {
			setDisableCreateNewRfpProcess(false);
		}
	}, [rfpProcessesList]);

	//////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (rfpProcessByProgrammeIdSuccess?.data) {
			const rfpPRocess = rfpProcessByProgrammeIdSuccess.data;
			// const filteredProcess = rfpPRocess.filter(process => {
			// 	const { startDate, endDate } = process;
			// 	const startDateMoment = moment(startDate);
			// 	const endDateMoment = moment(endDate);
			// 	return startDateMoment.isSameOrBefore(today) && process?.rfpStatus === 'not_started' && process?.isActivated === true && today.isSameOrBefore(endDateMoment.add(1, 'days'));
			// }).map(({ _id, startDate, endDate }) => ({
			// 	id: _id,
			// 	rfpStatus: RfpProcessStatus.INPROGRESS,
			// 	startDate,
			// 	endDate,
			// }));

			// if (filteredProcess?.length) {
			// 	setStatusChangeNeededRfpProcesses(filteredProcess);
			// }

			// // call update rfp process api on each statusChangeNeededRfpProcesses array
			// if (statusChangeNeededRfpProcesses?.length > 0) {
			// 	for (let process of statusChangeNeededRfpProcesses) {
			// 		updateRfpProcess(process);
			// 	}
			// }

			if (rfpPRocess) {
				const completeStatusChange = rfpPRocess.filter(process => {
					// const { endDate } = process?.rfp;

					const endDateMoment = moment(process?.rfp?.endDate);
					return moment(today).isAfter(endDateMoment, 'date') && process?.rfp?.rfpStatus !== 'completed';
				}).map((list: any) => ({


					id: list?.rfp?._id,
					rfpStatus: "completed",
					startDate: list?.rfp?.startDate,
					endDate: list?.rfp?.endDate,
					certificateTemplateId: list?.rfp?.certificateTemplateId,
					overviewForInterview: list?.rfp?.overviewForInterview
				}));

				if (completeStatusChange?.length) {
					setCompleteStatusChangeNeededRfpProcesses(completeStatusChange);
				}
				// call update rfp process api on each statusChangeNeededRfpProcesses array
				if (completeStatusChangeNeededRfpProcesses?.length > 0) {
					for (let processes of completeStatusChange) {

						updateRfpProcess(processes);
						// console.log(templateList,'dsfsdfs');
					}
				};
			}

		}
		if (rfpProcessByProgrammeIdError) {
			message.error(rfpProcessByProgrammeIdError?.message);
		}
	}, [completeStatusChangeNeededRfpProcesses?.length, rfpProcessByProgrammeIdError, rfpProcessByProgrammeIdSuccess, today, updateRfpProcess]);



	useEffect(() => {
		if (location?.state) {
			setFormOpen(location?.state?.formOpen);
			setRfpProcessId(location?.state?.rfpProcessId);
			setStartDate(moment(location?.state?.rfpStartDate).format('DD MMM YYYY'));
			setRfpName(location?.state?.rfpName);
			setEndDate(moment(location?.state?.rfpEndDate).format('DD MMM YYYY'));
			setRfpStatus(location?.state?.rfpStatus);
		}
	}, [location?.state]);
	
	//////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (completeStatusChangeNeededRfpProcesses.length > 0) {
			getRfpProcessByProgrammeId(programmeId);
		}
	}, [completeStatusChangeNeededRfpProcesses.length, getRfpProcessByProgrammeId, programmeId]);


	// to get the selectedProgramme from the localStorage //////////////////////////////////////////////////////
	useEffect(() => {
		const programme = getStorage('myo_selected_programme');
		if (programme) {

			setSelectedProgramme(programme);
		}

	}, []);

	// call getCorporateProgrammeById //////////////////////////////////////////////////////
	useEffect(() => {
		if (programmeId) {
			getCorporateProgrammeById(programmeId);
		}
	}, [getCorporateProgrammeById, programmeId]);

	// set state for programme data //////////////////////////////////////////////////////
	useEffect(() => {
		if (getCorporateProgrammeByIdError) {
			setProgrammeName(getCorporateProgrammeByIdError?.programmeName)
		}
		if (getCorporateProgrammeByIdSuccess?.data) {
			const { programmeName, isActivated } = getCorporateProgrammeByIdSuccess.data;
			setProgrammeName(programmeName);
			// setClientId(_id);
			setProgrammeStatus(isActivated);
		}
	}, [getCorporateProgrammeByIdSuccess, getCorporateProgrammeByIdError])

	// to fetch  data for the rfp process list //////////////////////////////////////////////////////
	useEffect(() => {
		if (programmeId) {
			getRfpProcessByProgrammeId(programmeId);
		}
	}, [getRfpProcessByProgrammeId, programmeId]
	);

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const reload = useCallback(() => {
		setTimeout(() => getRfpProcessByProgrammeId(programmeId), 1000);
	}, [getRfpProcessByProgrammeId, programmeId])

	// refresh 
	useEffect(() => {
		if (programmeId && refreshRfpProcessesList) {
			getRfpProcessByProgrammeId(programmeId);
			setRefreshRfpProcessesList(false);
		}
	}, [getRfpProcessByProgrammeId, programmeId, refreshRfpProcessesList]
	);

	//to set the rfpProcessListByProgrammeId to local state //////////////////////////////////////////////////////
	useEffect(() => {
		if (rfpProcessByProgrammeIdSuccess) {
			setRfpProcessesList(rfpProcessByProgrammeIdSuccess.data);
		}
		if (rfpProcessByProgrammeIdError) {
			message.error(rfpProcessByProgrammeIdError.message);
		}
	}, [rfpProcessByProgrammeIdSuccess, rfpProcessByProgrammeIdError, rfpProcessByProgrammeIdLoading, rfpProcessesList])

	// set the selected client to the state //////////////////////////////////////////////////////
	useEffect(() => {
		if (location?.state?.selectedClient?.companyName) {
			setSelectClientName(location?.state?.selectedClient.companyName)
		}
	}, [location]);

	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const loadForm = useCallback((data) => {
		setFormOpen(true);
		if (data !== null) {
			setRfpProcessId(data.key);
			setStartDate(moment(data.startDate).format('DD MMM YYYY'));
			setRfpName(data?.rfpName);
			setEndDate(moment(data.endDate).format('DD MMM YYYY'));
		} else {
			setRfpProcessId(null);
		}
	}, []);


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (updateRfpProcessSuccessResponse) {
			// setLoading(false);
			// toast.success("RFP process activated successfully", {
			// 	position: toast.POSITION.BOTTOM_RIGHT,
			// 	className: 'foo-bar'
			// });
			setUpdatedData(updateRfpProcessSuccessResponse?.data);
			callTemplate(updateRfpProcessSuccessResponse?.data);
			reload();
		}
		if (updateRfpProcessErrorResponse) {
			// toast.error(updateRfpProcessErrorResponse?.message, {
			// 	position: toast.POSITION.BOTTOM_RIGHT,
			// 	className: 'foo-bar'
			// });

		}
	}, [updateRfpProcessSuccessResponse, updateRfpProcessErrorResponse, reload, callTemplate]);

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const loadData = useCallback((data) => {
		// setLoading(true);
		let rfpStatus: any = RfpProcessStatus.NOTSTARTED;
		if (data) {
			if ((data?.startDate && moment(data.startDate).format("YYYY-MM-DD") === (today.format("YYYY-MM-DD"))) || (moment(data.startDate).isSameOrBefore(today))) {
				rfpStatus = RfpProcessStatus.INPROGRESS;
			}
			const formattedData = {
				id: data?.key,
				rfpStatus: rfpStatus,
				isActivated: true
			}
			updateRfpProcess(formattedData);
			const detail = {
				certificateTemplateId: data?.certificateTemplateId,
				rfpStatus: rfpStatus,
			}
			callTemplateData(detail);
		}
	}, [callTemplateData, today, updateRfpProcess]);


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	return (
		<div className='rfp-process-home' >
			<div className="flex justify-between">
				{!location?.state?.selectedClient && <p className="ext-2xl mb-10"></p>}
				{location?.state?.selectedClient && !rfpProcessId && <Breadcrumb className='mb-10' >
					<Breadcrumb.Item>
						<Link to={{ pathname: "/admin/client-management", state: { selectedClient } }} >{selectedClientName}</Link>
					</Breadcrumb.Item>
					{/* <Breadcrumb.Item>
						<Link to={{
							pathname: '/admin/programme-management',
							state: {
								clientId,
							}
						}} >programme Management</Link>
					</Breadcrumb.Item> */}
					<Breadcrumb.Item  >
						<Link to={{
							pathname: '/admin/programme-management',
							state: {
								selectedProgramme, selectedClient
							}
						}} >
							{programmeName}
						</Link>
					</Breadcrumb.Item>

					<Breadcrumb.Item active={formOpen ? false : true} >
						{
							formOpen ?
								<Link to={{
									pathname: '/admin/rfp-management',
									state: {
										selectedProgramme,
										selectedClient,
										programmeId
									}
								}} >RFP Management</Link> : "RFP Management"
						}
					</Breadcrumb.Item>


					{formOpen && rfpProcessId && <Breadcrumb.Item active >
						{startDate}
					</Breadcrumb.Item>}
					{formOpen && !rfpProcessId && <Breadcrumb.Item active>
						New RFP
					</Breadcrumb.Item>}
				</Breadcrumb>}
				{getLoggedUserType() === UserGroups.super_admin &&
					<Button
						id='edit-button'
						disabled={(disableCreateNewRfpProcess || programmeStatus === false || (selectedProgramme?.status ? selectedProgramme?.status === 'active' ? false : true : false))}
						hidden={formOpen}
						onClick={() => setFormOpen(true)}
						className="h-10 text-white" >Create New RFP</Button>}
			</div>
			{!formOpen ? <RfpProcessList setRefreshRfpProcessesList={setRefreshRfpProcessesList} setRfpStatus={setRfpStatus} loadForm={loadForm} loading={rfpProcessByProgrammeIdLoading} rfpProcessesList={rfpProcessesList} loadData={loadData} />
				: <RfpProcessForm rfpName={rfpName} rfpStatus={rfpStatus} programmeId={programmeId} rfpProcessId={rfpProcessId} setFormOpen={setFormOpen} reload={reload} selectedProgramme={selectedProgramme} selectedClient={selectedClient} startDate={startDate} endDate={endDate} location={location} selectedClientName={selectedClientName} />}

			{/* isCorporateProgrammeModuleAvailable(selectedProgramme?.clientId, selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) ? <RfpProcessDetails programmeId={programmeId} rfpProcessId={rfpProcessId} setFormOpen={setFormOpen} reload={reload} /> : <AccessDenied /> */}
			{/* {formOpen  && <RfpProcessForm programmeId={programmeId} rfpProcessId={rfpProcessId} setFormOpen={setFormOpen} reload={reload} />} */}
			{/* {formOpen && !rfpProcessId && isCorporateProgrammeModuleAvailable(selectedProgramme?.clientId, selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) && <RfpProcessDetails programmeId={programmeId} rfpProcessId={rfpProcessId} setFormOpen={setFormOpen} reload={reload} />} */}

		</div>
	)

}

export default RfpProcessHome;
