import { Select } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import currency from '../../../../common/currency.json';
import timezones from '../../../../common/timezone.json';
import GoogleMap from '../../../common/GoogleMap';
import GooglePlaces from '../../../common/GooglePlaces';
import { canBrandPropertyModuleWrite, formatPhoneNumber, getLoggedUserType, getMailDomain } from '../../../../common/functions';
import { UserAccess, CommonAnswer, UserGroups, ALL_INVENTORIES_APPROVAL_STATUS, PropertyStatus } from '../../../../common/constants';
import ImageUpload from '../../../common/imageUpload';
import roomType from '../../../../common/roomType.json';
import { Controller, useForm } from 'react-hook-form';
import { PropertyFormInput } from '../../../../common/interfaces';
import { Row, Col, Container, Form, Button, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formButton, formButton2, overlayTriggerDelay, toolTipStyle } from '../../../../common/components-style';
import { toast } from 'react-toastify';
import { ErrorMessage } from '@hookform/error-message';
import BreadCrumbPage from '../../../common/BreadCrumbPage';
import moment from 'moment'
import DatePicker from "react-datepicker";
import { PhoneNumberUtil } from 'google-libphonenumber';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const { Option } = Select;


const PropertyDetailsForm: React.FC<any> = ({ allInOnePropertyBreadCrumb, propertyId, selectedBrand, setFormOpen, loadData, tab }): JSX.Element => {
    const { register, handleSubmit, formState: { errors }, control, setValue } = useForm<PropertyFormInput>();
    const [editEnable, setEditEnable] = useState<boolean>(false);
    const [propertyData, setPropertyData] = useState<any>();
    const [location, setLocation] = useState<any>(null);
    const [coordinates, setCoordinates] = useState<any>(null);
    const [subType, setSubType] = useState<Array<Object>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [auditReference, setAuditReference] = useState<string>('');
    const [myoManagementReference, setMYOManagementReference] = useState<string>('');
    const [auditClientId, setAuditClientId] = useState<string>('');
    const [myoManagementClientId, setMYOManagementClientId] = useState<string>('');
    const [firstMYOApiCallEnsure, setFirstMYOApiCallEnsure] = useState('');
    const [termPdf, setTermPdf] = useState(null);
    const [termPdfUpdated, setTermPdfUpdated] = useState(false);
    const [whichRateChanelManager, setWhichRateChanelManager] = useState(false);
    const [whichPMSDoYouUtilise, setWhichPMSDoYouUtilise] = useState(false);
    const [pmsValue, setPmsValue] = useState(false);
    const [channelValue, setChannelValue] = useState(false);
    const [locationDataList, setLocationDataList] = useState<any>(null);
    const [propertyTypeList, setPropertyTypeList] = useState<any>();
    const [subTypeData, setSubTypeData] = useState<any>([]);
    const [allInventoriesApprovalStatus, setAllInventoriesApprovalStatus] = useState<string>('');
    const [brandDomains, setBrandDomains] = useState<Array<string>>([]);
    const [validPhoneNumber, setValidPhoneNumber] = useState<boolean>(false);
    const [validContactNumber, setValidContactNumber] = useState<boolean>(false);
    const [inventorySummaries, setInventorySummaries] = useState<any>();
    const [enableApprovalMessage, setEnableApprovalMessage] = useState<boolean>(true);
    const [propertyManagementList, setPropertyManagementList] = useState<any>();
    const [channelManagerList, setChannelManagerList] = useState<any>();
    const [propertyManagementData, setPropertyManagementData] = useState<any>();
    const [channelManagerData, setChannelManagerData] = useState<any>();
    const [propertyManagementName, setPropertyManagementName] = useState<any>(null);
    const [channelManagerName, setChannelManagerName] = useState<any>(null);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const { inventorySummary, resetMyo, resetAudit, getPropertyManagementList, getChannelManagerList, onloadBrand, createMYOManagementBuilding, updateMYOManagementBuilding, updatePropertyAuditAndMYOPurpose, propertyApproval, propertyReviewed, loadBrandList, createProperty, updateProperty, getProperty, createAuditBuilding, updateAuditBuilding, getPropertyTypes, resetProperty } = useStoreActions<any>((actions) => ({
        loadBrandList: actions.common.loadBrandList,
        createProperty: actions.property.createProperty,
        updateProperty: actions.property.updateProperty,
        updatePropertyAuditAndMYOPurpose: actions.property.updatePropertyAuditAndMYOPurpose,
        getProperty: actions.property.getProperty,
        getPropertyTypes: actions.property.getPropertyTypes,
        createAuditBuilding: actions.audit.createAuditBuilding,
        updateAuditBuilding: actions.audit.updateAuditBuilding,
        createMYOManagementBuilding: actions.myoManagementModel.createMYOManagementBuilding,
        updateMYOManagementBuilding: actions.myoManagementModel.updateMYOManagementBuilding,
        resetProperty: actions.property.resetProperty,
        propertyApproval: actions.property.propertyApproval,
        propertyReviewed: actions.property.propertyReviewed,
        resetAudit: actions.audit.resetAudit,
        resetMyo: actions.myoManagementModel.resetMyo,
        onloadBrand: actions.brand.onloadBrand,
        inventorySummary: actions.inventory.inventorySummary,
        getPropertyManagementList: actions.property.getPropertyManagementList,
        getChannelManagerList: actions.property.getChannelManagerList,
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const {
        brandList,
        createPropertySuccess,
        createPropertyError,
        getPropertySuccess,
        getPropertyError,
        updatePropertyError,
        updatePropertySuccess,
        getPropertyTypesSuccess,
        createAuditBuildingSuccess,
        propertyApprovalSuccess,
        propertyApprovalError,
        createMYOManagementBuildingSuccess,
        loadBrandSuccess,
        propertyReviewSuccess,
        propertyReviewError,
        getInventorySummarySuccess,
        getPropertyManagementListSuccess,
        getChannelManagerListSuccess,
    } = useStoreState<any>((state) => ({
        brandList: state.common.brandList,
        createPropertySuccess: state.property.createPropertySuccess,
        createPropertyError: state.property.createPropertyError,
        getPropertySuccess: state.property.getPropertySuccess,
        getPropertyError: state.property.getPropertyError,
        updatePropertyError: state.property.updatePropertyError,
        updatePropertySuccess: state.property.updatePropertySuccess,
        getPropertyTypesSuccess: state.property.getPropertyTypesSuccess,
        createAuditBuildingSuccess: state.audit.createAuditBuildingSuccess,
        createMYOManagementBuildingSuccess: state.myoManagementModel.createMYOManagementBuildingSuccess,
        propertyApprovalSuccess: state.property.propertyApprovalSuccess,
        propertyApprovalError: state.property.propertyApprovalError,
        propertyReviewSuccess: state.property.propertyReviewSuccess,
        propertyReviewError: state.property.propertyReviewError,
        loadBrandSuccess: state.brand.loadBrandSuccess,
        getInventorySummarySuccess: state.inventory.getInventorySummarySuccess,
        getInventorySummaryError: state.inventory.getInventorySummaryError,
        getPropertyManagementListSuccess: state.property.getPropertyManagementListSuccess,
        getChannelManagerListSuccess: state.property.getChannelManagerListSuccess,
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////

    useEffect(() => {
        (async function () {
            await loadBrandList();
            await getPropertyTypes();
            await getPropertyManagementList();
            await getChannelManagerList();
            if (selectedBrand) {
                await onloadBrand(selectedBrand?.key);
            }

            if (propertyId) {
                resetAudit();
                resetMyo();
                await getProperty(propertyId)
                await inventorySummary(propertyId);
            } else {
                setEditEnable(true);
            }

        }())
    }, [getChannelManagerList, getProperty, getPropertyManagementList, getPropertyTypes, inventorySummary, loadBrandList, onloadBrand, propertyId, resetAudit, resetMyo, selectedBrand]);



    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (loadBrandSuccess) {
            setBrandDomains(loadBrandSuccess.data?.allowedDomains);
        }
    }, [loadBrandSuccess])

    ///////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getInventorySummarySuccess) {
            setInventorySummaries(getInventorySummarySuccess.data[0]);
        }
    }, [getInventorySummarySuccess])

    //////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getPropertyManagementListSuccess) {
            const list = [{ label: "Not Listed", value: 0 },];
            for (const management of getPropertyManagementListSuccess?.data) {
                const room = {
                    label: management?.name,
                    value: management?._id
                };
                list.push(room);
            }
            setPropertyManagementData(list);

            const typeOfProperty = list.map((val: any, key: number) => {
                return (
                    <Option key={val.value} data={val} value={`${val.value}`} >{val.label}</Option>
                )
            });

            setPropertyManagementList(typeOfProperty);
        }
    }, [getPropertyManagementListSuccess])

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getChannelManagerListSuccess) {
            const list = [{ label: "Not Listed", value: 0 },];
            for (const management of getChannelManagerListSuccess?.data) {
                const room = {
                    label: management?.name,
                    value: management?._id
                };
                list.push(room);
            }

            setChannelManagerData(list);
            const typeOfProperty = list.map((val: any, key: number) => {
                return (
                    <Option key={val.value} data={val} value={`${val.value}`} >{val.label}</Option>
                )
            });
            setChannelManagerList(typeOfProperty);
        }
    }, [getChannelManagerListSuccess])


    //////////////////////////////////////////////////////////////////////
    useEffect(() => {
        //////////// vesta api response manage ///////////
        if (createAuditBuildingSuccess) {
            if (createAuditBuildingSuccess?.buildingID) {
                resetAudit();
                resetMyo();
                updatePropertyAuditAndMYOPurpose({ id: propertyId, firstMYOApiCallEnsure: 'YES' });
                updatePropertyAuditAndMYOPurpose({ id: propertyId, externalId: createAuditBuildingSuccess?.buildingID });
                setAuditReference(createAuditBuildingSuccess?.buildingID);
            }
        }
    }, [createAuditBuildingSuccess, propertyId, resetAudit, resetMyo, updatePropertyAuditAndMYOPurpose])

    //////////////////////////////////////////////////////////////////////
    useEffect(() => {

        //////////// myo management api response manage ///////////
        if (createMYOManagementBuildingSuccess) {
            if (createMYOManagementBuildingSuccess?.buildingID) {
                resetAudit();
                resetMyo();
                updatePropertyAuditAndMYOPurpose({ id: propertyId, firstMYOApiCallEnsure: 'YES' });
                updatePropertyAuditAndMYOPurpose({ id: propertyId, externalIdMYOManagement: createMYOManagementBuildingSuccess?.buildingID });
                setMYOManagementReference(createMYOManagementBuildingSuccess?.buildingID);
            }
        }
    }, [createMYOManagementBuildingSuccess, propertyId, resetAudit, resetMyo, updatePropertyAuditAndMYOPurpose])


    //////////////// approval part ////////
    const onApprovedProperty = useCallback(() => {
        if (auditClientId && myoManagementClientId) {
            setLoading(true);
            const payload = {
                propertyId: propertyId
            }
            propertyApproval(payload);

            if (firstMYOApiCallEnsure === 'YES' && auditReference && myoManagementReference && auditClientId && myoManagementClientId) {
                /////////// vesta api call ////////////
                updateAuditBuilding({
                    security: {
                        apiKey: process.env.REACT_APP_AUDIT_API_KEY,
                        user: "superadmin@mysa.global"
                    },
                    building: {
                        id: auditReference,
                        key: "",
                        telephone: propertyData?.telephone,
                        manager: propertyData?.contactEmail,
                        buildingName: propertyData?.propertyName,
                        address1: propertyData?.address1,
                        address2: propertyData?.address2,
                        city: propertyData?.city,
                        state: "",
                        clientID: auditClientId,
                        externalID: propertyId,
                        country: propertyData?.country,
                        postcode: propertyData?.postcode,
                        latitude: Number(propertyData?.latitude),
                        longitude: Number(propertyData?.longitude),
                        unitCount: 0,
                        managedExternally: false,
                        canDelete: true,
                        contactInfo: {
                            name: propertyData?.contactForPropertyAudit,
                            email: propertyData?.contactEmail,
                        },
                        propertyType: propertyData?.propertyType?.name,
                        configuration: {
                            oneBedroomUnit: inventorySummaries?.oneBedroomUnit.length !== 0 ? inventorySummaries?.oneBedroomUnit[0].totalUnit : 0,
                            twoBedroomUnit: inventorySummaries?.twoBedroomUnit.length !== 0 ? inventorySummaries?.twoBedroomUnit[0].totalUnit : 0,
                            threeBedroomUnit: inventorySummaries?.threeBedroomUnit.length !== 0 ? inventorySummaries?.threeBedroomUnit[0].totalUnit : 0,
                            moreThanThreeBedroomUnit: inventorySummaries?.moreThanThreeBedroomUnit.length !== 0 ? inventorySummaries?.moreThanThreeBedroomUnit[0].totalUnit : 0,
                            studioApartmentUnit: inventorySummaries?.studioApartmentUnit.length !== 0 ? inventorySummaries?.studioApartmentUnit[0].totalUnit : 0
                        }
                    }
                });

                //////////////// myo management api call /////////
                updateMYOManagementBuilding({
                    security: {
                        apiKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
                        user: "superadmin@mysa.global"
                    },
                    building: {
                        id: myoManagementReference,
                        key: "",
                        telephone: propertyData?.telephone,
                        manager: propertyData?.contactEmail,
                        buildingName: propertyData?.propertyName,
                        address1: propertyData?.address1,
                        address2: propertyData?.address2,
                        city: propertyData?.city,
                        state: "",
                        clientID: myoManagementClientId,
                        externalID: propertyId,
                        country: propertyData?.country,
                        postcode: propertyData?.postcode,
                        latitude: Number(propertyData?.latitude),
                        longitude: Number(propertyData?.longitude),
                        unitCount: 0,
                        managedExternally: false,
                        canDelete: true,
                        contactInfo: {
                            name: propertyData?.contactForPropertyAudit,
                            email: propertyData?.contactEmail,
                        },
                        propertyType: propertyData?.propertyType?.name,
                        configuration: {
                            oneBedroomUnit: inventorySummaries?.oneBedroomUnit.length !== 0 ? inventorySummaries?.oneBedroomUnit[0].totalUnit : 0,
                            twoBedroomUnit: inventorySummaries?.twoBedroomUnit.length !== 0 ? inventorySummaries?.twoBedroomUnit[0].totalUnit : 0,
                            threeBedroomUnit: inventorySummaries?.threeBedroomUnit.length !== 0 ? inventorySummaries?.threeBedroomUnit[0].totalUnit : 0,
                            moreThanThreeBedroomUnit: inventorySummaries?.moreThanThreeBedroomUnit.length !== 0 ? inventorySummaries?.moreThanThreeBedroomUnit[0].totalUnit : 0,
                            studioApartmentUnit: inventorySummaries?.studioApartmentUnit.length !== 0 ? inventorySummaries?.studioApartmentUnit[0].totalUnit : 0
                        }
                    }
                });
            } else {
                /////////// vesta api call ////////////
                createAuditBuilding({
                    security: {
                        apiKey: process.env.REACT_APP_AUDIT_API_KEY,
                        user: "superadmin@mysa.global"
                    },
                    building: {
                        id: "",
                        key: "",
                        telephone: propertyData?.telephone,
                        manager: propertyData?.contactEmail,
                        buildingName: propertyData?.propertyName,
                        address1: propertyData?.address1,
                        address2: propertyData?.address2,
                        city: propertyData?.city,
                        state: "",
                        country: propertyData?.country,
                        postcode: propertyData?.postcode,
                        latitude: Number(propertyData?.latitude),
                        longitude: Number(propertyData?.longitude),
                        clientID: auditClientId,
                        unitCount: 0,
                        externalID: propertyId,
                        managedExternally: false,
                        canDelete: true,
                        contactInfo: {
                            name: propertyData?.contactForPropertyAudit,
                            email: propertyData?.contactEmail,
                        },
                        propertyType: propertyData?.propertyType?.name,
                        configuration: {
                            oneBedroomUnit: inventorySummaries?.oneBedroomUnit.length !== 0 ? inventorySummaries?.oneBedroomUnit[0].totalUnit : 0,
                            twoBedroomUnit: inventorySummaries?.twoBedroomUnit.length !== 0 ? inventorySummaries?.twoBedroomUnit[0].totalUnit : 0,
                            threeBedroomUnit: inventorySummaries?.threeBedroomUnit.length !== 0 ? inventorySummaries?.threeBedroomUnit[0].totalUnit : 0,
                            moreThanThreeBedroomUnit: inventorySummaries?.moreThanThreeBedroomUnit.length !== 0 ? inventorySummaries?.moreThanThreeBedroomUnit[0].totalUnit : 0,
                            studioApartmentUnit: inventorySummaries?.studioApartmentUnit.length !== 0 ? inventorySummaries?.studioApartmentUnit[0].totalUnit : 0
                        }
                    }
                });

                //////////////// myo management api call /////////
                createMYOManagementBuilding({
                    security: {
                        apiKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
                        user: "superadmin@mysa.global"
                    },
                    building: {
                        id: "",
                        key: "",
                        telephone: propertyData?.telephone,
                        manager: propertyData?.contactEmail,
                        buildingName: propertyData?.propertyName,
                        address1: propertyData?.address1,
                        address2: propertyData?.address2,
                        city: propertyData?.city,
                        state: "",
                        country: propertyData?.country,
                        postcode: propertyData?.postcode,
                        latitude: Number(propertyData?.latitude),
                        longitude: Number(propertyData?.longitude),
                        clientID: myoManagementClientId,
                        unitCount: 0,
                        externalID: propertyId,
                        managedExternally: false,
                        canDelete: true,
                        contactInfo: {
                            name: propertyData?.contactForPropertyAudit,
                            email: propertyData?.contactEmail,
                        },
                        propertyType: propertyData?.propertyType?.name,
                        configuration: {
                            oneBedroomUnit: inventorySummaries?.oneBedroomUnit.length !== 0 ? inventorySummaries?.oneBedroomUnit[0].totalUnit : 0,
                            twoBedroomUnit: inventorySummaries?.twoBedroomUnit.length !== 0 ? inventorySummaries?.twoBedroomUnit[0].totalUnit : 0,
                            threeBedroomUnit: inventorySummaries?.threeBedroomUnit.length !== 0 ? inventorySummaries?.threeBedroomUnit[0].totalUnit : 0,
                            moreThanThreeBedroomUnit: inventorySummaries?.moreThanThreeBedroomUnit.length !== 0 ? inventorySummaries?.moreThanThreeBedroomUnit[0]?.totalUnit : 0,
                            studioApartmentUnit: inventorySummaries?.studioApartmentUnit?.length !== 0 ? inventorySummaries?.studioApartmentUnit[0]?.totalUnit : 0
                        }
                    }
                });
            }
        }

    }, [auditClientId, auditReference, createAuditBuilding, createMYOManagementBuilding, firstMYOApiCallEnsure, inventorySummaries, myoManagementClientId, myoManagementReference, propertyApproval, propertyData, propertyId, updateAuditBuilding, updateMYOManagementBuilding]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getPropertyTypesSuccess) {
            const typeOfProperty = getPropertyTypesSuccess.data.map((val: any, key: number) => {
                return (
                    <Option key={key} data={val} value={`${val._id}`} >{val.name}</Option>
                )
            });

            setPropertyTypeList(typeOfProperty)
        }

    }, [getPropertyTypesSuccess]);


    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getPropertySuccess) {
            const {
                latitude,
                longitude,
                propertyType,
                externalId,
                rateChannelManager,
                termPdf: termPdfVal,
                countryCode,
                placeId,
                propertySubType,
                // brandId,
                doesPropertyUtilisePMSToManageInventory,
                propertyManagementSystemOption,
                channelManagerOption
            } = getPropertySuccess.data;
            // onloadBrand(brandId?._id);
            setPropertyData(getPropertySuccess?.data);
            setAuditClientId(getPropertySuccess?.data?.brandId?.auditReference);
            setMYOManagementClientId(getPropertySuccess?.data?.brandId?.myoManagementReference);
            setAllInventoriesApprovalStatus(getPropertySuccess?.data?.allInventoriesApprovalStatus);

            //////////////// form required data manage////////
            const data = _.filter(propertyType?.subType, (a) => a._id === propertySubType);
            setSubTypeData(data);

            ///////////////////////////whichRateChanelManager Validation/////////////////////////////////////////
            if (rateChannelManager === CommonAnswer.YES) {
                setWhichRateChanelManager(true);
            } else {
                setWhichRateChanelManager(false);
            }

            if (doesPropertyUtilisePMSToManageInventory === CommonAnswer.YES) {
                setWhichPMSDoYouUtilise(true);
            } else {
                setWhichPMSDoYouUtilise(false);
            }

            if (propertyManagementSystemOption === 'Not Listed') {
                setPmsValue(true);
            } else {
                setPmsValue(false);
            }

            if (channelManagerOption === 'Not Listed') {
                setChannelValue(true);
            } else {
                setChannelValue(false);
            }


            if (getPropertySuccess.data?.firstMYOApiCallEnsure === 'YES') {
                setFirstMYOApiCallEnsure(getPropertySuccess.data?.firstMYOApiCallEnsure);
            } else {
                setFirstMYOApiCallEnsure('NO');
            }

            setSubType(propertyType?.subType || []);
            setAuditReference(externalId);
            setMYOManagementReference(getPropertySuccess.data?.externalIdMYOManagement);
            setLocation({ lat: Number(latitude), lng: Number(longitude) });
            setLocationDataList({ lat: latitude, lng: longitude, countryCode: countryCode, placeId: placeId })
            setTermPdf(termPdfVal);
        }

        if (getPropertyError) {
            toast.error(getPropertyError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

    }, [channelManagerData, getPropertyError, getPropertySuccess, propertyManagementData]);


    useEffect(() => {
        if (propertyData) {
            if (propertyManagementData) {
                const manager = _.filter(propertyManagementData, (a) => a.label === propertyData?.propertyManagementSystemOption);
                setPropertyManagementName(manager[0]);
            }
            if (channelManagerData) {
                const channel = _.filter(channelManagerData, (a) => a.label === propertyData?.channelManagerOption);
                setChannelManagerName(channel[0]);
            }

        } else {

            setPropertyManagementName(propertyData?.propertyManagementSystemOption);
            setChannelManagerName(propertyData?.channelManagerOption);
        }

    }, [channelManagerData, propertyData, propertyData?.channelManagerOption, propertyData?.propertyManagementSystemOption, propertyManagementData])

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (createPropertySuccess) {
            loadData(createPropertySuccess?.data?._id);
            resetProperty();
            toast.success("Property created successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEditEnable(false);
            scrollToTop();
            getProperty(createPropertySuccess?.data?._id);
        }

        if (updatePropertySuccess) {
            resetProperty();
            toast.success("Property updated successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEditEnable(false);
            scrollToTop();
            getProperty(updatePropertySuccess?.data?._id);
            // onApprovedProperty();
            setEnableApprovalMessage(false);
        }

        if (createPropertyError) {
            toast.error(createPropertyError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (updatePropertyError) {
            toast.error(updatePropertyError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        setLoading(false);
    }, [createPropertyError, createPropertySuccess, getProperty, loadData, resetProperty, updatePropertyError, updatePropertySuccess]);



    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (propertyApprovalError) {
            toast.error(propertyApprovalError?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (propertyReviewError) {
            toast.error(propertyReviewError?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (propertyApprovalSuccess) {
            getProperty(propertyId);
            if (enableApprovalMessage) {
                toast.success("Property approved successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
            }
        }
        if (propertyReviewSuccess) {
            getProperty(propertyId);
            toast.success("Property reviewed successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        setLoading(false);
    }, [enableApprovalMessage, getProperty, propertyApprovalError, propertyApprovalSuccess, propertyId, propertyReviewError, propertyReviewSuccess]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const renderBrandList = useCallback(() => {
        if (brandList) {
            return brandList.data.map((brand: any, key: number) => {
                return (
                    <Option key={key} brand={brand} value={`${brand._id}`} >{brand.name}</Option>
                )
            });
        }

    }, [brandList]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const renderCurrency = useCallback(() => {
        if (currency) {
            return currency.map((cur: any, key: number) => {
                return (
                    <Option key={cur.cc} value={`${cur.cc}`} >{cur.name} <span className="text-blue-400 font-bold ml-2">({cur.symbol}) - {cur.cc}</span></Option>
                )
            });
        }

    }, []);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const renderTimezone = useCallback(() => {

        if (timezones) {
            return timezones.map((tz: any, key: number) => {
                return (
                    <Option key={tz} value={`${tz}`} >{tz}</Option>
                )
            });
        }

    }, []);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const setCoords = useCallback((coords) => {
        setCoordinates({ lat: coords.lat, lng: coords.lng });
    }, []);

    const setLocationData = useCallback((location) => {
        setValue("country", location?.country)
        setLocation(location);
    }, [setValue]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const onTypeSelect = useCallback((value, options) => {
        if (options?.data?.subType?.length !== 0) {
            setSubType(options.data.subType);
        } else {
            setSubType([]);
        }

    }, []);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const onBrandSelect = useCallback((value, options) => {
        setAuditClientId(options.brand.auditReference);
        setMYOManagementClientId(options.brand.myoManagementReference);
    }, []);


    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const renderSubType = useMemo(() => {
        return _.map(subType, (val: any, key: number) => {
            return (
                <Option key={key} data={val} value={`${val._id}`} >{val.name}</Option>
            )
        })
    }, [subType]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const onSubTypeSelect = useCallback((value, options) => {
        const sub: Array<Object> = [];
        sub.push(options.data);
        setSubTypeData(sub);
    }, []);


    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const callApi = useCallback(() => {
        getPropertyTypes();
        getPropertyManagementList();
        getChannelManagerList();
        if (propertyId) {
            getProperty(propertyId);
        }

    }, [getChannelManagerList, getProperty, getPropertyManagementList, getPropertyTypes, propertyId]);

   

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const onFinish = (values) => {
    
        if (!validPhoneNumber) {
            toast.error('Please enter valid phone number!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            return;
        }

        if (!validContactNumber) {
            toast.error('Please enter valid contact phone number!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            return;
        }

        if (!validateContactNumber(values?.outOfHourContactNumber)) {
            toast.error('Please enter valid contact phone  number!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            return;
        }
        if (!validatePhoneNumber(values?.telephone)) {
            toast.error('Please enter valid phone number!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            return;
        }
        if (!validatePhoneNumber(values?.contactTelephone)) {
            toast.error('Please enter valid phone number!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            return;
        }

        if (values.systemNotificationEmail) {
            // check domain
            const getDomainValidation = getMailDomain(values.systemNotificationEmail, brandDomains);
            if (!getDomainValidation) {
                toast.error('Invalid brand domain. please check your system notification e-mail address domain with brand domains', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
                return;
            }
        }

        if (values.contactEmail) {
            // check domain
            const getDomainValidation = getMailDomain(values.contactEmail, brandDomains);
            if (!getDomainValidation) {
                toast.error('Invalid brand domain. please check your contact e-mail address domain with brand domains', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
                return;
            }
        }

        delete values.location;
        values.placeId = location ? location?.placeId : locationDataList?.placeId;
        values.termPdfUpdated = termPdfUpdated;
        if (propertyManagementName?.data) {
            values.propertyManagementSystemOption = propertyManagementName?.data?.label;

        } else if (propertyManagementName) {
            values.propertyManagementSystemOption = propertyManagementName?.label;

        } else {
            values.propertyManagementSystemOption = '';

        }


        if (channelManagerName?.data) {

            values.channelManagerOption = channelManagerName?.data?.label;
        } else if (channelManagerName) {

            values.channelManagerOption = channelManagerName?.label;
        } else {

            values.channelManagerOption = '';
        }
        // values.propertySubType = _.filter(subTypeData, (a) => a._id === values.propertySubType).shift()?.name || '';

        //////////////////////////whichRateChanelManager submit value manage////////////////////////////
        if (!whichRateChanelManager) {
            values.whichRateChanelManager = '';
        }

        if (coordinates) {
            values.latitude = coordinates.lat.toString();
            values.longitude = coordinates.lng.toString();
        } else {
            values.latitude = location ? location?.lat.toString() : locationDataList?.lat;
            values.longitude = location ? location?.lng.toString() : locationDataList?.lng;
            values.countryCode = location ? location?.countryCode : locationDataList?.countryCode;
        }

        if (subType.length === 0) {
            values.propertySubType = '';
        }

        setLoading(true);
        if (propertyId) {
            delete values.brandId;
            values.id = propertyId;
            values.termPdf = termPdfUpdated ? termPdf : values.termPdf;
            values.moderate = {
                noOfRoomTypesOffered: {
                    new_value: values.noOfRoomTypesOffered
                },
                description: {
                    new_value: values.description
                }
            };
            updateProperty(values);

        } else {
            values.termPdf = termPdf;
            createProperty(values)
        }

    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const getFileBlob = useCallback((fileBlob) => {
        setTermPdf(fileBlob);
        setTermPdfUpdated(true);
    }, []);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const renderRoomType = useCallback(() => {
        if (roomType) {
            return roomType.map((tg: any, key: number) => {
                return (
                    <option key={key} value={`${tg}`}>{tg}</option>
                )
            });
        }
    }, []);

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const changeFormEditable = useCallback(() => {
        scrollToTop();
        if (editEnable) {
            setEditEnable(false);
        } else {
            setEditEnable(true);
            callApi();

        }
    }, [callApi, editEnable]);


    ///////////////////////////whichRateChanelManager Validation/////////////////////////////////////////
    const onChangeRateChannelManager = useCallback((value) => {
        if (value === CommonAnswer.YES) {
            setWhichRateChanelManager(true);
        } else {
            setWhichRateChanelManager(false);
        }
    }, []);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const onChangeUtilisePMS = useCallback((value) => {
        if (value === CommonAnswer.YES) {
            setWhichPMSDoYouUtilise(true);
        } else {
            setWhichPMSDoYouUtilise(false);
        }
    }, []);


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const onReviewedProperty = () => {
        if (propertyId) {
            setLoading(true);
            const payload = {
                propertyId: propertyId
            }
            propertyReviewed(payload);

        }
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const validatePhoneNumber = (inputNumber: string) => {
        let number = formatPhoneNumber(inputNumber)

        let valid = false;
        let isPossible = false;
        try {
            const phoneUtil = PhoneNumberUtil.getInstance();
            isPossible = phoneUtil.isPossibleNumber(phoneUtil.parse(number))
            valid = phoneUtil.isValidNumber(phoneUtil.parse(number));
        } catch (e) {
            valid = false;
        }

        if (isPossible && valid) {

            setValidPhoneNumber(true);
            return true;
        } else {
            setValidPhoneNumber(false);
            return false;
        }
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const validateContactNumber = (inputNumber: string) => {
        let number = formatPhoneNumber(inputNumber)

        let valid = false;
        let isPossible = false;
        try {
            const phoneUtil = PhoneNumberUtil.getInstance();
            isPossible = phoneUtil.isPossibleNumber(phoneUtil.parse(number))
            valid = phoneUtil.isValidNumber(phoneUtil.parse(number));
        } catch (e) {
            valid = false;
        }

        if (isPossible && valid) {

            setValidContactNumber(true);
            return true;
        } else {
            setValidContactNumber(false);
            return false;
        }
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            <div className="content-title fixed_title">
                {
                    <BreadCrumbPage allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} isPageName="Property" tab={tab} className="mt-1" selectedBrand={selectedBrand} propertyData={propertyData} setFormOpen={setFormOpen} ></BreadCrumbPage>
                }
                <div className='ml-auto' style={{ position: "relative", zIndex: 9999 }}>
                    <div className='d-flex align-items-center justify-content-end gx-2'>
                        {/* <HelpModal /> */}
                        {
                            !loading && !editEnable && propertyId && canBrandPropertyModuleWrite(selectedBrand.key, propertyId, UserAccess.property_access.details.code) && <Button id='edit-button' style={formButton} className="mb-0" onClick={changeFormEditable} >Edit</Button>
                        }
                    </div>
                </div>
            </div>

            <Container fluid className="p-0">
                <div className="jumbotron" style={{ marginTop: "70px" }}>
                    <Form onSubmit={handleSubmit(onFinish)}>
                        <Row>
                            <Col md={1}></Col>
                            <Col md={10}>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Brand</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{selectedBrand.name}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="brandId"
                                                defaultValue={selectedBrand.key}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <Select defaultValue={selectedBrand.key} className={`${errors.brandId ? 'is-invalid' : ''}`} style={{ width: '100%' }} onChange={(value) => field.onChange(value)} onSelect={onBrandSelect} disabled={selectedBrand} showSearch>
                                                        {renderBrandList()}
                                                    </Select>
                                                }
                                            />}
                                        {editEnable && <div className="invalid-feedback">Please select a brand!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Property type</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertyData?.propertyType?.name}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="propertyType"
                                                defaultValue={propertyData?.propertyType?._id}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <Select defaultValue={propertyData?.propertyType?._id} className={`${errors.propertyType ? 'is-invalid' : ''}`} style={{ width: '100%' }}
                                                        onChange={(value, option) => {
                                                            field.onChange(value)
                                                        }} onSelect={onTypeSelect} showSearch>
                                                        {propertyTypeList}
                                                    </Select>
                                                }
                                            />}
                                        {editEnable && <div className="invalid-feedback">Please select a property type!</div>}
                                    </Col>
                                </Row>
                                {subType.length !== 0 &&
                                    <Row className='formSpace'>
                                        <Col md={6} style={{ textAlign: 'right' }}>
                                            <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Property Sub type</Form.Label>
                                        </Col>
                                        <Col md={6} style={{ textAlign: 'left' }}>
                                            {!editEnable && <p className="lead">{subTypeData[0]?.name}</p>}
                                            {editEnable &&
                                                <Controller
                                                    name="propertySubType"
                                                    defaultValue={subTypeData[0]?._id}
                                                    control={control}
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    render={({ field }) =>
                                                        <Select defaultValue={subTypeData[0]?._id} className={`${errors.propertySubType ? 'is-invalid' : ''}`} style={{ width: '100%' }} onChange={(value) => field.onChange(value)} showSearch onSelect={onSubTypeSelect}>
                                                            {renderSubType}
                                                        </Select>
                                                    }
                                                />}
                                            {editEnable && <div className="invalid-feedback">Please select a sub type!</div>}
                                        </Col>
                                    </Row>
                                }
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Property name</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertyData?.propertyName}</p>}
                                        {editEnable && <Form.Control className={`${errors.propertyName ? 'is-invalid' : ''}`} defaultValue={propertyData?.propertyName} {...register("propertyName", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please input property name!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Year property was built?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && propertyData?.yearPropertyWasBuilt && <p className="lead">{moment(propertyData?.yearPropertyWasBuilt).format('YYYY')} </p>}
                                        {editEnable &&
                                            <Controller
                                                name="yearPropertyWasBuilt"
                                                control={control}
                                                rules={
                                                    { required: true }
                                                }
                                                defaultValue={(propertyData?.yearPropertyWasBuilt) ? new Date(propertyData?.yearPropertyWasBuilt) : undefined}
                                                render={({ field }) =>
                                                    <DatePicker className={`form-control ${errors.yearPropertyWasBuilt ? 'is-invalid' : ''}`} selected={field.value} dateFormat={'yyyy'} showYearPicker={true} onChange={(date) => field.onChange(date)} />}
                                            />
                                        }
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="yearPropertyWasBuilt"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select year property was built!</div>}
                                            />
                                        }
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} When were communal areas last renovated?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && propertyData?.whenWereCommunalAreasLastRenovated && <p className="lead">{moment(propertyData?.whenWereCommunalAreasLastRenovated).format('YYYY')} </p>}
                                        {editEnable &&
                                            <Controller
                                                name="whenWereCommunalAreasLastRenovated"
                                                control={control}
                                                rules={
                                                    { required: true }
                                                }
                                                defaultValue={(propertyData?.whenWereCommunalAreasLastRenovated) ? new Date(propertyData?.whenWereCommunalAreasLastRenovated) : undefined}
                                                render={({ field }) =>
                                                    <DatePicker className={`form-control ${errors.whenWereCommunalAreasLastRenovated ? 'is-invalid' : ''}`} selected={field.value} dateFormat={'yyyy'} showYearPicker={true} onChange={(date) => field.onChange(date)} />}
                                            />
                                        }
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="whenWereCommunalAreasLastRenovated"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select when were communal areas last renovated!</div>}
                                            />
                                        }
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Description</Form.Label>
                                    </Col>

                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <div dangerouslySetInnerHTML={{ __html: propertyData?.moderate?.description?.new_value ? propertyData?.moderate?.description?.new_value : propertyData?.description }}></div>}
                                        {editEnable && <Controller
                                            name="description"
                                            control={control}
                                            defaultValue={propertyData?.moderate?.description?.new_value ? propertyData?.moderate?.description?.new_value : propertyData?.description}
                                            rules={{
                                                required: true,

                                            }}
                                            render={({ field }) =>
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    config={{
                                                        toolbar: {
                                                            items: [
                                                                'heading', '|',
                                                                'undo', 'redo'
                                                            ]
                                                        },
                                                        heading: {
                                                            options: [
                                                                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                                            ]
                                                        }


                                                    }}
                                                    data={propertyData?.moderate?.description?.new_value ? propertyData?.moderate?.description?.new_value : propertyData?.description}
                                                    onChange={(event, editor) => {
                                                        field.onChange(editor.getData())
                                                    }}
                                                />
                                            }
                                        />}

                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="description"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please input description!</div>}
                                            />
                                        }
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Telephone</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{formatPhoneNumber(propertyData?.telephone)}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="telephone"
                                                control={control}
                                                defaultValue={propertyData?.telephone}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <PhoneInput
                                                        placeholder=''
                                                        isValid={(inputNumber: string) => {
                                                            return validatePhoneNumber(
                                                                inputNumber,

                                                            );
                                                        }}

                                                        containerClass={`${errors.telephone ? 'is-invalid' : ''}`} value={String(field.value)} country='gb' onChange={(phone) => { field.onChange(phone); }} />}
                                            />
                                        }
                                        {editEnable && <div className="invalid-feedback">Please input telephone!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Out of hours (24 hr)</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{formatPhoneNumber(propertyData?.outOfHourContactNumber)}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="outOfHourContactNumber"
                                                control={control}
                                                defaultValue={propertyData?.outOfHourContactNumber}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <PhoneInput
                                                        placeholder=''
                                                        isValid={(inputNumber: string) => {
                                                            return validateContactNumber(
                                                                inputNumber,

                                                            );
                                                        }}

                                                        containerClass={`${errors.outOfHourContactNumber ? 'is-invalid' : ''}`} value={String(field.value)} country='gb' onChange={(phone) => { field.onChange(phone); }} />}
                                            />
                                        }
                                        {editEnable && <div className="invalid-feedback">Please input outOfHourContactNumber!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <OverlayTrigger
                                            placement="top"
                                            delay={overlayTriggerDelay}
                                            overlay={<Tooltip id='tooltip-table-top'>The number of accommodation variations (inc. price variations, such as upgrade options) within the property</Tooltip>}
                                        >
                                            <Form.Label>{editEnable && <span className='requiredMark'>*</span>} No of different inventory types in the building</Form.Label>
                                        </OverlayTrigger>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertyData?.moderate?.noOfRoomTypesOffered?.new_value ? propertyData?.moderate?.noOfRoomTypesOffered?.new_value : propertyData?.noOfRoomTypesOffered}</p>}
                                        {editEnable &&

                                            <Controller
                                                name="noOfRoomTypesOffered"
                                                defaultValue={propertyData?.moderate?.noOfRoomTypesOffered?.new_value ? propertyData?.moderate?.noOfRoomTypesOffered?.new_value : propertyData?.noOfRoomTypesOffered}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={overlayTriggerDelay}
                                                        overlay={<Tooltip id='tooltip-table-top'>The number of accommodation variations (inc. price variations, such as upgrade options) within the property</Tooltip>}
                                                    >
                                                        <Form.Select defaultValue={propertyData?.moderate?.noOfRoomTypesOffered?.new_value ? propertyData?.moderate?.noOfRoomTypesOffered?.new_value : propertyData?.noOfRoomTypesOffered} className={`${errors.noOfRoomTypesOffered ? 'is-invalid' : ''}`} onChange={(value) => field.onChange(value)}>
                                                            <option>Select no of different inventory</option>
                                                            {renderRoomType()}
                                                        </Form.Select>
                                                    </OverlayTrigger>

                                                }
                                            />

                                        }
                                        {editEnable && <div className="invalid-feedback">Please input no of different inventory types in the building!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Timezone</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertyData?.timezone}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="timezone"
                                                defaultValue={propertyData?.timezone}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <Select defaultValue={propertyData?.timezone} className={`${errors.timezone ? 'is-invalid' : ''}`} style={{ width: '100%' }} onChange={(value) => field.onChange(value)} showSearch>
                                                        {renderTimezone()}
                                                    </Select>
                                                }
                                            />}
                                        {editEnable && <div className="invalid-feedback">Please select timezone!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Currency</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertyData?.currency}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="currency"
                                                defaultValue={propertyData?.currency}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <Select defaultValue={propertyData?.currency} className={`${errors.currency ? 'is-invalid' : ''}`} style={{ width: '100%' }} onChange={(value) => field.onChange(value)} showSearch>
                                                        {renderCurrency()}
                                                    </Select>
                                                }
                                            />}
                                        {editEnable && <div className="invalid-feedback">Please select currency!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>Location</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertyData?.country}</p>}
                                        {editEnable && <GooglePlaces onPlaceSelect={(location) => setLocationData(location)} />}
                                        <div style={{ marginTop: '10px' }}></div>
                                        <GoogleMap
                                            googleMapURL={process.env.REACT_APP_MAP_URL}
                                            loadingElement={<div style={{ height: `100%` }} />}
                                            containerElement={<div style={{ height: `350px` }} />}
                                            mapElement={<div style={{ height: `100%` }} />}
                                            location={location}
                                            setCoords={setCoords}
                                        />
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Country</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertyData?.country}</p>}
                                        {editEnable && <Form.Control className={`${errors.country ? 'is-invalid' : ''}`} defaultValue={propertyData?.country}  {...register("country", { required: (location?.country) ? false : (propertyData?.country) ? false : true })} readOnly />}
                                        {/* {editEnable && <div className="invalid-feedback">Please input country!</div>} */}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Address1</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertyData?.address1}</p>}
                                        {editEnable && <Form.Control className={`${errors.address1 ? 'is-invalid' : ''}`} defaultValue={propertyData?.address1} {...register("address1", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please input address1!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>Address2</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertyData?.address2}</p>}
                                        {editEnable && <Form.Control defaultValue={propertyData?.address2} {...register("address2")} />}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} City</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertyData?.city}</p>}
                                        {editEnable && <Form.Control className={`${errors.city ? 'is-invalid' : ''}`} defaultValue={propertyData?.city} {...register("city", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please input city!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Postal/Zip code</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertyData?.postcode}</p>}
                                        {editEnable && <Form.Control className={`${errors.postcode ? 'is-invalid' : ''}`} defaultValue={propertyData?.postcode} {...register("postcode", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please input postal code!</div>}
                                    </Col>
                                </Row>
                                <OverlayTrigger
                                    placement="top"
                                    delay={overlayTriggerDelay}
                                    overlay={<Tooltip id='tooltip-table-top' style={toolTipStyle}>Please specify the e-mail address that will receive all system generated messages such as booking requests etc.</Tooltip>}
                                >
                                    <Row className='formSpace'>
                                        <Col md={6} style={{ textAlign: 'right' }}>
                                            <Form.Label>{editEnable && <span className='requiredMark'>*</span>} System notification email</Form.Label>
                                        </Col>
                                        <Col md={6} style={{ textAlign: 'left' }}>
                                            {!editEnable && <p className="lead">{propertyData?.systemNotificationEmail}</p>}
                                            {editEnable && <Form.Control type='email' className={`${errors.systemNotificationEmail ? 'is-invalid' : ''}`} defaultValue={propertyData?.systemNotificationEmail} {...register("systemNotificationEmail", { required: true })} />}
                                            {editEnable && <div className="invalid-feedback">Please input system notification email!</div>}
                                        </Col>
                                    </Row>
                                </OverlayTrigger>

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Does the property utilise a PMS to manage inventory?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertyData?.doesPropertyUtilisePMSToManageInventory}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="doesPropertyUtilisePMSToManageInventory"
                                                defaultValue={propertyData?.doesPropertyUtilisePMSToManageInventory}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            propertyData?.doesPropertyUtilisePMSToManageInventory === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doesPropertyUtilisePMSToManageInventory"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeUtilisePMS(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doesPropertyUtilisePMSToManageInventory"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeUtilisePMS(value.currentTarget.value); }}
                                                                />
                                                        }

                                                        {
                                                            propertyData?.doesPropertyUtilisePMSToManageInventory === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doesPropertyUtilisePMSToManageInventory"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeUtilisePMS(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doesPropertyUtilisePMSToManageInventory"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeUtilisePMS(value.currentTarget.value); }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="doesPropertyUtilisePMSToManageInventory"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>


                                {whichPMSDoYouUtilise && <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>}  Which PMS do you utilise?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertyData?.propertyManagementSystemOption}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="propertyManagementSystemOption"
                                                defaultValue={propertyData?.propertyManagementSystemOption}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <Select defaultValue={propertyData?.propertyManagementSystemOption} className={`${errors.propertyManagementSystemOption ? 'is-invalid' : ''}`} style={{ width: '100%' }}
                                                        onChange={(value, option) => {
                                                            if (value === '0') {
                                                                setPmsValue(true);
                                                            } else {
                                                                setPmsValue(false);
                                                            }

                                                            setPropertyManagementName(option);

                                                            field.onChange(value)
                                                        }}  >
                                                        {propertyManagementList}
                                                    </Select>
                                                }
                                            />}
                                        {editEnable && <div className="invalid-feedback">Please select a propertyManagement!</div>}
                                    </Col>
                                </Row>}
                                {
                                    pmsValue && <Row className='formSpace'>
                                        <Col md={6} style={{ textAlign: 'right' }}>
                                            <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Please advise which PMS you utilise</Form.Label>
                                        </Col>
                                        <Col md={6} style={{ textAlign: 'left' }}>
                                            {!editEnable && <p className="lead">{propertyData?.whichPMSDoYouUtilise}</p>}
                                            {editEnable && <Form.Control className={`${errors.whichPMSDoYouUtilise ? 'is-invalid' : ''}`} defaultValue={propertyData?.whichPMSDoYouUtilise} {...register("whichPMSDoYouUtilise", { required: true })} />}
                                            {editEnable && <div className="invalid-feedback">Please input your answer!</div>}
                                        </Col>
                                    </Row>
                                }

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Is property managed via a rate channel manager?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertyData?.rateChannelManager}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="rateChannelManager"
                                                defaultValue={propertyData?.rateChannelManager}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            propertyData?.rateChannelManager === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="rateChannelManager"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeRateChannelManager(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="rateChannelManager"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeRateChannelManager(value.currentTarget.value); }}
                                                                />
                                                        }

                                                        {
                                                            propertyData?.rateChannelManager === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="rateChannelManager"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeRateChannelManager(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="rateChannelManager"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeRateChannelManager(value.currentTarget.value); }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="rateChannelManager"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>


                                {whichRateChanelManager && <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>}  Which rate channel manager do you utilize?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertyData?.channelManagerOption}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="channelManagerOption"
                                                defaultValue={propertyData?.channelManagerOption}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <Select defaultValue={propertyData?.channelManagerOption} className={`${errors.channelManagerOption ? 'is-invalid' : ''}`} style={{ width: '100%' }}
                                                        onChange={(value, option) => {
                                                            if (value === '0') {
                                                                setChannelValue(true);
                                                            } else {
                                                                setChannelValue(false);
                                                            }
                                                            setChannelManagerName(option);
                                                            field.onChange(value)
                                                        }}  >
                                                        {channelManagerList}
                                                    </Select>
                                                }
                                            />}
                                        {editEnable && <div className="invalid-feedback">Please select a channelManagerOption!</div>}
                                    </Col>
                                </Row>}
                                {
                                    channelValue && <Row className='formSpace'>
                                        <Col md={6} style={{ textAlign: 'right' }}>
                                            <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Please advise which rate channel manager you utilise</Form.Label>
                                        </Col>
                                        <Col md={6} style={{ textAlign: 'left' }}>
                                            {!editEnable && <p className="lead">{propertyData?.whichRateChanelManager}</p>}
                                            {editEnable && <Form.Control className={`${errors.whichRateChanelManager ? 'is-invalid' : ''}`} defaultValue={propertyData?.whichRateChanelManager} {...register("whichRateChanelManager", { required: true })} />}
                                            {editEnable && <div className="invalid-feedback">Please input your answer!</div>}
                                        </Col>
                                    </Row>
                                }

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Contact for property audit</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertyData?.contactForPropertyAudit}</p>}
                                        {editEnable && <Form.Control className={`${errors.contactForPropertyAudit ? 'is-invalid' : ''}`} defaultValue={propertyData?.contactForPropertyAudit} {...register("contactForPropertyAudit", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please input contact for property audit!</div>}
                                    </Col>
                                </Row>

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Contact email</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{propertyData?.contactEmail}</p>}
                                        {editEnable && <Form.Control type='email' className={`${errors.contactEmail ? 'is-invalid' : ''}`} defaultValue={propertyData?.contactEmail} {...register("contactEmail", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please input contact email!</div>}
                                    </Col>
                                </Row>

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Contact telephone</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{formatPhoneNumber(propertyData?.contactTelephone)}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="contactTelephone"
                                                control={control}
                                                defaultValue={propertyData?.contactTelephone}
                                                rules={{
                                                    required: true,

                                                }}
                                                render={({ field }) =>
                                                    <PhoneInput
                                                        placeholder=''
                                                        isValid={(inputNumber: string) => {

                                                            return validatePhoneNumber(
                                                                inputNumber

                                                            );
                                                        }}

                                                        containerClass={`${errors.contactTelephone ? 'is-invalid' : ''}`} value={String(field.value)} country='gb' onChange={(phone) => { field.onChange(phone); }} />}
                                            />
                                        }
                                        {editEnable && <div className="invalid-feedback">Please input contact telephone!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Terms & conditions (PDF only)</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <ImageUpload getImageBlob={getFileBlob} type="pdf" value={termPdf} disabled={true} newUpload={false} />}
                                        {editEnable &&
                                            <Controller
                                                name="termPdf"
                                                defaultValue={String(termPdf)}
                                                control={control}
                                                rules={{
                                                    required: termPdf ? false : true
                                                }}
                                                render={({ field }) =>
                                                    <ImageUpload getImageBlob={getFileBlob} type="pdf" value={termPdf} disabled={false} newUpload={true} />
                                                }
                                            />}

                                        {editEnable && errors.termPdf && <div className="invalid-feedback-custom">Please upload term & condition</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!loading && !editEnable && propertyId && getLoggedUserType() === UserGroups.super_admin && (selectedBrand?.brandStatus === 'accepted') && (propertyData?.propertyStatus !== PropertyStatus.approved) && (allInventoriesApprovalStatus === ALL_INVENTORIES_APPROVAL_STATUS.APPROVE || propertyData?.propertyStatus === PropertyStatus.review || (propertyData?.propertyStatus === PropertyStatus.pending)) &&
                                            <Button id='approve-button' style={formButton} onClick={() => { onApprovedProperty() }}>Approve</Button>
                                        }

                                        {!loading && !editEnable && propertyId && getLoggedUserType() === UserGroups.super_admin && (selectedBrand?.brandStatus === 'accepted') && (propertyData?.propertyStatus === PropertyStatus.pending && allInventoriesApprovalStatus === ALL_INVENTORIES_APPROVAL_STATUS.APPROVE) &&
                                            <Button id='approve-button' style={formButton2} onClick={() => { onReviewedProperty() }}>Review</Button>
                                        }
                                        {!loading && !propertyId && canBrandPropertyModuleWrite(selectedBrand.key, propertyId, UserAccess.property_access.details.code) &&
                                            <Button type="submit" id='approve-button' style={formButton}>Create</Button>
                                        }
                                        {!loading && editEnable && propertyId && canBrandPropertyModuleWrite(selectedBrand.key, propertyId, UserAccess.property_access.details.code) &&
                                            <Button type="submit" id='approve-button' style={formButton}>Update</Button>
                                        }
                                        {
                                            loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={1}></Col>
                        </Row>
                    </Form>
                </div>
            </Container>
        </>
    )
};

export default PropertyDetailsForm;