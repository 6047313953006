import { Select } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { getLoggedUserEmail, getLoggedUserType } from '../../../../common/functions';
import { UserGroups, CommonAnswer, InventoryStatus } from '../../../../common/constants';
import { Controller, useForm } from 'react-hook-form';
import { Row, Col, Form, Button, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { InventoryFormInput } from '../../../../common/interfaces';
import { formButton, formButton2, overlayTriggerDelay, toolTipStyle } from '../../../../common/components-style';
import { ErrorMessage } from '@hookform/error-message';
import BreadCrumbPage from '../../../common/BreadCrumbPage';
import moment from 'moment'
import DatePicker from "react-datepicker";
import { setStorage } from '../../../../common/storage';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { HelpModal } from '../../../common/HelpModal';

const { Option } = Select;


const InventoryDetailsForm: React.FC<any> = ({ allInOnePropertyBreadCrumb, inventoryId, selectedProperty, selectedBrand, setFormOpen, loadData }): JSX.Element => {
  const { register, setValue, handleSubmit, formState: { errors }, control, reset } = useForm<InventoryFormInput>();
  const [editEnable, setEditEnable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [roomTypeList, setRoomTypeList] = useState<any>();
  const [roomTypeId, setRoomTypeId] = useState<any>(0);
  const [bedTypeList, setBedTypeList] = useState<any>();
  const [properties, setProperties] = useState<any>(null);
  const [offeredRoom, setOfferedRoom] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [dataList, setDataList] = useState<any>([]);
  const [dummyList, setDummyList] = useState<any>([]);
  const [messages, setMessage] = useState<any>(null);
  const [bedTypeId, setBedTypeId] = useState<Array<any>>([]);
  const [showCreateButton, setShowCreateButton] = useState<boolean>(false);
  const [noOfRollOutBedsAvailable, setNoOfRollOutBedsAVailable] = useState<number>(0);
  const [noOfCotsAvailable, setNoOfCotsAvailable] = useState<number>(0);
  const [currency, setCurrency] = useState<any>();
  const [extraCostPerBed, setExtraCostPerBed] = useState<any>();
  const [extraCostOfCot, setExtraCostOfCot] = useState<any>();
  const [areAllApartmentsNonSmoking, setAreAllApartmentsNonSmoking] = useState<boolean>(false);
  const [doYouHaveMaximumLengthOfStay, setDoYouHaveMaximumLengthOfStay] = useState<boolean>(false);

  const [inventoryData, setInventoryData] = useState<any>();
  // const [updatedDetails, setUpdatedDetails] = useState<boolean>(false);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { inventoryApproval, inventoryReview, getRoomTypeList, getBedTypeList, getInventoryById, createInventory, updateInventory, resetInventory, getAllPropertyNameList, getProperty, getInventoryByPropertyId } = useStoreActions<any>((actions) => ({
    getRoomTypeList: actions.inventory.getRoomTypeList,
    getBedTypeList: actions.inventory.getBedTypeList,
    getInventoryById: actions.inventory.getInventoryById,
    createInventory: actions.inventory.createInventory,
    updateInventory: actions.inventory.updateInventory,
    resetInventory: actions.inventory.resetInventory,
    getProperty: actions.property.getProperty,
    getAllPropertyNameList: actions.property.getAllPropertyNameList,
    getInventoryByPropertyId: actions.inventory.getInventoryByPropertyId,
    inventoryApproval: actions.inventory.inventoryApproval,
    inventoryReview: actions.inventory.inventoryReview,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { inventoryReviewError, inventoryReviewSuccess, inventoryApprovalSuccess, inventoryApprovalError, getRoomTypeListSuccess, getRoomTypeListError, getBedTypeListSuccess, getBedTypeListError
    , getInventoryByIdSuccess, getInventoryByIdError, createInventorySuccess, createInventoryError, updateInventorySuccess, updateInventoryError,
    getAllPropertyNameListSuccess, getInventoryByPropertyIdSuccess, getInventoryByPropertyIdError,
    getAllPropertyNameListError, getPropertySuccess, getPropertyError } = useStoreState<any>((state) => ({
      getRoomTypeListSuccess: state.inventory.getRoomTypeListSuccess,
      getRoomTypeListError: state.inventory.getRoomTypeListError,
      getBedTypeListSuccess: state.inventory.getBedTypeListSuccess,
      getBedTypeListError: state.inventory.getBedTypeListError,
      getInventoryByIdSuccess: state.inventory.getInventoryByIdSuccess,
      getInventoryByIdError: state.inventory.getInventoryByIdError,
      createInventorySuccess: state.inventory.createInventorySuccess,
      createInventoryError: state.inventory.createInventoryError,
      updateInventorySuccess: state.inventory.updateInventorySuccess,
      updateInventoryError: state.inventory.updateInventoryError,
      getAllPropertyNameListSuccess: state.property.getAllPropertyNameListSuccess,
      getAllPropertyNameListError: state.property.getAllPropertyNameListError,
      getPropertySuccess: state.property.getPropertySuccess,
      getPropertyError: state.property.getPropertyError,
      getInventoryByPropertyIdSuccess: state.inventory.getInventoryByPropertyIdSuccess,
      getInventoryByPropertyIdError: state.inventory.getInventoryByPropertyIdError,
      inventoryApprovalSuccess: state.inventory.inventoryApprovalSuccess,
      inventoryApprovalError: state.inventory.inventoryApprovalError,
      inventoryReviewSuccess: state.inventory.inventoryReviewSuccess,
      inventoryReviewError: state.inventory.inventoryReviewError,
    }));



  ////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    reset();
    if (selectedProperty) {
      getProperty(selectedProperty._id)
    }
  }, [getProperty, reset, selectedProperty]);

  ////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (selectedProperty) {
      getInventoryByPropertyId(selectedProperty._id);
    }

  }, [getInventoryByPropertyId, selectedProperty]);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getAllPropertyNameList();
  }, [getAllPropertyNameList]);

  ////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getRoomTypeList();
  }, [getRoomTypeList]);

  ////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getBedTypeList();
  }, [getBedTypeList]);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (inventoryId) {
      getInventoryById(inventoryId);
    } else {
      setEditEnable(true);
    }

  }, [getInventoryById, inventoryId]);


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const scrollToTop = () => {
    // messagesRef?.current?.scrollIntoView(({ behavior: "smooth", block: "start", inline: "start" }));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getAllPropertyNameListError) {
      toast.error(getAllPropertyNameListError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }

    if (getAllPropertyNameListSuccess) {
      const property = getAllPropertyNameListSuccess.data;
      setProperties(property);
    }

  }, [getAllPropertyNameListError, getAllPropertyNameListSuccess, selectedProperty]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getPropertySuccess) {
      const data = getPropertySuccess.data;
      setCurrency(data.currency);
      setExtraCostPerBed('Extra cost per bed ');
      setExtraCostOfCot('Extra cost of cot ')
      if (data?.moderate?.noOfRoomTypesOffered?.new_value) {
        setOfferedRoom(data?.moderate?.noOfRoomTypesOffered?.new_value);
      } else {
        setOfferedRoom(data?.noOfRoomTypesOffered);
      }
    }

    if (getPropertyError) {
      toast.error(getPropertyError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
  }, [getPropertySuccess, getPropertyError]);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getInventoryByPropertyIdSuccess) {
      setTotalCount(getInventoryByPropertyIdSuccess.data.length)
    }
    if (getInventoryByPropertyIdError) {
      toast.error(getInventoryByPropertyIdError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
    setMessage(totalCount + ' of ' + offeredRoom + ' room types defined');
    if (totalCount === offeredRoom) {
      setShowCreateButton(true);
    } else {
      setShowCreateButton(false);
    }
  }, [getInventoryByPropertyIdError, getInventoryByPropertyIdSuccess, totalCount, offeredRoom]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getInventoryByIdSuccess) {
      const inventory = getInventoryByIdSuccess.data;
      setStorage('myo_selected_inventory', inventory)
      setInventoryData(inventory);

      let newData: any = [];
      _.map(inventory.bedType, element =>
        newData.push({ id: element._id, name: element.name })
      );
      setDataList(newData);
      setRoomTypeId(inventory?.accommodationConfiguration?._id)
      if (inventory.areAllApartmentsNonSmoking === CommonAnswer.NO) {
        setAreAllApartmentsNonSmoking(true);
      } else {
        setAreAllApartmentsNonSmoking(false);
      }

      if (inventory.doYouHaveMaximumLengthOfStay === CommonAnswer.YES) {
        setDoYouHaveMaximumLengthOfStay(true);
      } else {
        setDoYouHaveMaximumLengthOfStay(false);
      }

      setNoOfRollOutBedsAVailable(inventory.noOfRollOutBedsAvailable || 0);
      setNoOfCotsAvailable(inventory.noOfCotsAvailable || 0);
    }
    if (getInventoryByIdError) {
      toast.error(getInventoryByIdError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
  }, [getInventoryByIdError, getInventoryByIdSuccess]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getRoomTypeListSuccess) {
      const roomType = getRoomTypeListSuccess.data;
      setRoomTypeList(roomType);
    }
    if (getRoomTypeListError) {
      toast.error(getRoomTypeListError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
  }, [getRoomTypeListError, getRoomTypeListSuccess]);


  useEffect(() => {
  
    if (inventoryData?.bedType?.length === 0) {

      if (roomTypeList) {
        const occupancy = _.first(_.filter(roomTypeList, user => (user._id === inventoryData?.accommodationConfiguration?._id))).occupancy;

      let list = new Array(occupancy);
      
      let data: any = [];
      if (list) {
        _.each(list, (yy) => {
          data.push([]);
          setDummyList(data);

        })
      }
    }
    }
  
  }, [inventoryData, inventoryData?.accommodationConfiguration?._id, inventoryData?.bedType?.length, roomTypeList])

  //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getBedTypeListSuccess) {
      const bedType = getBedTypeListSuccess.data;
      setBedTypeList(bedType);
    }
    if (getBedTypeListError) {
      toast.error(getBedTypeListError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }

  }, [getBedTypeListError, getBedTypeListSuccess]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  const renderProperty = useCallback(() => {
    let includeList: Array<string> = [];
    let filterData;
    if ([UserGroups.chief_property_admin, UserGroups.property_admin].includes(getLoggedUserType())) {
      includeList = [UserGroups.chief_property_admin, UserGroups.property_admin];
      filterData = _.filter(properties, propVal => _.find(propVal.propertyUsers, value => value.email === getLoggedUserEmail() && _.includes(includeList, value.role)));
    } else {
      filterData = properties;
    }
    if (properties) {
      return filterData.map((acc: any, key: number) => {
        return (
          <Option key={key} value={`${acc._id}`} >{acc.propertyName}</Option>
        )
      });
    }
  }, [properties]);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  const renderRoomType = useCallback(() => {
    if (roomTypeList) {
      return roomTypeList.map((acc: any, key: number) => {
        return (
          <Option key={key} value={`${acc._id}`} >{acc.name}</Option>
        )
      });
    }
  }, [roomTypeList]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const onRoomTypeChange = useCallback((value) => {
    if (roomTypeList) {
      const occupancy = _.first(_.filter(roomTypeList, user => (user._id === value))).occupancy;
      setRoomTypeId(value);
      setValue('noOfBedrooms', occupancy);

      let list = new Array(occupancy);

      let newData: any = [];
      if (list) {
        _.each(list, (yy) => {
          newData.push([]);
          setDataList(newData);

        })
      }


    }

  }, [roomTypeList, setValue])

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const renderBedType = useCallback(() => {
    if (bedTypeList) {
      return bedTypeList.map((acc: any, key: number) => {
        return (

          <Option key={key} value={`${acc._id}`} >{acc.name}</Option>
        )
      });
    }
  }, [bedTypeList])

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const onBedTypeChange = useCallback((value) => {
    let idList: Array<any> = bedTypeId;
    if (bedTypeList) {
      _.each(bedTypeList, (val) => {
        if (val._id === value) {
          idList.push(val._id);
        }
      });
      setBedTypeId(idList);
    }

  }, [bedTypeId, bedTypeList]);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  const rollOutBedsChange = useCallback((value) => {
    setNoOfRollOutBedsAVailable(Number(value));
  }, []);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  const cotsAvailableChange = useCallback((value) => {
    setNoOfCotsAvailable(Number(value));
  }, []);

  // //////////////////////////////////////////////////////////////////////////////////////////////////////
  // const renderRollOutBedsAvailable = useCallback(() => {
  //   return <Select onChange={rollOutBedsChange} >
  //     <Option value="0">0</Option>
  //     <Option value="1">1</Option>
  //     <Option value="2">2</Option>
  //   </Select>
  // }, [rollOutBedsChange]);

  // //////////////////////////////////////////////////////////////////////////////////////////////////////
  // const renderCotsAvailable = useCallback(() => {
  //   return <Select onChange={cotsAvailableChange} >
  //     <Option value="0">0</Option>
  //     <Option value="1">1</Option>
  //     <Option value="2">2</Option>
  //   </Select>
  // }, [cotsAvailableChange]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (createInventorySuccess) {
      loadData(createInventorySuccess.data);
      resetInventory();
      toast.success("Inventory created successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
      getInventoryById(createInventorySuccess?.data?._id);
      getInventoryByPropertyId(selectedProperty?._id);
      setEditEnable(false);
      scrollToTop();
    }

    if (createInventoryError) {
      toast.error(createInventoryError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }

    if (updateInventorySuccess) {
      toast.success("Inventory updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
      resetInventory();
      getInventoryByPropertyId(selectedProperty?._id);
      getInventoryById(updateInventorySuccess?.data?._id);
      setEditEnable(false);
      scrollToTop();
    }
    if (updateInventoryError) {
      toast.error(updateInventoryError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }

    if (inventoryApprovalSuccess) {
      getInventoryById(inventoryId);
      toast.success("Inventory approved successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }

    if (inventoryApprovalError) {
      toast.error(inventoryApprovalError?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }


    if (inventoryReviewSuccess) {
      getInventoryById(inventoryId);
      toast.success("Inventory reviewed successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }

    if (inventoryReviewError) {
      toast.error(inventoryReviewError?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
    setLoading(false);
  }, [resetInventory, createInventorySuccess, createInventoryError, updateInventorySuccess, updateInventoryError, getInventoryByPropertyId, selectedProperty, loadData, getInventoryById, inventoryApprovalSuccess, inventoryApprovalError, inventoryId, inventoryReviewSuccess, inventoryReviewError]);



  const onChangeAreAllApartmentsNonSmoking = useCallback((value) => {
    if (value === CommonAnswer.NO) {
      setAreAllApartmentsNonSmoking(true);
    } else {
      setAreAllApartmentsNonSmoking(false);
    }
  }, []);

  const onChangeDoYouHaveMaximumLengthOfStay = useCallback((value) => {
    if (value === CommonAnswer.YES) {
      setDoYouHaveMaximumLengthOfStay(true);
    } else {
      setDoYouHaveMaximumLengthOfStay(false);
    }
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const onSubmit = (values) => {
    setLoading(true);
    if (inventoryId) {
      values.id = inventoryId;
      const data = {
        id: inventoryId,
        // accommodationDetail: values.accommodationDetail,
        accommodationConfiguration: values.accommodationConfiguration ? values.accommodationConfiguration : inventoryData?.accommodationConfiguration?._id,
        whereWereIndividualApartments: values.whereWereIndividualApartments ? values.whereWereIndividualApartments : inventoryData?.whereWereIndividualApartments,
        areAllApartmentsNonSmoking: values.areAllApartmentsNonSmoking ? values.areAllApartmentsNonSmoking : inventoryData?.areAllApartmentsNonSmoking,
        pleaseAdviseWhatPercentageAreNonSmoking: values.pleaseAdviseWhatPercentageAreNonSmoking ? values.pleaseAdviseWhatPercentageAreNonSmoking ? inventoryData?.areAllApartmentsNonSmoking : inventoryData?.areAllApartmentsNonSmoking : '',
        howManyApartmentsAreSuitable: values.howManyApartmentsAreSuitable ? values.howManyApartmentsAreSuitable : inventoryData?.howManyApartmentsAreSuitable,
        whatBestDescribeYourApartments: values.whatBestDescribeYourApartments ? values.whatBestDescribeYourApartments : inventoryData?.whatBestDescribeYourApartments,
        noOfApartments: values.noOfApartments ? values.noOfApartments : inventoryData?.noOfApartments,
        noOfGuests: values.noOfGuests ? values.noOfGuests : inventoryData?.noOfGuests,
        noOfBedrooms: values.noOfBedrooms ? values.noOfBedrooms : inventoryData?.noOfBedrooms,
        noOfBathrooms: values.noOfBathrooms ? values.noOfBathrooms : inventoryData?.noOfBathrooms,
        minimumStayed: values.minimumStayed ? values.minimumStayed : inventoryData?.minimumStayed,
        bedType: bedTypeId,
        noOfRollOutBedsAvailable: noOfRollOutBedsAvailable ? noOfRollOutBedsAvailable : values.noOfRollOutBedsAvailable ? Number(values.noOfRollOutBedsAvailable) : inventoryData?.noOfRollOutBedsAvailable,
        noOfCotsAvailable: noOfCotsAvailable ? noOfCotsAvailable : values.noOfCotsAvailable ? Number(values.noOfCotsAvailable) : inventoryData?.noOfCotsAvailable,
        extraCostPerBed: noOfRollOutBedsAvailable === 0 ? 0 : values.extraCostPerBed ? Number(values.extraCostPerBed) : inventoryData?.extraCostPerBed,
        extraCostOfCot: noOfCotsAvailable === 0 ? 0 : values.extraCostOfCot ? Number(values.extraCostOfCot) : inventoryData?.extraCostOfCot,
        costTypeBed: values.costTypeBed ? values.costTypeBed : inventoryData.costTypeBed,
        costTypeCot: values.costTypeCot ? values.costTypeCot : inventoryData.costTypeCot,
        doYouHaveMaximumLengthOfStay: values.doYouHaveMaximumLengthOfStay ? values.doYouHaveMaximumLengthOfStay : inventoryData?.doYouHaveMaximumLengthOfStay,
        maximumStayed: values.maximumStayed ? values.maximumStayed : inventoryData?.maximumStayed === null ? 0 : inventoryData?.maximumStayed,
        // description: values.description,
        moderate: {
          details: {
            new_value: values.accommodationDetail ? values.accommodationDetail : inventoryData?.accommodationDetail
          },
          description: {
            new_value: values.description ? values.description : inventoryData?.description
          }
        }
      }
      updateInventory(data);
    } else {
      const data = {
        propertyId: values.propertyId,
        accommodationDetail: values.accommodationDetail,
        accommodationConfiguration: roomTypeId,
        whereWereIndividualApartments: values.whereWereIndividualApartments,
        areAllApartmentsNonSmoking: values.areAllApartmentsNonSmoking,
        pleaseAdviseWhatPercentageAreNonSmoking: values.pleaseAdviseWhatPercentageAreNonSmoking ? values.pleaseAdviseWhatPercentageAreNonSmoking : '',
        howManyApartmentsAreSuitable: values.howManyApartmentsAreSuitable,
        whatBestDescribeYourApartments: values.whatBestDescribeYourApartments,
        noOfApartments: values.noOfApartments,
        noOfGuests: values.noOfGuests,
        noOfBedrooms: values.noOfBedrooms,
        noOfBathrooms: values.noOfBathrooms,
        minimumStayed: values.minimumStayed,
        bedType: bedTypeId,
        noOfRollOutBedsAvailable: noOfRollOutBedsAvailable,
        noOfCotsAvailable: noOfCotsAvailable,
        extraCostPerBed: noOfRollOutBedsAvailable === 0 ? 0 : Number(values.extraCostPerBed),
        extraCostOfCot: noOfCotsAvailable === 0 ? 0 : Number(values.extraCostOfCot),
        costTypeBed: values.costTypeBed,
        costTypeCot: values.costTypeCot,
        description: values.description,
        doYouHaveMaximumLengthOfStay: values.doYouHaveMaximumLengthOfStay,
        maximumStayed: values.maximumStayed,
        moderate: {
          details: {
            new_value: values.accommodationDetail
          },
          description: {
            new_value: values.description
          }
        }
      }
      createInventory(data);
    }
  };
  // [inventoryId, updateInventory, roomTypeId, noOfRollOutBedsAvailable, noOfCotsAvailable, createInventory]);


  const changeFormEditable = useCallback(() => {
    scrollToTop();
    if (editEnable) {
      setEditEnable(false);
    } else {
      setEditEnable(true);
    }
  }, [editEnable]);

  //////////////// approval part ////////
  const onApprovedInventory = () => {
    setLoading(true);
    const payload = {
      inventoryId: inventoryId
    }
    inventoryApproval(payload);
  };

  //////////////// review part ////////
  const onReviewedInventory = () => {
    setLoading(true);
    const payload = {
      inventoryId: inventoryId
    }
    inventoryReview(payload);
  };



  //////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className="content-title fixed_title">
        <BreadCrumbPage allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} isPageName="Inventory" selectedProperty={selectedProperty} selectedBrand={selectedBrand} inventoryData={inventoryData} setFormOpen={setFormOpen} inventoryId={inventoryId}></BreadCrumbPage>
        <div className='edit_btn_wpr'>
          <div className='d-flex align-items-center justify-content-end gx-2'>
            {/* <HelpModal /> */}
            {
              !loading && !editEnable && inventoryId && <Button id='edit-button' className='mb-0' style={formButton} onClick={changeFormEditable} >Edit</Button>
            }
          </div>
        </div>
      </div>
      <div>
        <div className="jumbotron">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={1}></Col>
              <Col md={10}>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Property name</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{selectedProperty?.propertyName}</p>}
                    {editEnable &&
                      <Controller
                        name="propertyId"
                        defaultValue={selectedProperty._id}
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) =>
                          <Select defaultValue={selectedProperty._id} className={`${errors.propertyId ? 'is-invalid' : ''}`} style={{ width: '100%' }} onChange={(value) => field.onChange(value)} disabled={selectedProperty}>
                            {renderProperty()}
                          </Select>
                        }
                      />}
                    {editEnable && <div className="invalid-feedback">Please select property name!</div>}
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>Number of inventory types</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{inventoryData?.propertyId?.noOfRoomTypesOffered}</p>}
                    {editEnable && <Form.Control type="number" disabled defaultValue={offeredRoom || inventoryData?.propertyId?.noOfRoomTypesOffered} {...register("noOfRoomTypesOffered")} />}
                  </Col>
                </Row>

                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    <p className="lead message" style={{ textAlign: 'right', fontWeight: 'bold' }} >{messages}</p>
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Configuration</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{inventoryData?.accommodationConfiguration?.name}</p>}
                    {editEnable &&
                      <Controller
                        name="accommodationConfiguration"
                        defaultValue={inventoryData?.accommodationConfiguration?._id}
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) =>
                          <Select defaultValue={inventoryData?.accommodationConfiguration?._id} className={`${errors.accommodationConfiguration ? 'is-invalid' : ''}`} style={{ width: '100%' }} onChange={(value) => {
                            field.onChange(value);
                            onRoomTypeChange(value);
                          }} >
                            {renderRoomType()}
                          </Select>
                        }
                      />}
                    {editEnable && <div className="invalid-feedback">Please select accommodation configuration!</div>}
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <OverlayTrigger
                      placement="top"
                      delay={overlayTriggerDelay}
                      overlay={<Tooltip id='tooltip-table-top'>Please provide a short, factual sub-heading of the inventory property - this will appear on the Property and Booking Confirmation pages eg. ‘Standard Open Plan Apartment’ or 'Superior One Bedroom Apartment’. Max. 4 words.</Tooltip>}
                    >
                      <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Detail</Form.Label>
                    </OverlayTrigger>

                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{inventoryData?.moderate?.details?.new_value ? inventoryData?.moderate?.details?.new_value : inventoryData?.accommodationDetail}</p>}
                    {editEnable &&
                      <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>Please provide a short, factual sub-heading of the inventory property - this will appear on the Property and Booking Confirmation pages eg. ‘Standard Open Plan Apartment’ or 'Superior One Bedroom Apartment’. Max. 4 words.</Tooltip>}
                      >
                        <Form.Control className={`${errors.accommodationDetail ? 'is-invalid' : ''}`} as="textarea" rows={5} maxLength={32} defaultValue={inventoryData?.moderate?.details?.new_value ? inventoryData?.moderate?.details?.new_value : inventoryData?.accommodationDetail} {...register("accommodationDetail", { required: true })}
                          onChange={() => {
                            // setUpdatedDetails(true);
                          }} />
                      </OverlayTrigger>
                    }
                    {editEnable && <div className="invalid-feedback">Please input accommodation detail!</div>}
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>} What best describes your kitchen facilities?</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{inventoryData?.whatBestDescribeYourApartments}</p>}
                    {editEnable &&
                      <Controller
                        name="whatBestDescribeYourApartments"
                        defaultValue={inventoryData?.whatBestDescribeYourApartments}
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) =>
                          <Select className={`${errors.whatBestDescribeYourApartments ? 'is-invalid' : ''}`} style={{ width: '100%' }} defaultValue={inventoryData?.whatBestDescribeYourApartments} onChange={(value) => field.onChange(value)}>
                            <Option key={`FULLY_EQUIPPED_KITCHEN`} value={`Fully equipped kitchen`} >Fully equipped kitchen </Option>
                            <Option key={`KITCHENETTE`} value={`Kitchenette`} >Kitchenette</Option>
                            <Option key={`FRIDGE_MICRO-WAVE`} value={`Fridge & Micro-wave`} >Fridge & Micro-wave</Option>
                          </Select>
                        }
                      />}
                    {editEnable && <div className="invalid-feedback">Please select your answer!</div>}
                  </Col>
                </Row>
                <OverlayTrigger
                  placement="top"
                  delay={overlayTriggerDelay}
                  overlay={<Tooltip id='tooltip-table-top' style={toolTipStyle}>Please provide a written description of this type of inventory so that the booker has informed knowledge of what to expect. Please do not list any amenities as this is required in the amenity section</Tooltip>}
                >

                  <Row className='formSpace'>
                    <Col md={6} style={{ textAlign: 'right' }}>
                      <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Description of inventory</Form.Label>
                    </Col>
                    <Col md={6} style={{ textAlign: 'left' }}>
                      {/* {!editEnable && <p className="lead">{inventoryData?.moderate?.description?.new_value ? inventoryData?.moderate?.description?.new_value : inventoryData?.description}</p>}
                    {editEnable && <Form.Control className={`${errors.description ? 'is-invalid' : ''}`} as="textarea" rows={5} defaultValue={inventoryData?.moderate?.description?.new_value ? inventoryData?.moderate?.description?.new_value : inventoryData?.description} {...register("description", { required: true })}
                      onChange={() => {
                        setUpdatedDescription(true);
                      }}
                    />}
                    {editEnable && <div className="invalid-feedback">Please input description of inventory!</div>} */}
                      {!editEnable && <div dangerouslySetInnerHTML={{ __html: inventoryData?.moderate?.description?.new_value ? inventoryData?.moderate?.description?.new_value : inventoryData?.description }}></div>}
                      {editEnable && <Controller
                        name="description"
                        control={control}
                        defaultValue={inventoryData?.moderate?.description?.new_value ? inventoryData?.moderate?.description?.new_value : inventoryData?.description}
                        rules={{
                          required: true

                        }}
                        render={({ field }) =>
                          <CKEditor
                            editor={ClassicEditor}
                            config={{
                              toolbar: {
                                items: [
                                  'heading', '|',
                                  'undo', 'redo'
                                ]
                              },
                              heading: {
                                options: [
                                  { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                ]
                              }


                            }}
                            data={inventoryData?.moderate?.description?.new_value ? inventoryData?.moderate?.description?.new_value : inventoryData?.description}
                            onChange={(event, editor) => {
                              field.onChange(editor.getData());
                              // setUpdatedDescription(true);
                            }}
                          // disabled={!editEnable}

                          />
                        }
                      />}

                      {editEnable &&
                        <ErrorMessage
                          errors={errors}
                          name="description"
                          render={({ message }) => <div className="invalid-feedback-custom">Please input description of inventory!</div>}
                        />
                      }

                    </Col>
                  </Row>
                </OverlayTrigger>


                {(!editEnable && inventoryData?.bedType?.length === 0) &&

                  (dummyList.map((da: any, key) =>
                    <Row className='formSpace' key={key}>
                      <Col md={6} style={{ textAlign: 'right' }}>
                        <Form.Label>{!editEnable && <span className='requiredMark'>*</span>} Type of bed/configuration {`(Bedroom ${++key})`}</Form.Label>
                      </Col>
                      <Col md={6} style={{ textAlign: 'left' }}>
                        {!editEnable &&
                          <Controller
                            name={`bedType.${key}`}
                            defaultValue={da.id}
                            control={control}
                            rules={{
                              required: true,
                            }}
                            render={({ field }) =>
                              <Select value={da.id} style={{ width: '100%' }} onChange={(value) => {
                                field.onChange(value);
                                onBedTypeChange(value);
                              }} >
                                {renderBedType()}
                              </Select>
                            }
                          />}
                        {!editEnable &&
                          <ErrorMessage
                            errors={errors}
                            name={`bedType.${key}`}
                            render={({ message }) => <div className="invalid-feedback-custom">Please select  bed type!!!</div>} />}
                      </Col>
                    </Row>
                  ))}
                {roomTypeId &&

                  (dataList.map((da: any, key) =>
                    <Row className='formSpace' key={key}>
                      <Col md={6} style={{ textAlign: 'right' }}>
                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Type of bed/configuration {`(Bedroom ${++key})`}</Form.Label>
                      </Col>
                      <Col md={6} style={{ textAlign: 'left' }}>
                        {!editEnable && <p className="lead">{da.name}</p>}
                        {editEnable &&
                          <Controller
                            name={`bedType.${key}`}
                            defaultValue={da.id}
                            control={control}
                            rules={{
                              required: true,
                            }}
                            render={({ field }) =>
                              <Select value={da.id} style={{ width: '100%' }} onChange={(value) => {
                                field.onChange(value);
                                onBedTypeChange(value);
                              }} >
                                {renderBedType()}
                              </Select>
                            }
                          />}
                        {editEnable &&
                          <ErrorMessage
                            errors={errors}
                            name={`bedType.${key}`}
                            render={({ message }) => <div className="invalid-feedback-custom">Please select  bed type!!!</div>} />}
                      </Col>
                    </Row>
                  ))}


                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>}  When were individual apartments last renovated?</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && inventoryData?.whereWereIndividualApartments && <p className="lead">{moment(inventoryData?.whereWereIndividualApartments).format('YYYY')} </p>}
                    {editEnable &&
                      <Controller
                        name="whereWereIndividualApartments"
                        control={control}
                        rules={
                          { required: true }
                        }
                        defaultValue={(inventoryData?.whereWereIndividualApartments) ? new Date(inventoryData?.whereWereIndividualApartments) : undefined}
                        render={({ field }) =>
                          <DatePicker className={`form-control ${errors.whereWereIndividualApartments ? 'is-invalid' : ''}`} selected={field.value} dateFormat={'yyyy'} showYearPicker={true} onChange={(date) => field.onChange(date)} />}
                      />
                    }
                    {editEnable &&
                      <ErrorMessage
                        errors={errors}
                        name="whereWereIndividualApartments"
                        render={({ message }) => <div className="invalid-feedback-custom">Please select when were individual apartments last renovated!</div>}
                      />
                    }
                  </Col>
                </Row>

                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Are all apartments non-smoking?</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{inventoryData?.areAllApartmentsNonSmoking}</p>}
                    {editEnable &&
                      <Controller
                        name="areAllApartmentsNonSmoking"
                        defaultValue={inventoryData?.areAllApartmentsNonSmoking}
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) =>
                          <>
                            {
                              inventoryData?.areAllApartmentsNonSmoking === 'Yes'
                                ?
                                <Form.Check
                                  inline
                                  value={'Yes'}
                                  name="areAllApartmentsNonSmoking"
                                  label={'Yes'}
                                  type="radio"
                                  defaultChecked
                                  onChange={(value) => { field.onChange(value.currentTarget.value); onChangeAreAllApartmentsNonSmoking(value.currentTarget.value) }}
                                />
                                :
                                <Form.Check
                                  inline
                                  value={'Yes'}
                                  name="areAllApartmentsNonSmoking"
                                  label={'Yes'}
                                  type="radio"
                                  onChange={(value) => { field.onChange(value.currentTarget.value); onChangeAreAllApartmentsNonSmoking(value.currentTarget.value) }}
                                />
                            }

                            {
                              inventoryData?.areAllApartmentsNonSmoking === 'No'
                                ?
                                <Form.Check
                                  inline
                                  value={'No'}
                                  name="areAllApartmentsNonSmoking"
                                  label={'No'}
                                  type="radio"
                                  defaultChecked
                                  onChange={(value) => { field.onChange(value.currentTarget.value); onChangeAreAllApartmentsNonSmoking(value.currentTarget.value) }}
                                />
                                :
                                <Form.Check
                                  inline
                                  value={'No'}
                                  name="areAllApartmentsNonSmoking"
                                  label={'No'}
                                  type="radio"
                                  onChange={(value) => { field.onChange(value.currentTarget.value); onChangeAreAllApartmentsNonSmoking(value.currentTarget.value) }}
                                />
                            }

                          </>
                        }
                      />}
                    {editEnable &&
                      <ErrorMessage
                        errors={errors}
                        name="areAllApartmentsNonSmoking"
                        render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!!</div>} />}
                  </Col>
                </Row>

                {areAllApartmentsNonSmoking && <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>}  Please advise what percentage are non-smoking?</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{inventoryData?.pleaseAdviseWhatPercentageAreNonSmoking}</p>}
                    {editEnable && <Form.Control className={`${errors.pleaseAdviseWhatPercentageAreNonSmoking ? 'is-invalid' : ''}`} defaultValue={inventoryData?.pleaseAdviseWhatPercentageAreNonSmoking} {...register("pleaseAdviseWhatPercentageAreNonSmoking", { required: true })} />}
                    {editEnable && <div className="invalid-feedback">Please input your answer!</div>}
                  </Col>
                </Row>}
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>}How many apartments are suitable for people with physical disabilities?</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{inventoryData?.howManyApartmentsAreSuitable}</p>}
                    {editEnable && <Form.Control type="number" min={0} className={`${errors.howManyApartmentsAreSuitable ? 'is-invalid' : ''}`} defaultValue={inventoryData?.howManyApartmentsAreSuitable} {...register("howManyApartmentsAreSuitable", { required: true })} />}
                    {editEnable && <div className="invalid-feedback">Please input your answer!</div>}
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label> How many units do you have of this inventory type?</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{inventoryData?.noOfApartments}</p>}
                    {editEnable && <Form.Control min={0} type="number" className={`${errors.noOfApartments ? 'is-invalid' : ''}`} defaultValue={inventoryData?.noOfApartments} {...register("noOfApartments")} />}
                  </Col>
                </Row>

                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label> What is the maximum number of guests?</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{inventoryData?.noOfGuests}</p>}
                    {editEnable && <Form.Control min={0} type="number" defaultValue={inventoryData?.noOfGuests} {...register("noOfGuests")} />}
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>Number of bedrooms</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{inventoryData?.noOfBedrooms}</p>}
                    {editEnable && <Form.Control min={1} type="number" defaultValue={inventoryData?.noOfBedrooms} {...register("noOfBedrooms")} readOnly />}
                  </Col>
                </Row>


                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label> Number of bathrooms</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{inventoryData?.noOfBathrooms}</p>}
                    {editEnable && <Form.Control min={0} type="number" defaultValue={inventoryData?.noOfBathrooms} {...register("noOfBathrooms")} />}
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label> How many additional roll-out beds are you able to provide for this inventory?</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{inventoryData?.noOfRollOutBedsAvailable}</p>}
                    {editEnable &&
                      <Controller
                        name="noOfRollOutBedsAvailable"
                        defaultValue={inventoryData?.noOfRollOutBedsAvailable}
                        control={control}
                        // rules={{
                        //   required: true,
                        // }}
                        render={({ field }) =>
                          <Select onChange={(value) => {
                            field.onChange(value);
                            rollOutBedsChange(value);
                          }} className='w-100' defaultValue={inventoryData?.noOfRollOutBedsAvailable}>
                            <Option key="0" value="0">0</Option>
                            <Option key="1" value="1">1</Option>
                            <Option key="2" value="2">2</Option>
                          </Select>
                        }
                      />}
                  </Col>
                </Row>
                {noOfRollOutBedsAvailable !== 0 &&
                  <Row className='formSpace'>
                    <Col md={6} style={{ textAlign: 'right' }}>
                      <Form.Label>{extraCostPerBed + '(' + currency + ')'}</Form.Label>
                    </Col>
                    <Col md={6} style={{ textAlign: 'left' }}>
                      {!editEnable && <p className="lead">{inventoryData?.extraCostPerBed || 0}</p>}
                      {editEnable && <Form.Control min={0} type="number" defaultValue={inventoryData?.extraCostPerBed} {...register("extraCostPerBed")} />}
                    </Col>
                  </Row>
                }
                {noOfRollOutBedsAvailable !== 0 &&
                  <Row className='formSpace'>
                    <Col md={6} style={{ textAlign: 'right' }}>
                      <Form.Label> Cost type</Form.Label>
                    </Col>
                    <Col md={6} style={{ textAlign: 'left' }}>
                      {!editEnable && <p className="lead">{inventoryData?.costTypeBed}</p>}
                      {editEnable &&
                        <Controller
                          name="costTypeBed"
                          defaultValue={inventoryData?.costTypeBed}
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field }) =>
                            <>
                              {
                                inventoryData?.costTypeBed === 'Fixed Cost'
                                  ?
                                  <Form.Check
                                    inline
                                    value={'Fixed Cost'}
                                    name="costTypeBed"
                                    label={'Fixed Cost'}
                                    type="radio"
                                    defaultChecked
                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                  />
                                  :
                                  <Form.Check
                                    inline
                                    value={'Fixed Cost'}
                                    name="costTypeBed"
                                    label={'Fixed Cost'}
                                    type="radio"
                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                  />
                              }

                              {
                                inventoryData?.costTypeBed === 'Nightly Cost'
                                  ?
                                  <Form.Check
                                    inline
                                    value={'Nightly Cost'}
                                    name="costTypeBed"
                                    label={'Nightly Cost'}
                                    type="radio"
                                    defaultChecked
                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                  />
                                  :
                                  <Form.Check
                                    inline
                                    value={'Nightly Cost'}
                                    name="costTypeBed"
                                    label={'Nightly Cost'}
                                    type="radio"
                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                  />
                              }

                            </>
                          }
                        />}

                    </Col>
                  </Row>
                }
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label> How many cots are you able to provide for this inventory?</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{inventoryData?.noOfCotsAvailable}</p>}
                    {editEnable &&
                      <Controller
                        name="noOfCotsAvailable"
                        defaultValue={inventoryData?.noOfCotsAvailable}
                        control={control}
                        // rules={{
                        //   required: true,
                        // }}
                        render={({ field }) =>
                          <Select className='w-100' onChange={(value) => {
                            field.onChange(value);
                            cotsAvailableChange(value);
                          }} defaultValue={inventoryData?.noOfCotsAvailable}>
                            <Option key="0" value="0">0</Option>
                            <Option key="1" value="1">1</Option>
                            <Option key="2" value="2">2</Option>
                          </Select>
                        }
                      />}
                  </Col>
                </Row>
                {noOfCotsAvailable !== 0 &&
                  <Row className='formSpace'>
                    <Col md={6} style={{ textAlign: 'right' }}>
                      <Form.Label>{extraCostOfCot + '(' + currency + ')'}</Form.Label>
                    </Col>
                    <Col md={6} style={{ textAlign: 'left' }}>
                      {!editEnable && <p className="lead">{inventoryData?.extraCostOfCot || 0}</p>}
                      {editEnable && <Form.Control min={0} type="number" defaultValue={inventoryData?.extraCostOfCot} {...register("extraCostOfCot")} />}
                    </Col>
                  </Row>
                }

                {noOfCotsAvailable !== 0 &&
                  <Row className='formSpace'>
                    <Col md={6} style={{ textAlign: 'right' }}>
                      <Form.Label> Cost type</Form.Label>
                    </Col>
                    <Col md={6} style={{ textAlign: 'left' }}>
                      {!editEnable && <p className="lead">{inventoryData?.costTypeCot}</p>}
                      {editEnable &&
                        <Controller
                          name="costTypeCot"
                          defaultValue={inventoryData?.costTypeCot}
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field }) =>
                            <>
                              {
                                inventoryData?.costTypeCot === 'Fixed Cost'
                                  ?
                                  <Form.Check
                                    inline
                                    value={'Fixed Cost'}
                                    name="costTypeCot"
                                    label={'Fixed Cost'}
                                    type="radio"
                                    defaultChecked
                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                  />
                                  :
                                  <Form.Check
                                    inline
                                    value={'Fixed Cost'}
                                    name="costTypeCot"
                                    label={'Fixed Cost'}
                                    type="radio"
                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                  />
                              }

                              {
                                inventoryData?.costTypeCot === 'Nightly Cost'
                                  ?
                                  <Form.Check
                                    inline
                                    value={'Nightly Cost'}
                                    name="costTypeCot"
                                    label={'Nightly Cost'}
                                    type="radio"
                                    defaultChecked
                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                  />
                                  :
                                  <Form.Check
                                    inline
                                    value={'Nightly Cost'}
                                    name="costTypeCot"
                                    label={'Nightly Cost'}
                                    type="radio"
                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                  />
                              }

                            </>
                          }
                        />}

                    </Col>
                  </Row>
                }
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label> Minimum stay</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{inventoryData?.minimumStayed}</p>}
                    {editEnable && <Form.Control min={0} type="number" defaultValue={inventoryData?.minimumStayed} {...register("minimumStayed")} />}
                  </Col>
                </Row>

                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Do you have a maximum length of stay?</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{inventoryData?.doYouHaveMaximumLengthOfStay}</p>}
                    {editEnable &&
                      <Controller
                        name="doYouHaveMaximumLengthOfStay"
                        defaultValue={inventoryData?.doYouHaveMaximumLengthOfStay}
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) =>
                          <>
                            {
                              inventoryData?.doYouHaveMaximumLengthOfStay === 'Yes'
                                ?
                                <Form.Check
                                  inline
                                  value={'Yes'}
                                  name="doYouHaveMaximumLengthOfStay"
                                  label={'Yes'}
                                  type="radio"
                                  defaultChecked
                                  onChange={(value) => { field.onChange(value.currentTarget.value); onChangeDoYouHaveMaximumLengthOfStay(value.currentTarget.value) }}
                                />
                                :
                                <Form.Check
                                  inline
                                  value={'Yes'}
                                  name="doYouHaveMaximumLengthOfStay"
                                  label={'Yes'}
                                  type="radio"
                                  onChange={(value) => { field.onChange(value.currentTarget.value); onChangeDoYouHaveMaximumLengthOfStay(value.currentTarget.value) }}
                                />
                            }

                            {
                              inventoryData?.doYouHaveMaximumLengthOfStay === 'No'
                                ?
                                <Form.Check
                                  inline
                                  value={'No'}
                                  name="doYouHaveMaximumLengthOfStay"
                                  label={'No'}
                                  type="radio"
                                  defaultChecked
                                  onChange={(value) => { field.onChange(value.currentTarget.value); onChangeDoYouHaveMaximumLengthOfStay(value.currentTarget.value) }}
                                />
                                :
                                <Form.Check
                                  inline
                                  value={'No'}
                                  name="doYouHaveMaximumLengthOfStay"
                                  label={'No'}
                                  type="radio"
                                  onChange={(value) => { field.onChange(value.currentTarget.value); onChangeDoYouHaveMaximumLengthOfStay(value.currentTarget.value) }}
                                />
                            }

                          </>
                        }
                      />}
                    {editEnable &&
                      <ErrorMessage
                        errors={errors}
                        name="doYouHaveMaximumLengthOfStay"
                        render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!!</div>} />}
                  </Col>
                </Row>



                {doYouHaveMaximumLengthOfStay && <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>}  Maximum stay</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{inventoryData?.maximumStayed}</p>}
                    {editEnable && <Form.Control min={0} type="number" className={`${errors.maximumStayed ? 'is-invalid' : ''}`} defaultValue={inventoryData?.maximumStayed} {...register("maximumStayed", { required: true })} />}
                    {editEnable && <div className="invalid-feedback">Please input your answer!</div>}
                  </Col>
                </Row>}
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!loading && !editEnable && inventoryId && getLoggedUserType() === UserGroups.super_admin && (selectedBrand?.brandStatus === 'accepted') && (inventoryData?.status === InventoryStatus.PENDING || inventoryData?.status === InventoryStatus.REVIEW) &&
                      <Button id='approve-button' style={formButton} onClick={() => { onApprovedInventory() }}>Approve</Button>
                    }
                    {!loading && !editEnable && inventoryId && getLoggedUserType() === UserGroups.super_admin && inventoryData?.status === InventoryStatus.PENDING && (selectedBrand?.brandStatus === 'accepted') &&
                      <Button id='approve-button' style={formButton2} onClick={() => { onReviewedInventory() }}>Review</Button>
                    }
                    {!loading && !inventoryId &&
                      <Button type="submit" id='approve-button' hidden={showCreateButton} style={formButton}>Create</Button>
                    }
                    {!loading && editEnable && inventoryId &&
                      <Button type="submit" id='approve-button' style={formButton}>Update</Button>
                    }
                    {!loading && !editEnable && inventoryId && inventoryData?.bedType?.length === 0 &&
                      <Button type="submit" id='approve-button' style={formButton}>Update</Button>
                    }

                    {
                      loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                    }
                  </Col>
                </Row>
              </Col>
              <Col md={1}></Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  )
};

export default InventoryDetailsForm;