import { Select, Switch, Tooltip as ToolAntd } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useState } from 'react';
import ImageUpload from '../../../common/imageUpload';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { canBrandHotelModuleWrite } from '../../../../common/functions';
import { UserAccess } from '../../../../common/constants';
import { Controller, useForm } from 'react-hook-form';
import { HotelImageFormInput } from '../../../../common/interfaces';
import { Col, Container, Form, Row, Button, Spinner, Image, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formButton, overlayTriggerDelay, Settings, slideImageStyle } from '../../../../common/components-style';
import { toast } from 'react-toastify';
import Slider from "react-slick";
import Gallery from 'react-grid-gallery';
import BreadCrumbPage from '../../../common/BreadCrumbPage';
import Fancybox from '../../../common/fancyBox';
import searchIcon from "../../../../images/icons/svg/outside/search.svg";

const { Option } = Select;

const HotelImages: React.FC<any> = ({ allInOneHotelBreadCrumb, hotelId, setFormOpen, brandId, selectedBrand }): JSX.Element => {
	///////////////////// initialize form ////////////////////////////////////// 
	const { register, setValue, reset, handleSubmit, formState: { errors }, control } = useForm<HotelImageFormInput>();

	///////////////////////// local state manage ////////////////////////////////////
	const [editEnable, setEditEnable] = useState<boolean>(true);
	const [updateEnable, setUpdateEnable] = useState<boolean>(false);
	const [hotelData, setHotelData] = useState<any>();
	const [imageData, setImageData] = useState<any>();
	const [loading, setLoading] = useState<boolean>(false);
	const [imageList, setImageList] = useState<any>([]);
	const [image, setImage] = useState<any>();
	const [imageUpdated, setImageUpdated] = useState(false);
	const [tagsList, setTagList] = useState<Array<Object>>([]);
	const [tags, setTags] = useState<Array<Object>>([]);
	const [tagsSelect, setTagsSelect] = useState<any>();
	const [imageId, setImageId] = useState<string>();
	const [status, setStatus] = useState<string>('active');
	const [checkValue, setCheckValue] = useState<boolean>(true);
	const [show, setShow] = useState(false);
	const [imageVisible, setImageVisible] = useState(true);
	const [imagesGallery, setImagesGallery] = useState<Array<any>>([]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const { uploadHotelImage, getHotelImage, resetHotelImage, getHotelImageTag, getHotel, tab } = useStoreActions<any>((actions) => ({
		uploadHotelImage: actions.hotel.uploadHotelImage,
		getHotelImage: actions.hotel.getHotelImage,
		resetHotelImage: actions.hotel.resetHotelImage,
		getHotelImageTag: actions.hotel.getHotelImageTag,
		getHotel: actions.hotel.getHotel
	}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const { uploadHotelImageSuccess, uploadHotelImageError, getHotelImageSuccess, getHotelImageError, updateHotelImageSuccess, removeHotelImageSuccess, getHotelImageTagSuccess, getHotelImageTagError, getHotelSuccess } = useStoreState<any>((state) => ({
		uploadHotelImageSuccess: state.hotel.uploadHotelImageSuccess,
		uploadHotelImageError: state.hotel.uploadHotelImageError,
		getHotelImageSuccess: state.hotel.getHotelImageSuccess,
		getHotelImageError: state.hotel.getHotelImageError,
		updateHotelImageSuccess: state.hotel.updateHotelImageSuccess,
		removeHotelImageSuccess: state.hotel.removeHotelImageSuccess,
		getHotelImageTagSuccess: state.hotel.getHotelImageTagSuccess,
		getHotelImageTagError: state.hotel.getHotelImageTagError,
		getHotelSuccess: state.hotel.getHotelSuccess
	}));

	////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (hotelId) {
			getHotelImage(hotelId);
			getHotel(hotelId);
		}

	}, [getHotel, getHotelImage, hotelId]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getHotelImageSuccess) {
			if (getHotelImageSuccess.data.length !== 0) {
				setImageData(getHotelImageSuccess.data[0].image);
				const hotels = (getHotelImageSuccess.data[0]?.moderateImage && getHotelImageSuccess.data[0]?.moderateImage?.length !== 0) ? getHotelImageSuccess.data[0]?.moderateImage : getHotelImageSuccess.data[0].image;
				const imageListTemp: Array<Object> = [];
				const imageGallery: Array<Object> = [];
				_.map(hotels, (element: any) => {
					imageListTemp.push(element);
					imageGallery.push(
						{
							id: element._id,
							src: element.url,
							thumbnail: element.url,
							thumbnailWidth: 300,
							thumbnailHeight: 200,
							caption: element.caption
						}
					);
				}
				);
				setImageList(imageListTemp);
				setImagesGallery(imageGallery);
			}
		}
		if (getHotelImageError) {
			toast.error(getHotelImageError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}

		if (getHotelSuccess) {
			setHotelData(getHotelSuccess.data);
		}

	}, [getHotelImage, getHotelImageError, getHotelImageSuccess, getHotelSuccess, hotelId]);

	////////////////////////////////////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		if (brandId) {
			getHotelImageTag(brandId);
		}

	}, [brandId, getHotelImageTag]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getHotelImageTagSuccess) {
			const tagList = getHotelImageTagSuccess.data;
			if (tagList) {
				setTags(tagList.tags);
			}
		}

		if (getHotelImageTagError) {
			toast.error(getHotelImageTagError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}
	}, [getHotelImageTagError, getHotelImageTagSuccess, tags]);

	////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (uploadHotelImageSuccess) {
			setLoading(false);
			setImageVisible(false);
			toast.success('Hotel Image uploaded successfully', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetHotelImage();
			getHotelImage(uploadHotelImageSuccess.data.hotelId);
			reset();
			setTagsSelect([]);
			setUpdateEnable(false);
			setImageId('');
			setImageUpdated(false);
		}

		if (updateHotelImageSuccess) {
			setLoading(false);
			setImageVisible(false);
			toast.success('Hotel Image updated successfully', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			reset();
			setTagsSelect([]);
			setUpdateEnable(false);
			resetHotelImage();
			getHotelImage(updateHotelImageSuccess.data.hotelId);
			setImageUpdated(false);
		}

		if (removeHotelImageSuccess) {
			setLoading(false);
			setImageVisible(false);
			resetHotelImage();
			getHotelImage(removeHotelImageSuccess.data.hotelId);
			reset();
			setTagsSelect([]);
			setUpdateEnable(false);
			setImageId('');
			setImageUpdated(false);
		}
		if (uploadHotelImageError) {
			setLoading(false);
			toast.error(uploadHotelImageError, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}
	}, [uploadHotelImageSuccess, uploadHotelImageError, resetHotelImage, removeHotelImageSuccess, updateHotelImageSuccess, getHotelImage, reset, tagsSelect, image, setValue]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const getImageBlob = useCallback((imageBlob) => {
		setImage(imageBlob);
		setValue('image', imageBlob);
		setImageUpdated(true);
		setImageVisible(true);
	}, [setValue]);

	//////////////////////////////////////////////////////////////////////////////////////////////
	const tagChange = useCallback((value) => {
		setTagList(value);
		setTagsSelect(value);
	}, []);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const statusChange = useCallback((value) => {
		setCheckValue(value);
		if (value) {
			setStatus('active');
		}
		else {
			setStatus('inactive');
		}
	}, []);

	//////////////////////////////////////////////////////////////////////////////////////////////////
	const imageClick = useCallback((value) => {
		reset();
		setTagsSelect([]);
		setUpdateEnable(true);
		setCheckValue((value.status === 'active') ? true : false);
		setImageId(value._id);
		setStatus(value.status);
		setValue('caption', value.caption);
		setValue('description', value.description);
		setValue('image', value.url);
		setValue('tags', value.tags);
		setTagsSelect(value.tags)
		setValue('status', checkValue);
		setImageVisible(true);
	}, [checkValue, reset, setValue]);

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	const removeImage = useCallback((value) => {
		const data = {
			hotelId: hotelId,
			imageId: value._id,
			isDeleted: true,
			brandId: brandId,
		}
		setLoading(true);
		uploadHotelImage(data);
	}, [brandId, hotelId, uploadHotelImage]);

	////////////////////////////////////////////////////////////////////////////////////////////////////

	const renderTag = useCallback(() => {
		if (tags) {
			return tags.map((tg: any, key: number) => {
				return (
					<Option key={key} value={`${tg}`} >{tg}</Option>
				)
			});
		}
	}, [tags]);

	const changeFormEditable = useCallback(() => {
		if (editEnable) {
			setEditEnable(false);
		} else {
			setEditEnable(true);
		}
	}, [editEnable]);

	/////////////////////////////////////////////////////////////////////////////////////////////////////

	const onFinish = useCallback((values) => {
		setLoading(true);
		if (imageId) {
			values.imageId = imageId;
			const data = {
				hotelId: hotelId,
				brandId: brandId,
				imageId: imageId,
				image: imageUpdated ? image : values.image,
				tags: values.tags,
				caption: values.caption,
				description: values.description,
				isDeleted: false,
				status: values.status ? status : 'inactive'
			}
			uploadHotelImage(data)

		} else {
			values.hotelId = hotelId;
			values.imageId = '';
			values.image = image;
			values.tags = tagsList;
			values.status = status;
			values.brandId = brandId;
			uploadHotelImage(values)
		}

	}, [brandId, image, imageId, imageUpdated, hotelId, status, tagsList, uploadHotelImage]);

	const handleCloseHide = (value) => setShow(value);

	return (

		<>
			<div className="content-title fixed_title">
				{
					<BreadCrumbPage tab={tab} allInOneHotelBreadCrumb={allInOneHotelBreadCrumb} isPageName="Hotel" selectedBrand={selectedBrand} hotelData={hotelData} setFormOpen={setFormOpen} ></BreadCrumbPage>
				}
			</div>
			{
				canBrandHotelModuleWrite(brandId, hotelId, UserAccess.hotel_access.details.code) &&
				<div>
					<div className="jumbotron">
						<div style={{ fontSize: 22, marginBottom: '3%' }}>
							<span className='requiredMark'>Please upload at least one hotel image. The first image must be of the frontal exterior of the building</span>
						</div>
						<Form onSubmit={handleSubmit(onFinish)}>
							<Row>
								<Col md={1}></Col>
								<Col md={7}>
									<Row className='formSpace'>
										<Col md={6} style={{ textAlign: 'right' }}>
											<OverlayTrigger
												placement="top"
												delay={overlayTriggerDelay}
												overlay={<Tooltip id='tooltip-table-top'>A short factual description that will appear below the image e.g. external building image or communal reception area, max 3 words.</Tooltip>}
											>
												<Form.Label>{editEnable && <span className='requiredMark'>*</span>}Caption</Form.Label>
											</OverlayTrigger>

										</Col>
										<Col md={6} style={{ textAlign: 'left' }}>
											{!editEnable && <p className="lead">{imageData?.caption}</p>}
											{editEnable &&
												<OverlayTrigger
													placement="top"
													delay={overlayTriggerDelay}
													overlay={<Tooltip id='tooltip-table-top'>A short factual description that will appear below the image e.g. external building image or communal reception area, max 3 words.</Tooltip>}
												>
													<Form.Control className={`${errors.caption ? 'is-invalid' : ''}`} {...register("caption", { required: true })} />
												</OverlayTrigger>

											}
											{editEnable && <div className="invalid-feedback">Please input caption!</div>}
										</Col>
									</Row>
									<Row className='formSpace'>
										<Col md={6} style={{ textAlign: 'right' }}>
											<OverlayTrigger
												placement="top"
												delay={overlayTriggerDelay}
												overlay={<Tooltip id='tooltip-table-top'>Short overview of the subject/focus of the image</Tooltip>}
											>
												<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Description</Form.Label>
											</OverlayTrigger>

										</Col>
										<Col md={6} style={{ textAlign: 'left' }}>
											{!editEnable && <p className="lead">{imageData?.description}</p>}
											{editEnable &&
												<OverlayTrigger
													placement="top"
													delay={overlayTriggerDelay}
													overlay={<Tooltip id='tooltip-table-top'>Short overview of the subject/focus of the image</Tooltip>}
												>
													<Form.Control as="textarea" rows={5} className={`${errors.description ? 'is-invalid' : ''}`} {...register("description", { required: true })} />
												</OverlayTrigger>

											}
											{editEnable && <div className="invalid-feedback">Please input description!</div>}
										</Col>
									</Row>
									<Row className='formSpace'>
										<Col md={6} style={{ textAlign: 'right' }}>
											<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Image</Form.Label>
										</Col>
										<Col md={6} style={{ textAlign: 'left' }}>
											{!editEnable && <Image width={100} src={image} thumbnail={true} />}
											{editEnable &&
												<Controller
													name="image"
													control={control}
													rules={{
														required: image ? false : true
													}}
													render={({ field }) =>
														<ImageUpload crop={false} getImageBlob={getImageBlob} type="image" value={field.value} disabled={false} visible={imageVisible} />
													}
												/>}

											{editEnable && errors.image && <div className="invalid-feedback-custom">Please select image!</div>}
										</Col>
									</Row>
									<Row className='formSpace'>
										<Col md={6} style={{ textAlign: 'right' }}>
											<OverlayTrigger
												placement="top"
												delay={overlayTriggerDelay}
												overlay={<Tooltip id='tooltip-table-top'>Tags are used to aid search. Select an existing tag or create your own to help categorise the image</Tooltip>}
											>
												<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Tags</Form.Label>
											</OverlayTrigger>

										</Col>
										<Col md={6} style={{ textAlign: 'left' }}>
											{!editEnable && <p className="lead">{imageData?.tags}</p>}
											{editEnable &&
												<Controller
													name="tags"
													defaultValue={tagsSelect}
													control={control}
													rules={{
														required: true,
													}}
													render={({ field }) =>
														<ToolAntd color={`#000000`} placement="top" title={`Tags are used to aid search. Select an existing tag or create your own to help categorise the image`}>
															{
																tagsSelect ? <Select key={tagsSelect ? `tags${String(tagsSelect)}` : 'empty'} mode="tags" style={{ width: '100%' }} value={tagsSelect} defaultValue={tagsSelect} onChange={(value) => {
																	tagChange(value);
																	field.onChange(value);
																}}>
																	{renderTag()}
																</Select> : <Select mode="tags" style={{ width: '100%' }} onChange={(value) => {
																	tagChange(value);
																	field.onChange(value);
																}}>
																	{renderTag()}
																</Select>
															}
														</ToolAntd>
													}
												/>}
											{editEnable && errors.tags && <div className="invalid-feedback-custom">Please input tags!</div>}
										</Col>
									</Row>
									<Row className='formSpace'>
										<Col md={6} style={{ textAlign: 'right' }}>
											<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Status</Form.Label>
										</Col>
										<Col md={6} style={{ textAlign: 'left' }}>
											{!editEnable && <p className="lead">{imageData?.status}</p>}
											{editEnable &&
												<Controller
													name="status"
													defaultValue={imageData?.status}
													control={control}
													render={({ field }) =>
														<Switch size="small" defaultChecked={checkValue} checked={checkValue} onChange={(value) => {
															statusChange(value);
															field.onChange(value);
														}} />
													}
												/>}
										</Col>
									</Row>
									<Row className='formSpace'>
										<Col md={6} style={{ textAlign: 'right' }}>
										</Col>
										<Col md={6} style={{ textAlign: 'left' }}>
											{!loading && !updateEnable && editEnable && hotelId && canBrandHotelModuleWrite(brandId, hotelId, UserAccess.hotel_access.details.code) &&
												<Button type="submit" id='approve-button' style={formButton}>Create</Button>
											}
											{!loading && updateEnable && editEnable && hotelId && canBrandHotelModuleWrite(brandId, hotelId, UserAccess.hotel_access.details.code) &&
												<Button type="submit" id='approve-button' style={formButton}>Update</Button>
											}
											{
												!loading && !editEnable && hotelId && canBrandHotelModuleWrite(brandId, hotelId, UserAccess.hotel_access.details.code) && <Button id='edit-button' style={formButton} onClick={changeFormEditable} >Edit</Button>
											}
											{
												loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
											}
										</Col>
									</Row>
								</Col>
								<Col md={4}></Col>
							</Row>
						</Form>
					</div>
				</div>
			}

			{canBrandHotelModuleWrite(brandId, hotelId, UserAccess.hotel_access.details.code) && (imageList.length > 0) &&
				<>
					<div className="jumbotron Fancy_wrapper hotel_fancy_slider">
						<Row>
							<Slider {...Settings}>
								{imageList.map((img: any, index) => (
									<>
										<div key={index} className='relative text-center text-white'>
											<Image width={300} src={img.url} alt="avatar" thumbnail={true} style={slideImageStyle}>
											</Image>
											<div className='inset' >
												<EditOutlined className='p-1' style={{ color: '#fff', fontSize: '40px' }} onClick={() => imageClick(img)} />
												<DeleteOutlined style={{ color: '#fff', fontSize: '40px' }} onClick={() => removeImage(img)} />
											</div>
										</div>
									</>
								))}
							</Slider>
						</Row>
						<div className='mt-5'>
							<Fancybox>
								<p className='text-center'>
									{
										<a style={formButton}
											className="btn btn-primary slider_approve_btn"
											data-fancybox="gallery"
											href={imageList[0]?.url}
										>
											Preview
										</a>
									}
									{imageList?.map((curElemImg, index) => (
										<>
											{index !== 0 && (
												<a
													key={index}
													data-fancybox="gallery"
													href={curElemImg.url}
													style={{ display: "none" }}
												>
													<img alt="Inventary Images" src={curElemImg.url} />
												</a>
											)}
										</>
									))}
								</p>
							</Fancybox>
						</div>
					</div>
					<Modal size='xl' show={show} onHide={() => { handleCloseHide(false) }}>
						<Modal.Header closeButton>
							<Modal.Title>Preview</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Gallery images={imagesGallery} />
						</Modal.Body>
					</Modal>
				</>
			}
			{
				!canBrandHotelModuleWrite(brandId, hotelId, UserAccess.hotel_access.details.code) && <>
					<Container>
						<Gallery margin={20} images={imagesGallery} />
					</Container>
				</>
			}
		</>

	)
};

export default HotelImages;
