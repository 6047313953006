import React, { useCallback, useEffect, useState } from 'react';
import { Typography, Card } from 'antd';
import Slider from "react-slick";
import { Col, Row, Image, Form, Modal, Button } from 'react-bootstrap';
// import bed from '../../../../../../images/icons/svg/outside/bed.svg';
import { searchButton5, Settings } from '../../../../../../common/components-style';
import moment from 'moment';
import ClientPropertyGoogleMap from '../../../../../common/ClientPropertyGoogleMap';
import GooglePlaces from '../../../../../common/GooglePlaces';
import ShowCommuteMap from '../../../../../common/ShowCommuteMap';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { getStorage } from '../../../../../../common/storage';
import _ from 'lodash';


const { Text } = Typography;

const imageStyle = {
    textAlign: 'center' as const,
    height: '200px',
    background: '#F2F2F2',
    marginRight: '10px',
    borderRadius: '30px',
}

/////////////////////////////// details block style////////////////////////////////////////
const headStyle = { textAlign: 'center' as const, fontSize: '18px' };
const headStyleFirstCol = { fontSize: '18px', paddingLeft: '28px' };
const dataStyle = { textAlign: 'center' as const };
const infoCardStyle = { borderRadius: '20px' };

const ClientSinglePropertyView: React.FC<any> = ({ property, amenitiesList, selectCityName }): JSX.Element => {
    /////////// local state manage /////////
    const [location, setLocation] = useState<any>({ lat: Number(property?.latitude), lng: Number(property?.longitude) });
    const [markers, setMarkers] = useState<Array<any>>([]);
    const [amenities, setAmenities] = useState<Array<any>>([]);
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [commuteBuilding, setCommuteBuilding] = useState<any>();
    const [building, setBuilding] = useState<any>();
    const [showCommuteMap, setShowCommuteMap] = useState<boolean>(false);
    const [locationsShowCommute, setLocationsShowCommute] = useState<any>();
    const [travelMode, setTravelMode] = useState<any>("");

    const [distance, setDistance] = useState<any>("0.0 km");
    const [duration, setDuration] = useState<any>("00 mins");

    const [otherLocation, setOtherLocation] = useState<any>({});
    const {

        getClientLocationByProgrammeId
    } = useStoreActions<any>((actions) => ({

        getClientLocationByProgrammeId: actions.client.getClientLocationByProgrammeId,
    }));


    const {

        getClientLocationByProgrammeIdSuccess
    } = useStoreState<any>((state) => ({

        getClientLocationByProgrammeIdSuccess: state.client.getClientLocationByProgrammeIdSuccess,
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        const programmeId = getStorage('selectedProgramsOption')?.value ? getStorage('selectedProgramsOption')?.value : getStorage('selectedRFPProcess')?.programmeId
        if (programmeId) {
            getClientLocationByProgrammeId(programmeId);
        }

    }, [getClientLocationByProgrammeId, location]);




    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getClientLocationByProgrammeIdSuccess) {
            const locationData = getClientLocationByProgrammeIdSuccess?.data;
            const markrs: any = [];

            const data = _.filter(getClientLocationByProgrammeIdSuccess?.data, list => ((list.city === selectCityName)));
            locationData?.forEach(element => {
                const loc = {
                    id: element?._id,
                    name: `${element?.locationName}`,
                    position: { lat: Number(element?.latitude), lng: Number(element?.longitude) },
                    element: element,
                    city: element?.city
                };
                markrs.push(loc);
            });
            // if (location?.name) {
            //     markrs.push(location);
            // }
            setMarkers(markrs);
            setOtherLocation(data[0]);

        }


    }, [getClientLocationByProgrammeIdSuccess, location, selectCityName])

    useEffect(() => {

        if (otherLocation) {
            setShowCommuteMap(true);
            setLocationsShowCommute({ lat: Number(otherLocation?.latitude), lng: Number(otherLocation?.longitude), locationName: otherLocation?.address1 });
        }


    }, [getClientLocationByProgrammeIdSuccess, otherLocation?.address1, otherLocation])

    useEffect(() => {
        if (property) {
            setLocation({ lat: Number(property?.latitude), lng: Number(property?.longitude) });
            setBuilding({ property: property });
            setCommuteBuilding({ property });
        }
        const amenitiesIds: any = [];
        property?.amenities?.amenity.forEach(element => {
            amenitiesIds.push(element);
        });
        property?.guest_comfort?.amenity.forEach(element => {
            amenitiesIds.push(element);
        });
        setAmenities(amenitiesIds);
    }, [property]);


    // const studio = () => {
    //     let counting = 0;
    //     property?.inventories?.forEach(value => {
    //         if ('Studio/Open plan apartment' === value?.room_type?.name.trim()) {
    //             counting += 1;
    //         }
    //     });

    //     return counting;
    // };

    // const bedRoomOne = () => {
    //     property?.inventories?.forEach(value => {
    //         if ('1 bedroom' === value?.room_type?.name.trim()) {
    //             counting += 1;
    //         }
    //     });

    //     return counting;
    // };

    // const bedRoomTwo = () => {
    //     let counting = 0;
    //     property?.inventories?.forEach(value => {
    //         if ('2 bedroom' === value?.room_type?.name.trim()) {
    //             counting += 1;
    //         }
    //     });

    //     return counting;
    // };

    // const bedRoomThreePlus = () => {
    //     let counting = 0;
    //     property?.inventories?.forEach(value => {
    //         if ('3 bedroom' === value?.room_type?.name) {
    //             counting += 1;
    //         }
    //         if ('More than 3 bedrooms' === value?.room_type?.name.trim()) {
    //             counting += 1;
    //         }
    //     });

    //     return counting;
    // };  //     let counting = 0;


    const minNight = () => {
        let minimumStayed = 0;
        let firstLoading = false;
        property?.inventories?.forEach(value => {
            if (value?.minimumStayed) {
                if (!firstLoading) {
                    minimumStayed = value?.minimumStayed;
                    firstLoading = true;
                } else {
                    if (minimumStayed > value?.minimumStayed) {
                        minimumStayed = value?.minimumStayed;
                    }
                }
            }
        });

        return minimumStayed;
    }

    const getPropertyType = () => {
        let type = '';
        if (property?.property_type?.name.trim() === 'Apartments - building fully managed by our brand') {
            type = 'Apartments';

        } else if (property?.property_type?.name.trim() === 'Apartments - building managed by a third party') {
            type = 'Apartments';

        } else if (property?.property_type?.name.trim() === 'Aparthotel') {
            type = property?.property_type?.name;

        } else {
            type = 'Self contained';
        }

        return type;
    }

    const managingProcess = () => {
        let type = '';
        if (property?.property_type?.name.trim() === 'Apartments - building managed by a third party') {
            type = 'No';

        } else {
            type = 'Yes';
        }

        return type;
    }

    const inventoryImages = () => {
        let images: Array<any> = [];
        property?.inventories.forEach(inventory => {
            const temImage = inventory?.images?.[0]?.image?.map((value) => {
                return (
                    <div style={imageStyle}>
                        <Image width={310} src={value.url} alt={value.caption} style={{ borderRadius: '30px', height: 200 }}>
                        </Image>
                    </div>
                )
            });
            images = [...images, ...temImage]
        });
        return images;
    }

    const calculateAccoum = (inventories: Array<any>) => {
        let accoum = 0;
        inventories?.forEach((value) => {
            accoum = accoum + value?.noOfApartments;
        });
        return accoum;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const onShowCommute = useCallback((value) => {
        setShowMessage(true);
        setLocationsShowCommute({ lat: Number(value.latitude), lng: Number(value.longitude), locationName: value.address1 });
        setTravelMode("WALKING");

    }, []);



    const setLocationDataShowCommute = useCallback((location) => {
        setLocationsShowCommute({ lat: Number(location.lat), lng: Number(location.lng), locationName: location.address1 });
        setShowCommuteMap(true);
    }, []);
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const onChangeTravelMode = useCallback((value) => {
        if (value) {
            setTravelMode(value);
        }
    }, []);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const onResponse = useCallback((event) => {
        setDuration(event.routes[0].legs[0].duration.text);
        setDistance(event.routes[0].legs[0].distance.text);
    }, []);

    return (
        <>
            <div >
                <Row>
                    <Col md={12}>
                        <Slider {...Settings}>
                            {
                                property?.images?.[0]?.image?.map((value) => {
                                    return (
                                        <div style={imageStyle}>
                                            <Image width={325} src={value.url} alt={value.caption} style={{ borderRadius: '30px', height: 200 }}>
                                            </Image>
                                        </div>
                                    )
                                })
                            }
                            {inventoryImages()}
                        </Slider>
                    </Col>
                </Row>

                <Row className='mt-4 tble_rw'>

                    <Col md={2} style={headStyleFirstCol}>
                        <Text strong> Property type</Text>
                    </Col>
                    <Col md={2} style={headStyle}>
                        <Text strong> Accommodations</Text>
                    </Col>
                    <Col md={3} style={headStyle}>
                        <Text strong> Property 100% Managed</Text>
                    </Col>
                    <Col md={2} style={headStyle}>
                        <Text strong> Minimum Stay</Text>
                    </Col>
                    <Col md={3} style={headStyle}>
                        <Text strong> Check-in process</Text>
                    </Col>
                </Row>
                <Row className='mt-3' style={{ background: '#F2F2F2', height: '63px', padding: '15px' }}>
                    <Col md={2}>
                        <div >
                            <Text strong style={{ fontSize: '18px' }}> {getPropertyType()}</Text>
                        </div>
                    </Col>
                    <Col md={2} style={dataStyle}>
                        <div style={{ marginRight: '7%' }} >
                            <Text > {calculateAccoum(property?.inventories)}</Text>
                        </div>
                    </Col>
                    <Col md={3} style={dataStyle}>
                        <div  >
                            <Text > {managingProcess()}</Text>
                        </div>
                    </Col>
                    <Col md={2} style={dataStyle}>
                        <div >
                            <Text > {minNight()} Nights</Text>
                        </div>
                    </Col>
                    <Col md={3} style={dataStyle}>
                        <div  >
                            <Text > {property?.check_in?.check_in_process?.name}</Text>
                        </div>
                    </Col>
                </Row>

                {/* Map */}
                <Row className='mt-5'>
                    <Col md={6}>
                        <ClientPropertyGoogleMap
                            googleMapURL={process.env.REACT_APP_MAP_URL}
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `480px` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                            // centerData={location}
                            building={building}
                            // onShowCommute={onShowCommute}
                            onShowCommute={onShowCommute}
                            location={markers}
                            centerData={location}
                        />
                        <Button style={searchButton5} id='map-button' >
                            <b>Click on company location for commute</b>
                        </Button>


                    </Col>
                    <Col md={6}>
                        <Card className='bx_card' bordered={false} style={infoCardStyle}>
                            <h3 className='fz-24 mb-3'> Property Description</h3>
                            <Text strong><div dangerouslySetInnerHTML={{ __html: property?.description }} /></Text>
                        </Card>
                    </Col>
                </Row>

                {/* New Two Column */}
                <Row className='mt-5'>
                    <Col md={6}>
                        <div className='grey_bx'>
                            <Row>
                                <Col md={7}>
                                    <p>Year property built</p>
                                </Col>
                                <Col md={5}>
                                    <p className='fw-rg'>{moment(property?.yearPropertyWasBuilt).format('YYYY')}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={7}>
                                    <p>Date of last communal area renovation</p>
                                </Col>
                                <Col md={5}>
                                    <p className='fw-rg'>{moment(property?.whenWereCommunalAreasLastRenovated).format('YYYY')}</p>
                                </Col>
                            </Row>
                            {/* <Row className='mt-4'>
                            <Col md={7}>
                                <p>Does the property comply with all local and notional disability law?</p>
                            </Col>
                            <Col md={5}>
                                <Form.Check
                                    inline
                                    label="Yes"
                                    type="checkbox"
                                    checked={property?.guest_comfort?.localAndNationalDisabilityLaw === "Yes" ? true : false}
                                />
                                <Form.Check
                                    inline
                                    label="No"
                                    type="checkbox"
                                    checked={property?.guest_comfort?.localAndNationalDisabilityLaw === "No" ? true : false}
                                />
                            </Col>
                        </Row> */}
                            <Row >
                                <Col md={7}>
                                    <p>Are facilities and public areas accessible by people with physical disabilities?</p>
                                </Col>
                                <Col md={5}>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        type="checkbox"
                                        checked={property?.guest_comfort?.areFacilitiesAndPublicAreaAccessible === "Yes" ? true : false}
                                    />
                                    <Form.Check
                                        inline
                                        label="No"
                                        type="checkbox"
                                        checked={property?.guest_comfort?.areFacilitiesAndPublicAreaAccessible === "No" ? true : false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={7}>
                                    <p>Regularity mattress is changed</p>
                                </Col>
                                <Col md={5}>
                                    <p className='fw-rg'>{property?.guest_comfort?.changeMattresses} years</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={7}>
                                    <p>Bedding provided</p>
                                </Col>
                                <Col md={5}>
                                    <p className='fw-rg'>{property?.guest_comfort?.doYouProvide ? property?.guest_comfort?.doYouProvide?.toString() : ''}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={7}>
                                    <p>Frequency bedding is renewed (months)</p>
                                </Col>
                                <Col md={5}>
                                    <p className='fw-rg'>{property?.guest_comfort?.renewBeddingMonths}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={7}>
                                    <p>Frequency pillows are renewed (months)</p>
                                </Col>
                                <Col md={5}>
                                    <p className='fw-rg'>{property?.guest_comfort?.renewPillowsMonths}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={7}>
                                    <p>Type of pillows provided</p>
                                </Col>
                                <Col md={5}>
                                    <p className='fw-rg'>{
                                        property?.guest_comfort?.detailsOfThePillows.map((value) => {
                                            return (<span>{value} ,</span>)
                                        })
                                    }</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={7}>
                                    <p>Nearest convenience food store (mins)</p>
                                </Col>
                                <Col md={5}>
                                    <p className='fw-rg'>{property?.guest_comfort?.convenienceFoodStore}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={7}>
                                    <p>Nearest gymnasium (mins)</p>
                                </Col>
                                <Col md={5}>
                                    <p className='fw-rg'>{property?.guest_comfort?.nearestGym}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={7}>
                                    <p>Gymnasium short term membership?</p>
                                </Col>
                                <Col md={5}>
                                    <p className='fw-rg'>{property?.guest_comfort?.gymShortTermMember}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={7}>
                                    <p>Gymnasium pay-as-you-go option?</p>
                                </Col>
                                <Col md={5}>
                                    <p className='fw-rg'>{property?.guest_comfort?.gymPayAsYouGo}</p>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='strok_bx'>
                            <h3 className='fz-32 mb-3'>Transport</h3>
                            <Row>
                                <Col md={7}>
                                    <p>On-site car parking</p>
                                </Col>
                                <Col md={5}>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        type="checkbox"
                                        checked={property?.transport?.doYouProvideOnSiteCarParking === "Yes" ? true : false}
                                    />
                                    <Form.Check
                                        inline
                                        label="No"
                                        type="checkbox"
                                        checked={property?.transport?.doYouProvideOnSiteCarParking === "No" ? true : false}
                                    />
                                </Col>
                            </Row>
                            {
                                (property?.transport?.doYouProvideOnSiteCarParking === "No") && <Row>
                                    <Col md={7}>
                                        <p>Distance to the nearest car park (mins)</p>
                                    </Col>
                                    <Col md={5}>
                                        <p className='fw-rg'>{property?.transport?.ifYouDoNotProvideOnSiteCarParking}</p>
                                    </Col>
                                </Row>
                            }
                            {
                                (property?.transport?.doYouProvideOnSiteCarParking === "Yes") && <Row>
                                    <Col md={7}>
                                        <p>Included in the rate?</p>
                                    </Col>
                                    <Col md={5}>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            type="checkbox"
                                            checked={property?.transport?.includedInTheRate === "Yes" ? true : false}
                                        />
                                        <Form.Check
                                            inline
                                            label="No"
                                            type="checkbox"
                                            checked={property?.transport?.includedInTheRate === "No" ? true : false}
                                        />
                                    </Col>
                                </Row>
                            }
                            {
                                (property?.transport?.doYouProvideOnSiteCarParking === "Yes" && property?.transport?.includedInTheRate === "No") && <Row>
                                    <Col md={7}>
                                        <p>cost of car parking</p>
                                    </Col>
                                    <Col md={5}>
                                        <p className='fw-rg'>{property?.transport?.costOfCarParking} GBP</p>
                                    </Col>
                                </Row>
                            }

                            <Row>
                                <Col md={7}>
                                    <p>Does the property provide disabled car parking bays?</p>
                                </Col>
                                <Col md={5}>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        type="checkbox"
                                        checked={property?.guest_comfort?.carParkingBays === "Yes" ? true : false}
                                    />
                                    <Form.Check
                                        inline
                                        label="No"
                                        type="checkbox"
                                        checked={property?.guest_comfort?.carParkingBays === "No" ? true : false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={7}>
                                    <p>Nearest airport</p>
                                </Col>
                                <Col md={5}>
                                    <p className='fw-rg'>{property?.transport?.nearestAirport}</p>
                                </Col>
                            </Row>
                            <Row >
                                <Col md={7}>
                                    <p>Distance to airport</p>
                                </Col>
                                <Col md={5}>
                                    <p className='fw-rg'> {property?.transport?.distanceToTheAirport} km</p>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <div className='aprt_bx mt-5'>
                    <h3 className='fz-32 mb-3'>Building amenities</h3>
                    <Row>
                        {
                            amenities && amenities?.map((value, key) => {

                                // let checked = false;

                                // for (let i = 0; i < amenities?.length; i++) {
                                //     if (amenities[i] === value?._id) {
                                //         checked = true;
                                //         break;
                                //     } else {
                                //         checked = false;
                                //     }
                                // }

                                return (
                                    <Col md={4} key={key}>
                                        <Row className='mt-3'>
                                            <Col md={2}>
                                                <img src={value.iconLocation} alt={value.name} />
                                            </Col>
                                            {/* <Col md={2}>
                                                    <div style={{ marginTop: '9px' }}>
                                                        <Form.Check
                                                            inline
                                                            type="checkbox"
                                                            checked={checked}
                                                        />
                                                    </div>
                                                </Col> */}
                                            <Col md={8}>
                                                <div style={{ marginTop: '9px', marginLeft: '-4%' }}>
                                                    {value.name}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                );
                            })
                        }

                    </Row>
                </div>
            </div>

            <Row hidden>
                <GooglePlaces onPlaceSelect={(location) => console.log(location, 'location')} />
            </Row>


            <Modal
                show={showMessage}
                onHide={() => { setShowMessage(false); setShowCommuteMap(false) }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered={false}
            >
                <Modal.Header closeButton>
                    <h1>{commuteBuilding?.property?.propertyName}</h1>
                </Modal.Header>
                <Modal.Body>
                    {!otherLocation && <Row>
                        <Col md={12}><div style={{ textAlign: 'left' }}>	<p>Please enter the specific key location</p></div></Col>
                    </Row>}
                    <Row>
                        {!otherLocation && <Col md={6}>

                            <GooglePlaces
                                style={
                                    {
                                        backgroundColor: 'white', borderRadius: '11px', paddingLeft: '50px',
                                        height: '46px'
                                    }
                                }

                                onPlaceSelect={(location) => {
                                    setLocationDataShowCommute(location);
                                }} />
                        </Col>}

                        {otherLocation && <Col md={6}>

                            <div style={{ textAlign: 'left', fontSize: '20px' }}>{otherLocation?.locationName + ',' + otherLocation?.address1} </div>
                        </Col>}
                        <Col md={6}>
                            {showCommuteMap && (
                                <div >
                                    <div className="travel-mode text-left">
                                        <div className="mb-3 ml-2">
                                            Distance : {distance} | {duration}
                                        </div>
                                        <span
                                            onClick={() => {
                                                onChangeTravelMode("WALKING");
                                            }}
                                            className={`${travelMode === "WALKING"
                                                ? "travel-mode__selected"
                                                : ""
                                                }`}
                                        >
                                            Walking
                                        </span>
                                        <span
                                            onClick={() => {
                                                onChangeTravelMode("DRIVING");
                                            }}
                                            className={`${travelMode === "DRIVING"
                                                ? "travel-mode__selected"
                                                : ""
                                                }`}
                                        >
                                            Driving
                                        </span>
                                        <span
                                            onClick={() => {
                                                onChangeTravelMode("TRANSIT");
                                            }}
                                            className={`${travelMode === "TRANSIT"
                                                ? "travel-mode__selected"
                                                : ""
                                                }`}
                                        >
                                            Public transport
                                        </span>
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            {showCommuteMap && (
                                <Row style={{
                                    position: "sticky",
                                    top: "265px"
                                }}>
                                    <Col
                                        className=""
                                        style={{ marginBottom: "-150px", marginTop: "14px" }}
                                    >
                                        <ShowCommuteMap
                                            googleMapURL={process.env.REACT_APP_MAP_URL}
                                            loadingElement={
                                                <div
                                                    style={{ height: `90%`, width: "100%" }}
                                                />
                                            }
                                            containerElement={
                                                <div
                                                    style={{
                                                        height: "90%",
                                                        width: `100%`,
                                                        aspectRatio: "1/0.5",
                                                    }}
                                                />
                                            }
                                            mapElement={
                                                <div
                                                    style={{ height: `80%`, width: `100%` }}
                                                />
                                            }
                                            location={locationsShowCommute}
                                            commuteBuilding={commuteBuilding}
                                            travelMode={travelMode}
                                            onResponse={onResponse}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ClientSinglePropertyView;

