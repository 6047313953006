import React, { useCallback, useEffect, useState } from 'react';
import { GoogleMap, InfoWindow, Marker, useLoadScript } from "@react-google-maps/api";
import { useHistory } from 'react-router-dom';
import markerIcon from '../../images/icons/svg/outside/Icon-Location-Finder-M.svg';

const ClientPropertyGoogleMap: React.FC<any> = ({ location, centerData, onShowCommute, building }): JSX.Element => {
  ////////// routing ///////
  const history = useHistory();

  const [center, setCenter] = useState<any>({
    lat: -3.745,
    lng: -38.523
  });


  const icon = {
    url: markerIcon,
    scaledSize: new google.maps.Size(45, 45)
  };

  const [locationBind, setLocationBind] = useState<Array<any>>([]);
  const [zoom, setZoom] = useState<number>(0);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY as string
  });
  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);
  setTimeout(() => setZoom(16), 500);

  useEffect(() => {
    if (centerData) {
      const center = {
        lat: centerData?.lat,
        lng: centerData?.lng
      };
      setCenter(center);
      setLat(centerData?.lat);
      setLng(centerData?.lng);
    }

    if (location) {
      setLocationBind(location);
    }
  }, [centerData, location])

  // console.log(centerData, 'centerData');
  // console.log(location, 'location');


  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    const bounds = new google.maps.LatLngBounds();
    locationBind?.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };


  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onDragEnd = useCallback(({ latLng }) => {
    setLat(latLng.lat());
    setLng(latLng.lng());

  }, []);

  return (
    <div className="google-map h-100">
      {
        isLoaded && locationBind.length !== 0 ? <GoogleMap
          onLoad={handleOnLoad}
          onClick={() => setActiveMarker(null)}
          mapContainerStyle={{ width: "100%", height: "85%" }}
          center={center}
          zoom={zoom}
        >
          <Marker  onDragEnd={onDragEnd}  draggable position={{ lat, lng }} >
            {building ? <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <div>
                <div>
                  <p>{building?.property?.propertyName}</p>
                </div>
              </div>
            </InfoWindow> : null}
          </Marker>
          {locationBind?.map(({ id, name, position, element, city }) => (
            <Marker
              key={position?.lat}
              position={position}
              onClick={() => handleActiveMarker(id)}
              icon={icon}
            >

              {activeMarker === id ? (
                <InfoWindow key={id + 1} onCloseClick={() => setActiveMarker(null)} >
                  <div style={{ position: 'relative', padding: '10px' }}>

                    <div style={{ cursor: 'pointer', marginBottom: '10px' }} >{name}</div>
                    {id !== 0 && <div style={{ cursor: 'pointer', }} onClick={() => onShowCommute(element)}>
                      <span style={{
                        color: "#000000",
                        textDecoration: "underline",
                        fontSize: "12px",
                        cursor: "pointer",
                        fontWeight: "bold",
                        textDecorationColor: "#e80f8b",
                      }}>Show commute</span>
                    </div>}
                  </div>
                </InfoWindow>) : null}
            </Marker>
          ))}
        </GoogleMap> : null
      }

    </div>
  )
};

export default ClientPropertyGoogleMap;