/* eslint-disable import/no-anonymous-default-export */
import { action, thunk } from 'easy-peasy';
import { call } from '../common/axios';



export default {
    partnerListSuccess: null,
    partnerListError: null,
    getPartnerTypes: null,
    getPartnerTypesError: null,
    createPartnerSuccess: null,
    createPartnerError: null,
    updatePartnerSuccess: null,
    updatePartnerError: null,
    partnerByIdSuccess: null,
    partnerByIdError: null,

    
  sendPartnerWelcomeEmailSuccess: null,
  sendPartnerWelcomeEmailError: null,
    // actions
    setPartnerList: action((state, payload) => ({ partnerListSuccess: payload })),
    setPartnerListError: action((state, payload) => ({ partnerListError: payload })),
    setGetPartnerTypes: action((state, payload) => ({ getPartnerTypes: payload })),
    setGetPartnerTypesError: action((state, payload) => ({ getPartnerTypesError: payload })),
    setCreatePartner: action((state, payload) => ({ createPartnerSuccess: payload })),
    setCreatePartnerError: action((state, payload) => ({ createPartnerError: payload })),
    setUpdatePartner: action((state, payload) => ({ updatePartnerSuccess: payload })),
    setUpdatePartnerError: action((state, payload) => ({ updatePartnerError: payload })),
    setPartnerById: action((state, payload) => ({ partnerByIdSuccess: payload })),
    setPartnerByIdError: action((state, payload) => ({ partnerByIdError: payload })),

    setSendPartnerWelcomeEmailSuccess: action((state, payload) => ({ sendPartnerWelcomeEmailSuccess: payload })),
    setSendPartnerWelcomeEmailError: action((state, payload) => ({ sendPartnerWelcomeEmailError: payload })),
  

    // thunks

       // send email
       sendPartnerWelcomeEmail: thunk(async (actions: any, payload: any) => {
        try {
          const result = await call('post', `/partner-sv/partner/sendPartnerWelcomeEmail`, payload, null);
          actions.setSendPartnerWelcomeEmailSuccess(result.data);
        } catch (error: any) {
          actions.setSendPartnerWelcomeEmailError(error.response.data);
        }
      }),
  
    onGetPartnerList: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('get', '/partner-sv/partner/list', payload, null);
            actions.setPartnerList(result.data);
        } catch (error: any) {
            actions.setPartnerListError(error.response.data);
        }
    }),



    onCreatePartner: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('post', `/partner-sv/partner`, payload, null);
            actions.setCreatePartner(result.data);
        } catch (error: any) {
            actions.setCreatePartnerError(error.response.data);
        }
    }),

    onUpdatePartner: thunk(async (actions: any, payload: any) => {
        try {
            const result = await call('patch', `/partner-sv/partner`, payload, null);
            actions.setUpdatePartner(result.data);
        } catch (error: any) {
            actions.setUpdatePartnerError(error.response.data);
        }
    }),

    getPartnerTypesList: thunk(async (actions: any, id: any) => {
        try {
            const result = await call('get', `/partner-sv/partner/type/list`, null, null);
            actions.setGetPartnerTypes(result.data);
        } catch (error: any) {
            actions.setGetPartnerTypesError(error.response.data);
        }
    }),

    

	getPartnerById: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('get', `/partner-sv/partner/${payload}`, null, null);
			actions.setPartnerById(result.data);
		} catch (error: any) {
			actions.setPartnerByIdError(error.response.data);
		}
	}),






}