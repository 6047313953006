import { Select } from "antd";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Image,
  Spinner,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";

import { toast } from "react-toastify";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useCallback, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import ImageUpload from "../../../common/imageUpload";
import {
  formatPhoneNumber,
} from "../../../../common/functions";

import { PartnerFormInput } from "../../../../common/interfaces";
import { formButton } from "../../../../common/components-style";

import { PhoneNumberUtil } from "google-libphonenumber";
const { Option } = Select;


const PartnerDetailForm: React.FC<any> = ({
  setFormOpen,
  partnerId,
}): JSX.Element => {
  const [title, setTitle] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<PartnerFormInput>();

  const [editEnable, setEditEnable] = useState<boolean>(false);
  const [partnerTypeList, setPartnerTypeList] = useState<any>();
  const [partnerData, setPartnerData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);


  const [image, setImage] = useState(null);
  const [imageUpdated, setImageUpdated] = useState(false);
  let [validPhoneNumber, setValidPhoneNumber] = useState<boolean>(false);

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const {
    getPartnerById,
    onCreatePartner,
    onUpdatePartner,
    getPartnerTypesList
  } = useStoreActions<any>((actions) => ({
    getPartnerById: actions.partner.getPartnerById,
    onCreatePartner: actions.partner.onCreatePartner,
    onUpdatePartner: actions.partner.onUpdatePartner,
    getPartnerTypesList: actions.partner.getPartnerTypesList
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const {
    createPartnerSuccess,
    createPartnerError,
    updatePartnerSuccess,
    updatePartnerError,
    partnerByIdSuccess,
    getPartnerTypes
  } = useStoreState<any>((state) => ({
    createPartnerSuccess: state.partner.createPartnerSuccess,
    createPartnerError: state.partner.createPartnerError,
    updatePartnerSuccess: state.partner.updatePartnerSuccess,
    updatePartnerError: state.partner.updatePartnerError,
    partnerByIdSuccess: state.partner.partnerByIdSuccess,
    getPartnerTypes: state.partner.getPartnerTypes
  }));

  useEffect(() => {
    if (partnerId) {
      getPartnerById(partnerId);
    }
  }, [getPartnerById, partnerId]);


  useEffect(() => {
    (async function () {
      await getPartnerTypesList();
    }())
  }, [getPartnerTypesList]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (updatePartnerSuccess) {
      setLoading(false);
      toast.success("Partner updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      scrollToTop();
      setEditEnable(false);
      getPartnerById(updatePartnerSuccess?.data?._id);


    }
  }, [getPartnerById, updatePartnerSuccess]);


  const scrollToTop = () => {
    // messagesRef?.current?.scrollIntoView(({ behavior: "smooth", block: "start", inline: "start" }));
    window.scrollTo({ top: 0, behavior: "smooth" });
  };


  ////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (createPartnerSuccess) {
      setLoading(false);


      toast.success("Partner created successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      setEditEnable(false);
      getPartnerById(createPartnerSuccess?.data?._id);
      setTimeout(() => setFormOpen(false), 3000);
    }

    if (createPartnerError) {
      setLoading(false);
      toast.error(createPartnerError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    if (updatePartnerError) {
      setLoading(false);

      toast.error(updatePartnerError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }



    if (partnerByIdSuccess) {
      const {
        name,
        image: imageVal,


      } = partnerByIdSuccess.data;

      // setResponsiblePersonEmail(responsiblePersonEmail);
      setPartnerData(partnerByIdSuccess?.data);


      /////////// major fields are direct manage ///////
      setValue("name", partnerByIdSuccess.data?.name);

      ////////////////////////////////
      setImage(imageVal);
      setTitle(name);



    }
  }, [createPartnerError, createPartnerSuccess, getPartnerById, partnerByIdSuccess, partnerId, setFormOpen, setValue, updatePartnerError]);



  let validatePhoneNumber = (inputNumber: string) => {
    let number = formatPhoneNumber(inputNumber);

    let valid = false;
    let isPossible = false;
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      isPossible = phoneUtil.isPossibleNumber(phoneUtil.parse(number));
      valid = phoneUtil.isValidNumber(phoneUtil.parse(number));
    } catch (e) {
      valid = false;
    }

    if (isPossible && valid) {
      setValidPhoneNumber(true);
      return true;
    } else {
      setValidPhoneNumber(false);
      return false;
    }
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const onSubmit = (values) => {

    setLoading(true);
    if (!validPhoneNumber) {
      setLoading(false);
      toast.error("Please enter valid phone number!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return;
    }



    if (partnerId) {
      values.id = partnerId;
      if (!validatePhoneNumber(values?.contactTelephone)) {
        setLoading(false);
        toast.error("Please enter valid phone number!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        return;
      }

      const data = {
        id: partnerId,
        imageUpdated,
        name: values.name,
        contact: values.contact,
        image: image,
        allowedDomains: values.allowedDomains.toString(),
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        country: values.country,
        postcode: values.postcode,
        contactTelephone: values.contactTelephone,
        mysaOwner: values.mysaOwner,
        contactEmail: values.contactEmail,
        partnerType: values.partnerType
      };

      onUpdatePartner(data);
    } else {

      values.image = image;
      values.imageUpdated = imageUpdated;
      values.allowedDomains = values.allowedDomains
        ? values.allowedDomains.toString()
        : "";

      onCreatePartner(values);
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const getImageBlob = useCallback(
    (imageBlob) => {
      setImage(imageBlob);
      setImageUpdated(true);
    },
    [setImage]
  );




  const changeFormEditable = useCallback(() => {
    scrollToTop();
    if (editEnable) {
      setEditEnable(false);
    } else {
      setEditEnable(true);
    }
  }, [editEnable]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const onTypeSelect = useCallback((value, options) => {
    // if (options?.data?.subType?.length !== 0) {
    //     setSubType(options.data.subType);
    // } else {
    //     setSubType([]);
    // }

  }, []);


  //////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getPartnerTypes) {
      const typeOfPartner = getPartnerTypes.data.map((val: any, key: number) => {
        return (
          <Option key={key} data={val} value={`${val._id}`} >{val.name}</Option>
        )
      });

      setPartnerTypeList(typeOfPartner);
    }

  }, [getPartnerTypes]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="content-title fixed_title">
            {title}
            <div className="ml-auto">
              {!loading &&
                !editEnable &&
                (
                  <div className="d-flex align-items-center gx-2">
                    {/* <HelpModal /> */}
                    <Button
                      id="edit-button"
                      style={formButton}
                      className="mb-0"
                      onClick={changeFormEditable}
                    >
                      Edit
                    </Button>
                  </div>
                )}
            </div>
          </div>
        </Col>
      </Row>
      <Container fluid className="p-0">
        <div className="jumbotron" style={{ marginTop: "65px" }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={1}></Col>
              <Col md={10}>
                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>}{" "}
                      Partner name
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && <p className="lead">{partnerData?.name}</p>}
                    {editEnable && (
                      <Form.Control
                        className={`${errors.name ? "is-invalid" : ""}`}
                        defaultValue={partnerData?.name}
                        {...register("name", { required: true })}

                      />
                    )}
                    {editEnable && (
                      <div className="invalid-feedback">
                        Please input partner name!
                      </div>
                    )}
                  </Col>
                </Row>

                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>}{" "}
                      Company logo
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && (
                      <Image width={100} src={String(image)} thumbnail={true} />
                    )}
                    {editEnable && (
                      <Controller
                        name="image"
                        defaultValue={String(image)}
                        control={control}
                        rules={{
                          required: image ? false : true,
                        }}
                        render={({ field }) => (
                          <ImageUpload
                            crop={false}
                            getImageBlob={getImageBlob}
                            type="image"
                            value={String(field.value)}
                            disabled={false}
                          />
                        )}
                      />
                    )}

                    {editEnable && errors.image && (
                      <div className="invalid-feedback-custom">
                        Please select company logo!
                      </div>
                    )}
                  </Col>
                </Row>

                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Partner type</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{partnerData?.partnerType?.name}</p>}
                    {editEnable &&
                      <Controller
                        name="partnerType"
                        defaultValue={partnerData?.partnerType?._id}
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) =>
                          <Select defaultValue={partnerData?.partnerType?._id} className={`${errors.partnerType ? 'is-invalid' : ''}`} style={{ width: '100%' }}
                            onChange={(value, option) => {
                              field.onChange(value)
                            }}
                            onSelect={onTypeSelect}
                            showSearch>
                            {partnerTypeList}
                          </Select>
                        }
                      />}
                    {editEnable && <div className="invalid-feedback">Please select a partner type!</div>}
                  </Col>
                </Row>


                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>}{" "}
                      Partner contact
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && (
                      <p className="lead">{partnerData?.contact}</p>
                    )}
                    {editEnable && (
                      <Form.Control
                        className={`${errors.contact ? "is-invalid" : ""}`}
                        defaultValue={partnerData?.contact}
                        {...register("contact", { required: true })}

                      />
                    )}
                    {editEnable && (
                      <div className="invalid-feedback">
                        Please input partner contact!
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>}{" "}
                      Email address
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && (
                      <p className="lead">
                        {partnerData?.contactEmail}
                      </p>
                    )}
                    {editEnable && (
                      <Form.Control
                        type="email"
                        className={`${errors.contactEmail ? "is-invalid" : ""
                          }`}
                        defaultValue={partnerData?.contactEmail}
                        {...register("contactEmail", { required: true })}
                      />
                    )}
                    {editEnable && (
                      <div className="invalid-feedback">
                        Please input partner contact email!
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>}{" "}
                      Telephone no.
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && partnerData?.contactTelephone && (
                      <p className="lead">
                        {formatPhoneNumber(partnerData?.contactTelephone)}
                      </p>
                    )}
                    {editEnable && (
                      <Controller
                        name="contactTelephone"
                        control={control}
                        defaultValue={partnerData?.contactTelephone}
                        rules={{
                          required: true,

                        }}
                        render={({ field }) => (
                          <PhoneInput
                            placeholder=""
                            isValid={(inputNumber: string) => {

                              return validatePhoneNumber(inputNumber);
                            }}
                            containerClass={`${errors.contactTelephone ? "is-invalid" : ""
                              }`}
                            value={String(field.value)}
                            country="gb"
                            onChange={(phone) => {
                              field.onChange(phone);

                            }}
                          />
                        )}
                      />
                    )}
                    {editEnable && (
                      <div className="invalid-feedback">
                        Please input  contact phone number!
                      </div>
                    )}
                  </Col>
                </Row>

                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>}{" "}
                      Domain name
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && (
                      <p className="lead">
                        {partnerData?.allowedDomains.map((value, key: number) => {
                          return <span>{value}, </span>;
                        })}
                      </p>
                    )}
                    {editEnable && (
                      <Controller
                        name="allowedDomains"
                        defaultValue={partnerData?.allowedDomains}
                        control={control}
                        rules={{
                          required: true,
                          pattern: {
                            value:
                              /((ftp|http|https):\/\/)?([a-z0-9]+\.)?([a-z0-9][a-z0-9-]*)?((\.[a-z]{2,6})|(\.[a-z]{2,6})(\.[a-z]{2,6}))$/,
                            message: "Please enter valid domain name",
                          },
                        }}
                        render={({ field }) => (
                          <Select
                            defaultValue={partnerData?.allowedDomains}
                            onChange={(value) => field.onChange(value)}
                            mode="tags"
                            className={`${errors.allowedDomains ? "is-invalid" : ""
                              }`}
                            options={[]}
                            style={{ width: "100%" }}

                          ></Select>
                        )}
                      />
                    )}
                    {editEnable && (
                      <div className="invalid-feedback">
                        Please enter valid domain name!
                      </div>
                    )}
                  </Col>
                </Row>

                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>}{" "}
                      Address1
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && (
                      <p className="lead">{partnerData?.address1}</p>
                    )}
                    {editEnable && (
                      <Form.Control
                        className={`${errors.address1 ? "is-invalid" : ""}`}
                        defaultValue={partnerData?.address1}
                        {...register("address1", { required: true })}
                      />
                    )}
                    {editEnable && (
                      <div className="invalid-feedback">
                        Please input address1!
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {/* {editEnable && <span className="requiredMark">*</span>}{" "} */}
                      Address2
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && (
                      <p className="lead">{partnerData?.address2}</p>
                    )}
                    {editEnable && (
                      <Form.Control
                        // className={`${errors.address2 ? "is-invalid" : ""}`}
                        defaultValue={partnerData?.address2}
                        {...register("address2",)}
                      />
                    )}
                    {/* {editEnable && (
                      <div className="invalid-feedback">
                        Please input address2!
                      </div>
                    )} */}
                  </Col>
                </Row>
                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>}{" "}
                      City
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && <p className="lead">{partnerData?.city}</p>}
                    {editEnable && (
                      <Form.Control
                        className={`${errors.city ? "is-invalid" : ""}`}
                        defaultValue={partnerData?.city}
                        {...register("city", { required: true })}
                      />
                    )}
                    {editEnable && (
                      <div className="invalid-feedback">Please input city!</div>
                    )}
                  </Col>
                </Row>
                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>}{" "}
                      Postal/Zip code
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && (
                      <p className="lead">{partnerData?.postcode}</p>
                    )}
                    {editEnable && (
                      <Form.Control
                        className={`${errors.postcode ? "is-invalid" : ""}`}
                        defaultValue={partnerData?.postcode}
                        {...register("postcode", { required: true })}
                      />
                    )}
                    {editEnable && (
                      <div className="invalid-feedback">
                        Please input postal code!
                      </div>
                    )}
                  </Col>
                </Row>


                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>}{" "}
                      Country
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && (
                      <p className="lead">{partnerData?.country}</p>
                    )}
                    {editEnable && (
                      <Form.Control
                        className={`${errors.country ? "is-invalid" : ""}`}
                        defaultValue={partnerData?.country}
                        {...register("country", { required: true })}

                      />
                    )}
                    {editEnable && (
                      <div className="invalid-feedback">
                        Please input country!
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>}{" "}
                      Mysa owner
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && (
                      <p className="lead">
                        {partnerData?.mysaOwner}
                      </p>
                    )}
                    {editEnable && (
                      <Form.Control
                        className={`${errors.mysaOwner
                          ? "is-invalid"
                          : ""
                          }`}
                        defaultValue={
                          partnerData?.mysaOwner
                        }
                        {...register("mysaOwner", {
                          required: true,
                        })}
                      />
                    )}
                    {editEnable && (
                      <div className="invalid-feedback">
                        Please input mysa owner name!
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}></Col>
                  <Col md={6} style={{ textAlign: "left" }}>


                    {!loading &&
                      editEnable &&
                      !partnerId &&

                      <Button
                        type="submit"
                        id="approve-button"
                        style={formButton}
                      >
                        Create
                      </Button>
                    }
                    {!loading &&
                      editEnable &&
                      partnerId &&

                      <Button
                        type="submit"
                        id="approve-button"
                        style={formButton}
                      >
                        Update
                      </Button>
                    }

                    {loading && (
                      <div style={{ textAlign: "center" }}>
                        <Spinner animation="border" variant="warning" />
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col md={1}></Col>
            </Row>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default PartnerDetailForm;
