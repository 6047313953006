
import React, { useCallback, useState } from "react";



import PartnerList from "./PartnerList";
import PartnerForm from "./form/partnerForm";

const PartnerHome: React.FC<any> = ({ location }): JSX.Element => {
  const [formOpen, setFormOpen] = useState<boolean>(false);


  const [partnerId, setPartnerId] = useState<number | null>(null);

  ///////////location?.state/////////////////////////////////////////////////////////////////////////////////////////
  const loadForm = useCallback((id) => {

    setFormOpen(true);
    setPartnerId(id);
  }, []);


  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const loadData = useCallback((id) => {
    setPartnerId(id);
  }, []);


  return (
    <div className="property-home">

      {!formOpen && (
        <PartnerList
          setPartnerId={setPartnerId}
          setFormOpen={setFormOpen}
          formOpen={formOpen}

          partnerId={partnerId}

          loadForm={loadForm}

        />
      )}
      {formOpen && (
        <PartnerForm
          partnerId={partnerId}
          setFormOpen={setFormOpen}
          loadData={loadData}
        />
      )}
    </div>
  );
};

export default PartnerHome;
