import { FC, useEffect, useMemo, useState } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import { useStoreActions, useStoreState } from "easy-peasy";
import { toast } from "react-toastify";
import { overlayTriggerDelay, tableStyles } from "../../../../common/components-style";
import RFPBreadCrumbPage from "../../../common/RFPBredcrumPage";
import _ from "lodash";
import { Button, Col, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";


const QuestionManagement: FC<any> = ({rfpName, programmeId, rfpProcessId, selectedClient, selectedProgramme, startDate }): JSX.Element => {
	const [selectedClientName, setSelectedClientName] = useState<string>("");
	const [selectedProgrammeName, setSelectedProgrammeName] = useState<string>("");
	const [selectedProgrammeId, setSelectedProgrammeId] = useState<string>("");
	const [submissionList, setSubmissionList] = useState<any>([]);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [question, setQuestion] = useState<string>("");

	// ///////////////////////////////////////////////////////////////////////////////////


	const {
		getCorporateProgrammeById,
		listBrandQuestionsByRfpId
	} = useStoreActions<any>((actions) => ({

		getCorporateProgrammeById: actions.client.getCorporateProgrammeById,
		listBrandQuestionsByRfpId: actions.rfpProcess.listBrandQuestionsByRfpId

	}));


	const {
		getCorporateProgrammeByIdSuccess,
		getCorporateProgrammeByIdError,
		listBrandQuestionsByRfpIdSuccess
	} = useStoreState<any>((state) => ({
		getCorporateProgrammeByIdSuccess: state.client.getCorporateProgrammeByIdSuccess,
		getCorporateProgrammeByIdError: state.client.getCorporateProgrammeByIdError,
		listBrandQuestionsByRfpIdSuccess: state.rfpProcess.listBrandQuestionsByRfpIdSuccess,
	}));

	/////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		setSelectedProgrammeId(programmeId);
	}, [programmeId]);

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (selectedProgrammeId) {
			getCorporateProgrammeById(selectedProgrammeId);
		}
	}, [selectedProgrammeId, getCorporateProgrammeById]);

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (rfpProcessId) {
			listBrandQuestionsByRfpId(rfpProcessId);
		}
	}, [rfpProcessId, listBrandQuestionsByRfpId]);

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getCorporateProgrammeByIdSuccess?.data) {
			const { clientId, programmeName, _id } = getCorporateProgrammeByIdSuccess.data;

			setSelectedClientName(clientId.companyName);
			setSelectedProgrammeId(_id);
			setSelectedProgrammeName(programmeName);

		}
		if (getCorporateProgrammeByIdError) {
			toast.error(getCorporateProgrammeByIdError?.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});

		}
	}, [getCorporateProgrammeByIdSuccess, getCorporateProgrammeByIdError, selectedClientName, selectedProgrammeName, rfpProcessId]);

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (listBrandQuestionsByRfpIdSuccess?.data) {
			setSubmissionList(listBrandQuestionsByRfpIdSuccess?.data)
		}

	}, [listBrandQuestionsByRfpIdSuccess]);

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const data = useMemo(() => {
		return _.map(submissionList, rfp => {
			return {
				key: rfp.id,
				brandName: rfp.brandName,
				question: rfp.question,
				dateSubmitted: rfp.dateSubmitted,
				submittedBy: rfp.submittedBy,

			}
		})
	}, [submissionList]);

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const columns = [
		{
			name: 'Brand',
			selector: row => row.brandName,
		},
		{
			name: 'Question',
			minWidth: '230px',
			cell: (row) => {
				return (
					<OverlayTrigger
						placement="top"
						delay={overlayTriggerDelay}
						overlay={<Tooltip id='tooltip-table-top'>View question</Tooltip>}
					>
						<span className='' onClick={() => { setOpenModal(true); setQuestion(row.question) }}>{row.question}</span>
					</OverlayTrigger>
				)
			}
		},
		{
			name: 'Date submitted',
			selector: row => moment(row.dateSubmitted).format("YYYY-MM-DD"),
		},
		{
			name: 'Submitted by',
			selector: row => row.submittedBy,
		},
	];

	return (
		<div>
			{rfpProcessId &&
				<RFPBreadCrumbPage isPageName="RFP" selectedClient={selectedClient} selectedProgramme={selectedProgramme} selectedClientName={selectedClientName} selectedProgrammeName={selectedProgrammeName} rfpProcessId={rfpProcessId} programmeId={programmeId} startDate={rfpName}></RFPBreadCrumbPage>
			}

			<div>
				<DataTable
					columns={columns}
					data={data}
					pagination
					responsive
					customStyles={tableStyles}
					striped={true}
					theme='mysaTable'
					fixedHeader={true}
				/>
			</div>

			<Modal
				show={openModal}
				onHide={() => { setOpenModal(false); }}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered={false}
			>
				<Modal.Header closeButton>
				</Modal.Header>
				<Modal.Body>

					<Row>
						<Col md={10}><div style={{ textAlign: 'left' }}>	<p>{question}</p></div></Col>
						<Col md={1}></Col>
					</Row>
					<Row>
						<Col md={10}>
							<Row className='formSpace'>
								<Col >
									<div style={{ textAlign: 'center' }}>
										<Button onClick={() => { setOpenModal(false); }} type="submit" id='edit-button' style={{
											height: "40px",
											width: '220px',
											borderRadius: 30,
											paddingLeft: "30px",
											paddingRight: "30px",
											marginRight: '20px'
										}}>Close</Button>
									</div>
								</Col>
							</Row>
						</Col>
						<Col md={1}></Col>
					</Row>
				</Modal.Body>
			</Modal>
		</div>
	)
}

export default QuestionManagement;