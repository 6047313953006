import { Select, TimePicker } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { UserAccess, Status } from '../../../../common/constants';
import { canBrandHotelModuleWrite } from '../../../../common/functions';
import { Col, Form, Row, Button, Spinner } from 'react-bootstrap';
import { HotelCheckInFormInput } from '../../../../common/interfaces';
import { Controller, useForm } from 'react-hook-form';
import { formButton } from '../../../../common/components-style';
import { toast } from 'react-toastify';
import BreadCrumbPage from '../../../common/BreadCrumbPage';

const { Option } = Select;

const format = 'HH:mm';

const HotelCheckInProcess: React.FC<any> = ({ allInOneHotelBreadCrumb, brandId, hotelId, setFormOpen, selectedBrand, tab }): JSX.Element => {
	///////////////////// initialize form ////////////////////////////////////// 
	const { register, handleSubmit, formState: { errors }, control } = useForm<HotelCheckInFormInput>();
	const [hotelData, setHotelData] = useState<any>();
	const [editEnable, setEditEnable] = useState<boolean>(false);
	const [checkInData, setCheckInData] = useState<any>();
	const [loading, setLoading] = useState<boolean>(false);
	const [alternativeCheckInProcess, setAlternativeCheckInProcess] = useState<Array<Object>>([]);
	const [receptionHoursCheckInProcess, setReceptionHoursCheckInProcess] = useState<boolean>(false);
	const [checkInId, setCheckInId] = useState<string>();
	const [checkInProcess, setCheckInProcess] = useState<Array<Object>>([]);
	const [checkIn, setCheckIn] = useState<any>('');
	const [checkOut, setCheckOut] = useState<any>('');
	const [alternativeCheckInProcessName, setAlternativeCheckInProcessName] = useState<String>('');
	const [receptionWorkingFrom, setReceptionWorkingFrom] = useState<String>('');
	const [receptionWorkingTo, setReceptionWorkingTo] = useState<String>('');

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const { getHotel, getCheckInProcessDetail, getHotelCheckInProcessDetailByHotelId, createHotelCheckInProcessDetail, updateHotelCheckInProcessDetail, resetHotel } = useStoreActions<any>((actions) => ({
		getCheckInProcessDetail: actions.hotel.getCheckInProcessDetail,
		getHotelCheckInProcessDetailByHotelId: actions.hotel.getHotelCheckInProcessDetailByHotelId,
		createHotelCheckInProcessDetail: actions.hotel.createHotelCheckInProcessDetail,
		updateHotelCheckInProcessDetail: actions.hotel.updateHotelCheckInProcessDetail,
		resetHotel: actions.hotel.resetHotel,
		getHotel: actions.hotel.getHotel,
	}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const { getHotelSuccess, getCheckInProcessListSuccess, getHotelCheckInProcessDetailByIdSuccess, updateHotelCheckInProcessDetailSuccess, updateHotelCheckInProcessDetailError,
		getHotelCheckInProcessDetailByIdError, createHotelCheckInProcessDetailSuccess, createHotelCheckInProcessDetailError } = useStoreState<any>((state) => ({
			getCheckInProcessListSuccess: state.hotel.getCheckInProcessListSuccess,
			getHotelCheckInProcessDetailByIdSuccess: state.hotel.getHotelCheckInProcessDetailByIdSuccess,
			getHotelCheckInProcessDetailByIdError: state.hotel.getHotelCheckInProcessDetailByIdError,
			createHotelCheckInProcessDetailSuccess: state.hotel.createHotelCheckInProcessDetailSuccess,
			createHotelCheckInProcessDetailError: state.hotel.createHotelCheckInProcessDetailError,
			updateHotelCheckInProcessDetailSuccess: state.hotel.updateHotelCheckInProcessDetailSuccess,
			updateHotelCheckInProcessDetailError: state.hotel.updateHotelCheckInProcessDetailError,
			getHotelSuccess: state.hotel.getHotelSuccess

		}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (hotelId) {
			getHotelCheckInProcessDetailByHotelId(hotelId);
			getHotel(hotelId);
		}
		getCheckInProcessDetail();
	}, [getCheckInProcessDetail, getHotel, getHotelCheckInProcessDetailByHotelId, hotelId]);

	const alternativeCheckInProcessAnswer = useCallback(() => {
		if (checkInProcess) {
			checkInProcess.forEach((data: any) => {
				data.alternativeCheckInProcess.forEach(element => {
					if (element._id === checkInData?.alternativeCheckInProcess) {
						setAlternativeCheckInProcessName(element.name);
					}
				});
			});
		} else {
			return false;
		}

	}, [checkInData, checkInProcess])


	////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {

		if (getCheckInProcessListSuccess) {
			setCheckInProcess(getCheckInProcessListSuccess.data);
		}
		if (getHotelCheckInProcessDetailByIdSuccess) {
			if (getHotelCheckInProcessDetailByIdSuccess.data) {
				const checkIn: any = getHotelCheckInProcessDetailByIdSuccess.data;
				setCheckInData(checkIn);
				setCheckIn(moment(checkInData?.checkInTime, format));
				setCheckOut(moment(checkInData?.checkOutTime, format));
				setCheckInId(checkIn._id);
				setAlternativeCheckInProcess(checkIn.checkInProcess?.alternativeCheckInProcess || []);
				setReceptionHoursCheckInProcess((checkIn.checkInProcess.displayWorkingHours && checkIn.checkInProcess.displayWorkingHours === Status.ACTIVE) ? true : false);
			}
		}

		if (getHotelCheckInProcessDetailByIdError) {
			toast.error(getHotelCheckInProcessDetailByIdError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}

		if (getHotelSuccess) {
			setHotelData(getHotelSuccess.data);
		}

		alternativeCheckInProcessAnswer();
	}, [alternativeCheckInProcessAnswer, checkInData?.checkInTime, checkInData?.checkOutTime, createHotelCheckInProcessDetailSuccess, getCheckInProcessDetail, getCheckInProcessListSuccess, getHotelCheckInProcessDetailByIdError, getHotelCheckInProcessDetailByIdSuccess, getHotelSuccess]);

	////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (createHotelCheckInProcessDetailSuccess) {
			setLoading(false);
			setEditEnable(false);
			toast.success("Hotel check-in process created successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetHotel();
			getHotelCheckInProcessDetailByHotelId(hotelId);
		}

		if (createHotelCheckInProcessDetailError) {
			setLoading(false);
			setEditEnable(false);
			toast.error(createHotelCheckInProcessDetailError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetHotel();
			getHotelCheckInProcessDetailByHotelId(hotelId);
		}

		if (updateHotelCheckInProcessDetailSuccess) {
			setLoading(false);
			setEditEnable(false);
			toast.success("Hotel check-in process updated successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetHotel();
			getHotelCheckInProcessDetailByHotelId(hotelId);
		}

		if (updateHotelCheckInProcessDetailError) {
			setLoading(false);
			toast.error(updateHotelCheckInProcessDetailError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetHotel();
		}

	}, [createHotelCheckInProcessDetailError, createHotelCheckInProcessDetailSuccess, getHotelCheckInProcessDetailByHotelId, hotelId, resetHotel, updateHotelCheckInProcessDetailError, updateHotelCheckInProcessDetailSuccess]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const renderCheckInProcess = useCallback(() => {
		if (checkInProcess) {
			return checkInProcess.map((val: any, key: number) => {
				return (
					<Option key={key} data={val} value={`${val._id}`} >{val.name}</Option>
				)
			});
		}

	}, [checkInProcess]);


	////////////////////////////////////////////////////////////////////////////////////////////////////
	const onTypeSelect = useCallback((selectedId) => {
		checkInProcess.forEach((data: any) => {
			if (data._id === selectedId) {
				setReceptionHoursCheckInProcess((data.displayWorkingHours && data.displayWorkingHours === Status.ACTIVE) ? true : false);
				setAlternativeCheckInProcess(data.alternativeCheckInProcess);
			}
		});
	}, [checkInProcess]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const renderAlternativeCheckInProcess = useMemo(() => {
		return _.map(alternativeCheckInProcess, (val: any, key: number) => {
			return (
				<Option key={key} data={val} value={`${val._id}`} >{val.name}</Option>
			)
		})
	}, [alternativeCheckInProcess]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const onCheckInTimeChange = useCallback((timeString) => {
		setCheckIn(timeString);
	}, []);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const onCheckOutTimeChange = useCallback((timeString) => {
		setCheckOut(timeString);
	}, []);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const onReceptionWorkingFromChange = useCallback((timeString) => {
		setReceptionWorkingFrom(timeString);
	}, []);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const onReceptionWorkingToChange = useCallback((timeString) => {
		setReceptionWorkingTo(timeString);
	}, []);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const onFinish = useCallback((values) => {
		setLoading(true);
		if (alternativeCheckInProcess.length === 0) {
			values.alternativeCheckInProcess = '';
		}
		if (checkInId) {
			values.id = checkInId;
			const data = {
				id: checkInId,
				checkInTime: moment(checkIn).format('HH:mm'),
				checkOutTime: moment(checkOut).format('HH:mm'),
				receptionWorkingHoursFrom: values.receptionWorkingHoursFrom ? moment(values.receptionWorkingHoursFrom._d).format('HH:mm') : receptionWorkingFrom,
				receptionWorkingHoursTo: values.receptionWorkingHoursTo ? moment(values.receptionWorkingHoursTo._d).format('HH:mm') : receptionWorkingTo,
				checkInProcess: values.checkInProcess,
				alternativeCheckInProcess: values.alternativeCheckInProcess,
				additionalInformation: values.additionalInformation,
			}
			updateHotelCheckInProcessDetail(data);
		} else {
			const data = {
				hotelId: hotelId,
				checkInTime: values.checkInTime ? moment(values.checkInTime._d).format('HH:mm') : checkIn,
				checkOutTime: values.checkOutTime ? moment(values.checkOutTime._d).format('HH:mm') : checkOut,
				receptionWorkingHoursFrom: values.receptionWorkingHoursFrom ? moment(values.receptionWorkingHoursFrom._d).format('HH:mm') : receptionWorkingFrom,
				receptionWorkingHoursTo: values.receptionWorkingHoursTo ? moment(values.receptionWorkingHoursTo._d).format('HH:mm') : receptionWorkingTo,
				checkInProcess: values.checkInProcess,
				alternativeCheckInProcess: values.alternativeCheckInProcess,
				additionalInformation: values.additionalInformation,
			}
			createHotelCheckInProcessDetail(data);
		}

	}, [alternativeCheckInProcess, checkIn, checkInId, checkOut, createHotelCheckInProcessDetail, hotelId, receptionWorkingFrom, receptionWorkingTo, updateHotelCheckInProcessDetail]);

	const changeFormEditable = useCallback(() => {
		if (editEnable) {
			setEditEnable(false);
		} else {
			setEditEnable(true);
		}
	}, [editEnable]);



	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	return (
		<>
			<div className="content-title fixed_title">
				{
					<BreadCrumbPage tab={tab} allInOneHotelBreadCrumb={allInOneHotelBreadCrumb} isPageName="Hotel" selectedBrand={selectedBrand} hotelData={hotelData} setFormOpen={setFormOpen} ></BreadCrumbPage>
				}
				<div className='edit_btn_wpr'>
					<div className='d-flex align-items-center justify-content-end gx-2'>
						{
							!loading && !editEnable && hotelId && canBrandHotelModuleWrite(brandId, hotelId, UserAccess.hotel_access.details.code) && <Button id='edit-button' className='mb-0' style={formButton} onClick={changeFormEditable} >Edit</Button>
						}
					</div>
				</div>
			</div>
			<div>
				<div className="jumbotron">
					<Form onSubmit={handleSubmit(onFinish)}>
						<Row>
							<Col md={1}></Col>
							<Col md={7}>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Check-in / out</Form.Label>
									</Col>
									<Col md={3} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{checkInData?.checkInTime}</p>}
										{editEnable &&
											<Controller
												name="checkInTime"
												defaultValue={String(checkInData?.checkInTime ? moment(checkInData?.checkInTime, format) : '')}
												control={control}
												rules={{
													required: true,
												}}
												render={({ field }) =>
													<>
														{
															checkInData?.checkInTime ?
																<TimePicker showNow={false} defaultValue={moment(checkInData?.checkInTime, format)} autoFocus={true} format={format} placeholder='check-in time' onChange={(value) => {
																	onCheckInTimeChange(value);
																	field.onChange(value);
																}} /> :
																<TimePicker showNow={false} autoFocus={true} format={format} placeholder='check-in time' onChange={(value) => {
																	onCheckInTimeChange(value);
																	field.onChange(value);
																}} />
														}
													</>
												}
											/>}
										{editEnable && errors.checkInTime && <div className="invalid-feedback-custom">Please select check-in time!</div>}
									</Col>
									<Col md={3} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{checkInData?.checkOutTime}</p>}
										{editEnable &&
											<Controller
												name="checkOutTime"
												defaultValue={String(checkInData?.checkOutTime ? moment(checkInData?.checkOutTime, format) : '')}
												control={control}
												rules={{
													required: true,
												}}
												render={({ field }) =>
													<>
														{
															checkInData?.checkOutTime ?
																<TimePicker showNow={false} defaultValue={moment(checkInData?.checkOutTime, format)} autoFocus={true} format={format} placeholder='check out time' onChange={(value) => {
																	onCheckOutTimeChange(value);
																	field.onChange(value);
																}} /> :
																<TimePicker showNow={false} autoFocus={true} format={format} placeholder='check out time' onChange={(value) => {
																	onCheckOutTimeChange(value);
																	field.onChange(value);
																}} />
														}

													</>
												}
											/>}
										{editEnable && errors.checkOutTime && <div className="invalid-feedback-custom">Please select  check out time!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Check-in process</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{checkInData?.checkInProcess.name}</p>}
										{editEnable &&
											<Controller
												name="checkInProcess"
												defaultValue={checkInData?.checkInProcess._id}
												control={control}
												rules={{
													required: true,
												}}
												render={({ field }) =>
													<Select defaultValue={checkInData?.checkInProcess._id} onSelect={(value) => {
														onTypeSelect(value);
														field.onChange(value);
													}} style={{ width: '100%' }}>
														{renderCheckInProcess()}
													</Select>
												}
											/>}
										{editEnable && errors.checkInProcess && <div className="invalid-feedback-custom">Please select a check-in process!</div>}
									</Col>
								</Row>
								{alternativeCheckInProcess.length === 0 ||
									<Row className='formSpace'>
										<Col md={6} style={{ textAlign: 'right' }}>
											<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Alternative check-in process</Form.Label>
										</Col>
										<Col md={6} style={{ textAlign: 'left' }}>
											{!editEnable && <p className="lead">{alternativeCheckInProcessName}</p>}
											{editEnable &&
												<Controller
													name="alternativeCheckInProcess"
													defaultValue={checkInData?.alternativeCheckInProcess}
													control={control}
													rules={{
														required: true,
													}}
													render={({ field }) =>
														<Select defaultValue={checkInData?.alternativeCheckInProcess} onChange={(value) => {
															field.onChange(value)
														}} style={{ width: '100%' }}>
															{renderAlternativeCheckInProcess}
														</Select>
													}
												/>}
											{editEnable && errors.alternativeCheckInProcess && <div className="invalid-feedback-custom">Please select a sub alternative check-in process!</div>}
										</Col>
									</Row>
								}
								{
									receptionHoursCheckInProcess &&
									<Row className='formSpace'>
										<Col md={6} style={{ textAlign: 'right' }}>
											<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Reception opening hours</Form.Label>
										</Col>
										<Col md={3} style={{ textAlign: 'left' }}>
											{!editEnable && <p className="lead">{checkInData?.receptionWorkingHoursFrom}</p>}
											{editEnable &&
												<Controller
													name="receptionWorkingHoursFrom"
													defaultValue={String(checkInData?.receptionWorkingHoursFrom ? moment(checkInData?.receptionWorkingHoursFrom, format) : '')}
													control={control}
													render={({ field }) =>
														<>
															{checkInData?.receptionWorkingHoursFrom ?
																<TimePicker showNow={false} defaultValue={checkInData?.receptionWorkingHoursFrom ? moment(checkInData?.receptionWorkingHoursFrom, format) : moment(new Date(), format)} autoFocus={true} format={format} placeholder='reception working hours from' onChange={(value) => {
																	onReceptionWorkingFromChange(value);
																	field.onChange(value);
																}} /> :
																<TimePicker showNow={false} autoFocus={true} format={format} placeholder='reception working hours from' onChange={(value) => {
																	onReceptionWorkingFromChange(value);
																	field.onChange(value);
																}} />
															}
														</>
													}
												/>}
										</Col>
										<Col md={3} style={{ textAlign: 'left' }}>
											{!editEnable && <p className="lead">{checkInData?.receptionWorkingHoursTo}</p>}
											{editEnable &&
												<Controller
													name="receptionWorkingHoursTo"
													defaultValue={String(checkInData?.receptionWorkingHoursTo ? moment(checkInData?.receptionWorkingHoursTo, format) : '')}
													control={control}
													render={({ field }) =>
														<>
															{
																checkInData?.receptionWorkingHoursTo ?
																	<TimePicker showNow={false} defaultValue={checkInData?.receptionWorkingHoursTo ? moment(checkInData?.receptionWorkingHoursTo, format) : moment(new Date(), format)} autoFocus={true} format={format} placeholder='reception working hours to' onChange={(value) => {
																		onReceptionWorkingToChange(value);
																		field.onChange(value);
																	}} /> :
																	<TimePicker showNow={false} autoFocus={true} format={format} placeholder='reception working hours to' onChange={(value) => {
																		onReceptionWorkingToChange(value);
																		field.onChange(value);
																	}} />
															}

														</>
													}
												/>}
										</Col>
									</Row>
								}
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>Additional Information</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{checkInData?.additionalInformation}</p>}
										{editEnable && <Form.Control as="textarea" rows={5} className={`${errors.additionalInformation ? 'is-invalid' : ''}`} defaultValue={checkInData?.additionalInformation} {...register("additionalInformation", { required: false })} />}
										{editEnable && <div className="invalid-feedback">Please input your additional information!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!loading && editEnable && hotelId && checkInData && canBrandHotelModuleWrite(brandId, hotelId, UserAccess.hotel_access.details.code) &&
											<Button type="submit" id='approve-button' style={formButton}>Update</Button>
										}
										{!loading && editEnable && hotelId && !checkInData && canBrandHotelModuleWrite(brandId, hotelId, UserAccess.hotel_access.details.code) &&
											<Button type="submit" id='approve-button' style={formButton}>Create</Button>
										}

										{
											loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
										}
									</Col>
								</Row>
							</Col>
							<Col md={4}></Col>
						</Row>
					</Form>
				</div>
			</div>

		</>

	)
};

export default HotelCheckInProcess;
