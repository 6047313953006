import React, { useCallback, useEffect, useState } from 'react';
import GoogleMapMultipleMarker from '../../../../common/GoogleMapMultipleMarker';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { getStorage } from '../../../../../common/storage';
import { Col, Modal, Row } from 'react-bootstrap';
import { BrandRfpPropertyEvaluationStatus } from '../../../../../common/constants';
import _ from 'lodash';
import GooglePlaces from '../../../../common/GooglePlaces';
import ShowCommuteMap from '../../../../common/ShowCommuteMap';

const ClientMysaReview: React.FC<any> = ({ selectCityName }): JSX.Element => {

    const [filter] = useState("rfp_locations");
    const [rfpProcess] = useState<any>(getStorage('selectedRFPProcess'));
    const [htmlData, setHtmlData] = useState<any>("");
    const [markers, setMarkers] = useState<Array<any>>([]);
    const [location, setLocation] = useState<any>({});
    const [clientLocation, setClientLocation] = useState<string>("");
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [commuteBuilding, setCommuteBuilding] = useState<any>();

    const [showCommuteMap, setShowCommuteMap] = useState<boolean>(false);
    const [locationsShowCommute, setLocationsShowCommute] = useState<any>();
    const [travelMode, setTravelMode] = useState<any>("");
    const [distance, setDistance] = useState<any>("0.0 km");
    const [duration, setDuration] = useState<any>("00 mins");
    const [otherLocation, setOtherLocation] = useState<any>({});
    const {
        getRFPAdminFilterReview,
        programmePropertyList,
        getClientLocationByProgrammeId
    } = useStoreActions<any>((actions) => ({
        getRFPAdminFilterReview: actions.rfpProcess.getRFPAdminFilterReview,
        programmePropertyList:
            actions.rfpProcess.programmePropertyList,
        getClientLocationByProgrammeId: actions.client.getClientLocationByProgrammeId,
    }));


    const {
        getRFPAdminFilterReviewSuccess,
        getProgrammePropertyListSuccess,
        getRFPAdminReviewSuccess,
        getClientLocationByProgrammeIdSuccess
    } = useStoreState<any>((state) => ({
        getRFPAdminFilterReviewSuccess: state.rfpProcess.getRFPAdminFilterReviewSuccess,
        getProgrammePropertyListSuccess:
            state.rfpProcess.getProgrammePropertyListSuccess,
        getRFPAdminReviewSuccess: state.rfpProcess.getRFPAdminReviewSuccess,
        getClientLocationByProgrammeIdSuccess: state.client.getClientLocationByProgrammeIdSuccess,
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        const programmeId = getStorage('selectedProgramsOption')?.value ? getStorage('selectedProgramsOption')?.value : getStorage('selectedRFPProcess')?.programmeId
        if (programmeId) {
            getClientLocationByProgrammeId(programmeId);
        }

    }, [getClientLocationByProgrammeId, location]);




    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getClientLocationByProgrammeIdSuccess) {
            // const data = getClientLocationByProgrammeIdSuccess?.data;

            const data = _.filter(getClientLocationByProgrammeIdSuccess?.data, list => ((list.city === selectCityName)));

            setOtherLocation(data[0]);

        }


    }, [getClientLocationByProgrammeIdSuccess, selectCityName])

    useEffect(() => {

        if (otherLocation) {
            setShowCommuteMap(true);
            setLocationsShowCommute({ lat: Number(otherLocation?.latitude), lng: Number(otherLocation?.longitude), locationName: otherLocation?.address1 });
        }


    }, [getClientLocationByProgrammeIdSuccess, otherLocation?.address1, otherLocation, selectCityName])

    useEffect(() => {
        if (filter) {
            const payload = {
                rfpProcessId: rfpProcess?._id,
                reviewType: filter,
                locationName: selectCityName
            }
            getRFPAdminFilterReview(payload);

            const paylod = {
                rfp_id: rfpProcess?._id,
                city_name: selectCityName
            };
            programmePropertyList(paylod);

            rfpProcess?.locations.forEach((location) => {
                location?.cities?.forEach(element => {
                    if (element.cityName === selectCityName) {
                        setClientLocation(element?.locations?.[0]?.locationName);
                        const loc = {
                            id: 0,
                            name: `${element?.locations?.[0]?.locationName}`,
                            position: { lat: Number(element?.locations?.[0]?.latitude), lng: Number(element?.locations?.[0]?.longitude) }
                        };
                        setLocation(loc);
                    }
                });
            });
        }
    }, [filter, getRFPAdminFilterReview, programmePropertyList, rfpProcess?._id, rfpProcess?.locations, selectCityName])

    useEffect(() => {
        if (getRFPAdminFilterReviewSuccess) {
            setHtmlData(getRFPAdminFilterReviewSuccess?.data?.description ? getRFPAdminFilterReviewSuccess?.data?.description : getRFPAdminFilterReviewSuccess?.data?.reviewTemplate);
        }

        if (getProgrammePropertyListSuccess?.data) {
            const markrs: any = [];
            const data = _.filter(getProgrammePropertyListSuccess?.data, list => ((list.evaluationStatus === BrandRfpPropertyEvaluationStatus.COMPLETE)));
            data?.forEach(element => {
                const loc = {
                    id: element?.property?._id,
                    name: `${element?.property?.propertyName} - ${element?.property?.brandId?.name}`,
                    position: { lat: Number(element?.property?.latitude), lng: Number(element?.property?.longitude) },
                    element: element,
                    city: element?.property?.city
                };
                markrs.push(loc);
            });
            if (location?.name) {
                markrs.push(location);
            }
            setMarkers(markrs);
        }

    }, [getRFPAdminFilterReviewSuccess, getProgrammePropertyListSuccess, getRFPAdminReviewSuccess, location])


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const onShowCommute = useCallback((value) => {

        setShowMessage(true);
        setCommuteBuilding(value);
        setTravelMode("WALKING");
        // if (otherLocation) {
        //     setShowCommuteMap(true);
        //   } else {
        //     setShowCommuteMap(false);
        //   }
    }, []);



    const setLocationDataShowCommute = useCallback((location) => {

        setLocationsShowCommute({ lat: Number(location.lat), lng: Number(location.lng), locationName: location.address1 });
        setShowCommuteMap(true);
    }, []);



    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const onChangeTravelMode = useCallback((value) => {
        if (value) {
            setTravelMode(value);
        }
    }, []);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const onResponse = useCallback((event) => {
        setDuration(event.routes[0].legs[0].duration.text);
        setDistance(event.routes[0].legs[0].distance.text);
    }, []);


    return (
        <>
            <Row className="mb-10">
                <Col md={5}>
                    {
                        markers.length !== 0 && location && rfpProcess && <GoogleMapMultipleMarker onShowCommute={onShowCommute} location={markers} centerData={location} rfpProcessId={rfpProcess?._id} />
                    }
                </Col>
                <Col md={7}>
                    <Row className="mt-8 ml-5">
                        <h2 style={{ fontSize: '2rem', fontWeight: 'bold' }}>{selectCityName}</h2>
                        {
                            <div className='customStyle' dangerouslySetInnerHTML={{ __html: htmlData }} />
                        }

                    </Row>
                </Col>
            </Row>

            <Row hidden>
                <GooglePlaces onPlaceSelect={(location) => console.log(location, 'location')} />
            </Row>


            <Modal
                show={showMessage}
                onHide={() => { setShowMessage(false); setShowCommuteMap(false) }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered={false}
            >
                <Modal.Header closeButton>
                    <h1>{commuteBuilding?.property?.propertyName}</h1>
                </Modal.Header>
                <Modal.Body>
                    {!otherLocation && <Row>
                        <Col md={12}><div style={{ textAlign: 'left' }}>	<p>Please enter the specific key location</p></div></Col>
                    </Row>}
                    <Row>
                        {!otherLocation && <Col md={6}>

                            <GooglePlaces
                                style={
                                    {
                                        backgroundColor: 'white', borderRadius: '11px', paddingLeft: '50px',
                                        height: '46px'
                                    }
                                }

                                onPlaceSelect={(location) => {
                                    setLocationDataShowCommute(location);
                                }} />




                        </Col>}

                        {otherLocation && <Col md={6}>

                            <div style={{ textAlign: 'left', fontSize: '20px' }}>{otherLocation?.locationName + ',' + otherLocation?.address1} </div>



                        </Col>}
                        <Col md={6}>
                            {showCommuteMap && (
                                <div >
                                    <div className="travel-mode text-left">
                                        <div className="mb-3 ml-2">
                                            Distance : {distance} | {duration}
                                        </div>
                                        <span
                                            onClick={() => {
                                                onChangeTravelMode("WALKING");
                                            }}
                                            className={`${travelMode === "WALKING"
                                                ? "travel-mode__selected"
                                                : ""
                                                }`}
                                        >
                                            Walking
                                        </span>
                                        <span
                                            onClick={() => {
                                                onChangeTravelMode("DRIVING");
                                            }}
                                            className={`${travelMode === "DRIVING"
                                                ? "travel-mode__selected"
                                                : ""
                                                }`}
                                        >
                                            Driving
                                        </span>
                                        <span
                                            onClick={() => {
                                                onChangeTravelMode("TRANSIT");
                                            }}
                                            className={`${travelMode === "TRANSIT"
                                                ? "travel-mode__selected"
                                                : ""
                                                }`}
                                        >
                                            Public transport
                                        </span>
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            {showCommuteMap && (
                                <Row style={{
                                    position: "sticky",
                                    top: "265px"
                                }}>
                                    <Col
                                        className=""
                                        style={{ marginBottom: "-150px", marginTop: "14px" }}
                                    >
                                        <ShowCommuteMap
                                            googleMapURL={process.env.REACT_APP_MAP_URL}
                                            loadingElement={
                                                <div
                                                    style={{ height: `90%`, width: "100%" }}
                                                />
                                            }
                                            containerElement={
                                                <div
                                                    style={{
                                                        height: "90%",
                                                        width: `100%`,
                                                        aspectRatio: "1/0.5",
                                                    }}
                                                />
                                            }
                                            mapElement={
                                                <div
                                                    style={{ height: `80%`, width: `100%` }}
                                                />
                                            }
                                            location={locationsShowCommute}
                                            commuteBuilding={commuteBuilding}
                                            travelMode={travelMode}
                                            onResponse={onResponse}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )

}

export default ClientMysaReview;