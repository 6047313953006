
import React, { useCallback, useEffect, useState } from "react";

import {

  $mysaRed,
  $mysaTeal,
  mysaTableTheme,
  overlayTriggerDelay,
  routingButton,
  tableStyles,
} from "../../../common/components-style";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import ViewEdit from "../../../images/icons/svg/outside/Icon-Function-View-Edit.svg";
import DataTable, { createTheme } from "react-data-table-component";


import Archive from "../../../images/icons/svg/outside/Icon-Function-Archive.svg";
import SweetAlert from "react-bootstrap-sweetalert";
import { useStoreActions, useStoreState } from "easy-peasy";
import { toast } from "react-toastify";


////////////////////// table theme setting ////////////////////////////////////
createTheme("mysaTable", mysaTableTheme, "light");

const PartnerList: React.FC<any> = ({
  loadForm,
  formOpen,
  setFormOpen,
  setPartnerId
}): JSX.Element => {
  /**
   * global action
   */
  const { onGetPartnerList, onUpdatePartner } = useStoreActions<any>((actions) => ({
    onGetPartnerList: actions.partner.onGetPartnerList,
    onUpdatePartner: actions.partner.onUpdatePartner,
  }));

  /**
   * global state
   */
  const { partnerListError, updatePartnerError, partnerListSuccess, updatePartnerSuccess } = useStoreState<any>(
    (state) => ({
      partnerListSuccess: state.partner.partnerListSuccess,
      partnerListError: state.partner.partnerListError,
      updatePartnerSuccess: state.partner.updatePartnerSuccess,
      updatePartnerError: state.partner.updatePartnerError,
    })
  );

  /**
   * local state
   */
  const [archivePopop, setArchivePopop] = useState<boolean>(false);

  const [propertyArchive, setPropertyArchive] = useState<any>("");
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    onGetPartnerList();
  }, [onGetPartnerList]);



  useEffect(() => {
    if (partnerListSuccess) {
      setData(partnerListSuccess?.data);
    }

  }, [partnerListSuccess]);


  const onPartnerArchive = useCallback((partner) => {

    const data = {
      id: partner?.id,
      isDeleted: true
    };

    onUpdatePartner(data);
  },
    [onUpdatePartner]
  );

  /**
   * manage state
   */
  useEffect(() => {
    if (updatePartnerSuccess?.data) {
 
      toast.success("Partner archived successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      onGetPartnerList();
    }

    if (updatePartnerError) {
      toast.error(updatePartnerError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  }, [onGetPartnerList, updatePartnerError, updatePartnerSuccess]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const columns = [
    {
      name: "Name of Partner",
      selector: (row) => row.name,
    },
    {
      name: "Type",
      selector: (row) => row.partnerType.name,
    },
    {
      name: "Contact",
      selector: (row) => row.contact,
    },
    {
      name: "Contact Email",
      selector: (row) => row.contactEmail,
    },
    {
      name: "Contact Tel No",
      selector: (row) => row.contactTelephone,
    },
    {
      name: "MYSA Owner",
      selector: (row) => row.mysaOwner,
    },
    {
      name: "Status",
      cell: (row) => {
        let color = "";
        if (row.status === 'active') color = $mysaTeal;
        if (row.status === 'inActive') color = $mysaRed;

        return (
          <span
            style={{ color: color, fontWeight: "bold" }}
            className="text-capitalize"
          >
            {row.status}
          </span>
        );
      },
    },

    {
      name: "Action",
      button: true,
      cell: (row: any) => {
        // let active = (row.status === 'active') ? 1 : 0;

        return (
          <>
            <OverlayTrigger
              placement="top"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id="tooltip-table-top">View / Edit</Tooltip>}
            >
              <img
                className="iconSizes"
                src={ViewEdit}
                alt=""
                onClick={() => {
                  loadForm(row._id);

                }}
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id="tooltip-table-top">Archive</Tooltip>}
            >
              <img
                className="iconSizes"
                src={Archive}
                alt=""
                onClick={() => {
                  const data = {
                    id: row?._id,

                    // propertyStatus: PropertyStatus.archived,
                  };
                  setPropertyArchive(data);
                  setArchivePopop(true);
                }}
              />
            </OverlayTrigger>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="mt-2">
        {
          <>
            <Row className="btn_wrapper mb-2">
              <Col xs={2}>

              </Col>
              <Col xs={8}></Col>
              <Col xs={2}>

                <Button
                  hidden={formOpen}
                  style={routingButton}
                  id="routing-button"
                  onClick={() => {
                    setFormOpen(true);
                    setPartnerId(null);
                  }}
                  className="float-right mb-3"
                >
                  <b>Create New Partner</b>
                </Button>

              </Col>
            </Row>
          </>
        }
        <DataTable
          columns={columns}
          data={data}
          pagination
          striped={true}
          customStyles={tableStyles}
          theme="mysaTable"
          fixedHeader={true}
          noDataComponent={"Updating records"}
        />
      </div>

      <SweetAlert
        show={archivePopop}
        warning
        showCancel={true}
        focusCancelBtn={true}
        confirmBtnText="Yes archive it!"
        confirmBtnBsStyle="danger"
        title="Are you sure you wish to archive this partner?"
        onConfirm={() => {
          onPartnerArchive(propertyArchive);
          setArchivePopop(false);
        }}
        onCancel={() => {
          setArchivePopop(false);
        }}
      ></SweetAlert>
    </>
  );
};

export default PartnerList;
