import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getLoggedUserType, isHotelModuleAvailable } from '../../../../common/functions';
import AccessDenied from '../../../../components/common/AccessDenied';
import { UserAccess, UserGroups } from '../../.././../common/constants';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { navContentStyle, navStyle, tabDivStyle } from '../../../../common/components-style';
import HotelDetailForm from './HotelDetailsForm';
import TransportDetailForm from './TransportDetailForm';
import GuestComfortDetailForm from './GuestComfortDetailForm';
import HotelImages from './HotelImages';
import HotelInvoice from './HotelInvoice';
import HotelCheckInProcess from './HotelCheckInProcess';


const HotelForm: React.FC<any> = ({ allInOneHotelBreadCrumb, hotelId, setFormOpen, selectedBrand, loadData, tab, location }): JSX.Element => {
	const [tabKey, setTabKey] = useState<any>('details');
	const history = useHistory();
	const [isAccess, setIsAccess] = useState<boolean>(false);

	useEffect(() => {
		if (location?.state?.defaultKey) {
			setTabKey(location?.state?.defaultKey);
		}
	}, [location?.state]);

	const redirectHotel = () => {
		if (isHotelModuleAvailable(selectedBrand.key, hotelId, UserAccess.hotel_access.details.code)) {
			history.push('/admin/inventory-management', { hotelId: hotelId, selectedBrand: selectedBrand, allInOneHotelBreadCrumb: allInOneHotelBreadCrumb })
		} else {
			setIsAccess(true);
		}

	}

	return (
		<>
			<div style={tabDivStyle}>
				<Tab.Container defaultActiveKey="details" key={tabKey} activeKey={tabKey} onSelect={(tab) => setTabKey(tab)}>
					<Row >
						<Col sm={2}>
							<Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
								<div style={{ marginTop: '0%' }}>
									<Nav.Item><Nav.Link eventKey="details">Hotel Details</Nav.Link></Nav.Item>
									{hotelId && <Nav.Item><Nav.Link eventKey="transport">Transport</Nav.Link></Nav.Item>}
									{hotelId && <Nav.Item><Nav.Link eventKey="guestComfort">Guest Comfort</Nav.Link></Nav.Item>}
									{hotelId && <Nav.Item><Nav.Link eventKey="hotelUsers">Hotel Users</Nav.Link></Nav.Item>}
									{hotelId && <Nav.Item><Nav.Link eventKey="hotelInvoice">Hotel Invoice</Nav.Link></Nav.Item>}
									{hotelId && getLoggedUserType() === UserGroups.super_admin && <Nav.Item><Nav.Link eventKey="audit">Hotel Audit</Nav.Link></Nav.Item>}
									{hotelId && <Nav.Item><Nav.Link eventKey="images">Hotel Images</Nav.Link></Nav.Item>}
									{hotelId && <Nav.Item><Nav.Link eventKey="amenities">Hotel Amenities</Nav.Link></Nav.Item>}
									{hotelId && <Nav.Item><Nav.Link eventKey="checkIn">Check-in</Nav.Link></Nav.Item>}
									{hotelId && <Nav.Item><Nav.Link eventKey="gdsCodes">GDS Codes</Nav.Link></Nav.Item>}
									{hotelId && <Nav.Item><Nav.Link eventKey="inventory" onClick={redirectHotel}>Inventory Management</Nav.Link></Nav.Item>}
								</div>
							</Nav>
						</Col>
						<Col sm={10}>
							<Tab.Content >
								<Tab.Pane eventKey="details" >
									<div style={navContentStyle}>
										{isHotelModuleAvailable(selectedBrand.key, hotelId, UserAccess.hotel_access.details.code) ? <HotelDetailForm allInOneHotelBreadCrumb={allInOneHotelBreadCrumb} hotelId={hotelId} selectedBrand={selectedBrand} setFormOpen={setFormOpen} loadData={loadData} tab={tab} /> : <AccessDenied />}
									</div>
								</Tab.Pane>
								{hotelId && <Tab.Pane eventKey="transport">
									<div style={navContentStyle}>
										{isHotelModuleAvailable(selectedBrand.key, hotelId, UserAccess.hotel_access.details.code) ? <TransportDetailForm allInOneHotelBreadCrumb={allInOneHotelBreadCrumb} hotelId={hotelId} selectedBrand={selectedBrand} setFormOpen={setFormOpen} tab={tab} /> : <AccessDenied />}
									</div>
								</Tab.Pane>}
								{hotelId && <Tab.Pane eventKey="guestComfort">
									<div style={navContentStyle}>
										{isHotelModuleAvailable(selectedBrand.key, hotelId, UserAccess.hotel_access.details.code) ? <GuestComfortDetailForm allInOneHotelBreadCrumb={allInOneHotelBreadCrumb} hotelId={hotelId} selectedBrand={selectedBrand} setFormOpen={setFormOpen} tab={tab} /> : <AccessDenied />}
									</div>
								</Tab.Pane>}
								{hotelId && <Tab.Pane eventKey="images">
									<div style={navContentStyle}>
										{isHotelModuleAvailable(selectedBrand.key, hotelId, UserAccess.hotel_access.details.code) ? <HotelImages allInOneHotelBreadCrumb={allInOneHotelBreadCrumb} brandId={selectedBrand.key} hotelId={hotelId} setFormOpen={setFormOpen} selectedBrand={selectedBrand} tab={tab} /> : <AccessDenied />}
									</div>
								</Tab.Pane>}
								{hotelId && <Tab.Pane eventKey="hotelInvoice">
									<div style={navContentStyle}>
										{isHotelModuleAvailable(selectedBrand.key, hotelId, UserAccess.hotel_access.details.code) ? <HotelInvoice allInOneHotelBreadCrumb={allInOneHotelBreadCrumb} brandId={selectedBrand.key} hotelId={hotelId} setFormOpen={setFormOpen} selectedBrand={selectedBrand} tab={tab} /> : <AccessDenied />}
									</div>
								</Tab.Pane>}
								{hotelId && <Tab.Pane eventKey="checkIn">
									<div style={navContentStyle}>
										{isHotelModuleAvailable(selectedBrand.key, hotelId, UserAccess.hotel_access.details.code) ? <HotelCheckInProcess allInOneHotelBreadCrumb={allInOneHotelBreadCrumb} brandId={selectedBrand.key} hotelId={hotelId} setFormOpen={setFormOpen} selectedBrand={selectedBrand} tab={tab} /> : <AccessDenied />}
									</div>
								</Tab.Pane>}
							</Tab.Content>
						</Col>
					</Row>
				</Tab.Container>
			</div>
		</>
	)
};

export default HotelForm;