import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Table, Image, message } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _, { property } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CheckInProcessForm from './CheckInProcessForm';


const CheckInProcessList: React.FC<any> = (): JSX.Element => {
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const [checkInProcessList, setCheckInProcessList] = useState<any>([]);
  const [checkInProcessDetails, setCheckInProcessDetails] = useState<any>(null);
  const [checkInProcessId, setCheckInProcessId] = useState<number | null>(null);

  ////////////////////////////////////////////////////////////////////////////////////////////
  const loadForm = useCallback((data) => {
    setFormOpen(true);
    setCheckInProcessDetails(data);
    if (data !== null) {
      setCheckInProcessId(data.key);
    } else {
      setCheckInProcessId(null);
    }
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { getCheckInProcessDetail, updateCheckInProcessDetail } = useStoreActions<any>((actions) => ({
    getCheckInProcessDetail: actions.property.getCheckInProcessDetail,
    updateCheckInProcessDetail: actions.property.updateCheckInProcessDetail,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { getCheckInProcessListSuccess, getCheckInProcessListError, updateCheckInProcessError, removeCheckInProcessSuccess } = useStoreState<any>((state) => ({
    getCheckInProcessListSuccess: state.property.getCheckInProcessListSuccess,
    getCheckInProcessListError: state.property.getCheckInProcessListError,
    updateCheckInProcessError: state.property.updateCheckInProcessError,
    removeCheckInProcessSuccess: state.property.removeCheckInProcessSuccess,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getCheckInProcessDetail();
  }, [getCheckInProcessDetail]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getCheckInProcessListSuccess) {
      setCheckInProcessList(getCheckInProcessListSuccess.data);
    }
    if (getCheckInProcessListError) {
      message.error(getCheckInProcessListError.message)
    }

    if (removeCheckInProcessSuccess) {
      message.success('Check-in process removed successfully');
      getCheckInProcessDetail();
    }

    if (updateCheckInProcessError) {
      message.error(updateCheckInProcessError.message)
    }

  }, [getCheckInProcessListSuccess, getCheckInProcessListError, removeCheckInProcessSuccess, updateCheckInProcessError, getCheckInProcessDetail]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  const removeData = useCallback((values) => {
    const data = {
      id: values.key,
      isDeleted: true
    }
    updateCheckInProcessDetail(data);

  }, [updateCheckInProcessDetail]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const columns = [
    {
      title: 'Icon',
      dataIndex: 'iconLocation',
      key: 'iconLocation',
      render: (iconLocation: string) => {

        return (<div>
          <Image style={{ width: '100%', height: '72px' }} className="object-contan" fallback={iconLocation} src={iconLocation} />
        </div>)
      },
    },
    { title: 'Name', dataIndex: 'name', key: 'name', outerWidth: '75px' },
    { title: 'Property Type', dataIndex: 'propertyType', key: 'propertyType' },
    {
      title: 'Display working hours',
      dataIndex: '',
      key: 'displayWorkingHours',
      render: (data: any) => {

        return (<div className={`${data.displayWorkingHours === 'active' ? 'text-green-500' : 'text-red-500'}`}>
          {data.displayWorkingHours}
        </div>)
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'status',
      render: (data: any) => {
        return (<div>
          <Button onClick={() => loadForm(data)} className="border-none" ><EditOutlined /></Button>
          <Button onClick={() => removeData(data)} className="border-none" ><DeleteOutlined /></Button>
        </div>)
      },
    },
  ];

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const data = useMemo(() => {
    return _.map(checkInProcessList, checkIn => {
      return {
        key: checkIn._id,
        name: checkIn.name,
        propertyType: checkIn.propertyType,
        iconLocation: checkIn.iconLocation,
        // status: checkIn.status,
        displayWorkingHours: checkIn.displayWorkingHours,
        alternativeCheckInProcess: checkIn.alternativeCheckInProcess
      }
    })
  }, [checkInProcessList]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div>
      <div className="flex justify-between">
        <p className="text-2xl mb-10">Check-in Process Details</p>
        <Button hidden={formOpen} onClick={() => { loadForm(null) }} className="btn_design text-white m-1">Create New</Button>
        <Button hidden={!formOpen} onClick={() => setFormOpen(false)} className="btn_design text-white m-1">Show List</Button>
      </div>
      {formOpen && <CheckInProcessForm setFormOpen={setFormOpen} checkInProcessDetails={checkInProcessDetails} checkInProcessId={checkInProcessId} />}
      <div className="mb-1" hidden={formOpen}>
        <Table columns={columns} dataSource={data} />
      </div>
    </div>
  )
};

export default CheckInProcessList;