import { useStoreActions } from 'easy-peasy';
import React, { useCallback } from 'react'
import { Row, Col, Breadcrumb, } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const BreadCrumbPage: React.FC<any> = ({ allInOnePropertyBreadCrumb,allInOneHotelBreadCrumb, isPageName, propertyData,hotelData, selectedBrand, setFormOpen, inventoryData, selectedProperty, inventoryId, tab }): JSX.Element => {

   const { getProperties,getInventoryList } = useStoreActions<any>((actions) => ({
      getProperties: actions.property.getProperties,
      // getHotels:actions.hotel.getHotels,
      getInventoryList: actions.inventory.getInventoryList,
   }));

   //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   const refreshProperties = useCallback(() => {
      const params: any = {};
      if (selectedBrand) {
         params.brandId = selectedBrand?.key;
      }

      getProperties(params)
   }, [getProperties, selectedBrand]);

   // const refreshHotels = useCallback(() => {
   //    const params: any = {};
   //    if (selectedBrand) {
   //       params.brandId = selectedBrand?.key;
   //    }

   //    getHotels(params)
   // }, [getHotels, selectedBrand]);

   /////////////////////////////////////////////////////////////////////////////////////////////////////////
   const refreshInventories = useCallback(() => {
      const params: any = {};
      if (selectedProperty) {
         params.propertyId = selectedProperty._id;
      }
      getInventoryList(params);
   }, [getInventoryList, selectedProperty]);

   return (
      <div>
         {/* brand to property  */}
         {isPageName === 'Property' && !allInOnePropertyBreadCrumb &&
            <Row>
               <Col xs={12}><div className="">
                  <Breadcrumb className="mb-10">
                     <Breadcrumb.Item>
                        <Link to={{ pathname: "/admin/brand-management", state: { selectedBrand: selectedBrand } }}>{selectedBrand?.name}</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item >
                        <Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); refreshProperties(); }}>Property Management</Link>
                     </Breadcrumb.Item>
                     {!propertyData && <Breadcrumb.Item active>New Property</Breadcrumb.Item>}
                     {propertyData && <Breadcrumb.Item active >{propertyData?.propertyName} - {propertyData?.city}</Breadcrumb.Item>}
                  </Breadcrumb>
               </div></Col>
            </Row>
         }

         {/* direct property management */}
         {isPageName === 'Property' && allInOnePropertyBreadCrumb &&
            <Row>
               <Col xs={12}><div className="">
                  <Breadcrumb className="mb-10">
                     <Breadcrumb.Item>
                        <Link to={{ pathname: "/admin/brand-management", state: { selectedBrand: selectedBrand, allInOnePropertyBreadCrumb: allInOnePropertyBreadCrumb, tab: tab } }}>Property Management</Link>
                     </Breadcrumb.Item>
                     {!propertyData && <Breadcrumb.Item active>New Property</Breadcrumb.Item>}
                     {propertyData && <Breadcrumb.Item active >{propertyData?.propertyName} - {propertyData?.city}</Breadcrumb.Item>}
                  </Breadcrumb>
               </div></Col>
            </Row>
         }
         {/* brand to property with inventory*/}
         {isPageName === 'Inventory' && !allInOnePropertyBreadCrumb &&
            <Row>
               <Col xs={12}><div className="">
                  <Breadcrumb className="mb-10">
                     <Breadcrumb.Item>
                        <Link to={{ pathname: "/admin/brand-management", state: { selectedBrand: selectedBrand } }}>{selectedBrand?.name}</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>
                        <Link to={{ pathname: "/admin/property-management", state: { selectedProperty: selectedProperty, allInOnePropertyBreadCrumb: allInOnePropertyBreadCrumb, isBack: true } }}>Property Management</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>
                        <Link to={{ pathname: "/admin/property-management", state: { selectedProperty: selectedProperty, allInOnePropertyBreadCrumb: allInOnePropertyBreadCrumb } }}>{selectedProperty?.propertyName} - {selectedProperty?.city}</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item >
                        <Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); refreshInventories(); }}>Inventory Management</Link>
                     </Breadcrumb.Item>
                     {(!inventoryId || !inventoryData) && <Breadcrumb.Item active>New inventory</Breadcrumb.Item>}
                     {(inventoryData && inventoryId) && <Breadcrumb.Item active> {inventoryData?.moderate?.details?.new_value ? inventoryData?.moderate?.details?.new_value : inventoryData?.accommodationDetail}</Breadcrumb.Item>}
                  </Breadcrumb>
               </div></Col>
            </Row>
         }

         {/* direct property management */}
         {isPageName === 'Inventory' && allInOnePropertyBreadCrumb &&
            <Row>
               <Col xs={12}><div className="">
                  <Breadcrumb className="mb-10">
                     <Breadcrumb.Item>
                        <Link to={{ pathname: "/admin/brand-property-management" }}>Property Management</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>
                        <Link to={{ pathname: "/admin/property-management", state: { selectedProperty: selectedProperty, allInOnePropertyBreadCrumb: allInOnePropertyBreadCrumb } }}>{selectedProperty?.propertyName} - {selectedProperty?.city}</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item >
                        <Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); refreshInventories(); }}>Inventory Management</Link>
                     </Breadcrumb.Item>
                     {(!inventoryId || !inventoryData) && <Breadcrumb.Item active>New inventory</Breadcrumb.Item>}
                     {(inventoryData && inventoryId) && <Breadcrumb.Item active> {inventoryData?.moderate?.details?.new_value ? inventoryData?.moderate?.details?.new_value : inventoryData?.accommodationDetail}</Breadcrumb.Item>}
                  </Breadcrumb>
               </div></Col>
            </Row>
         }
          {/* brand to hotel  */}
         {isPageName === 'Hotel' && !allInOneHotelBreadCrumb &&
                     <Row>
                        <Col xs={12}><div className="">
                           <Breadcrumb className="mb-10">
                              <Breadcrumb.Item>
                                 <Link to={{ pathname: "/admin/brand-management", state: { selectedBrand: selectedBrand } }}>{selectedBrand?.name}</Link>
                              </Breadcrumb.Item>
                              <Breadcrumb.Item >
                                 <Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false);  }}>Hotel Management</Link>
                              </Breadcrumb.Item>
                              {!hotelData && <Breadcrumb.Item active>New Hotel</Breadcrumb.Item>}
                              {hotelData && <Breadcrumb.Item active >{hotelData?.hotelName} - {hotelData?.city}</Breadcrumb.Item>}
                           </Breadcrumb>
                        </div></Col>
                     </Row>
                  }
                  {/* direct hotel management */}
            {isPageName === 'Hotel' && allInOneHotelBreadCrumb &&
                        <Row>
                           <Col xs={12}><div className="">
                              <Breadcrumb className="mb-10">
                                 <Breadcrumb.Item>
                                    <Link to={{ pathname: "/admin/brand-management", state: { selectedBrand: selectedBrand, allInOneHotelBreadCrumb: allInOneHotelBreadCrumb, tab: tab } }}>Hotel Management</Link>
                                 </Breadcrumb.Item>
                                 {!hotelData && <Breadcrumb.Item active>New Hotel</Breadcrumb.Item>}
                                 {hotelData && <Breadcrumb.Item active >{hotelData?.hotelName} - {hotelData?.city}</Breadcrumb.Item>}
                              </Breadcrumb>
                           </div></Col>
                        </Row>
                     }
      </div>
   )
}

export default BreadCrumbPage;
