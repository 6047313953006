import { message } from "antd";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useCallback, useEffect, useState } from "react";
import HotelForm from "./form/HotelForm";
import HotelList from "./HotelList";
import { getStorage, setStorage } from "../../../common/storage";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { useHistory } from 'react-router-dom';

const HotelHome: React.FC<any> = ({ location }): JSX.Element => {
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const [allInOneHotelBreadCrumb, setAllInOneHotelBreadCrumb] = useState<boolean>(false);
  const [hotels, setHotels] = useState<Array<Object> | null>([]);
  const [hotelId, setHotelId] = useState<number | null>(null);
  const [extId, setExtId] = useState<number | null>(null);
  const [selectedBrand, setSelectedBrand] = useState<any>(null);
  const history = useHistory<any>();

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { getHotels } = useStoreActions<any>((actions) => ({
    getHotels: actions.hotel.getHotels,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { getHotelsSuccess, getHotelsError } = useStoreState<any>(
    (state) => ({
      getHotelsSuccess: state.hotel.getHotelsSuccess,
      getHotelsError: state.hotel.getHotelsError,
    })
  );
  ///////////location?.state/////////////////////////////////////////////////////////////////////////////////////////
  const loadForm = useCallback((id, extId) => {
    setExtId(extId);
    setFormOpen(true);
    setHotelId(id);
  }, []);

  useEffect(() => {
   
    if (location?.state) {
        setFormOpen(location?.state?.formOpen);
         setAllInOneHotelBreadCrumb(location?.state?.allInOneHotelBreadCrumb);
         setHotelId(location?.state?.hotelId);
    }
}, [history, location?.state]);

  /**
   * special navigate
   */
  useEffect(() => {
    if (location.state?.allInOneHotel) {
      loadForm(
        location.state?.allInOneHotel?._id,
        location.state?.allInOneHotel?.externalId
      );
      setAllInOneHotelBreadCrumb(true);
      setStorage("myo_selected_hotel", location.state?.allInOneHotel);
      const transformBrandData = (brandData) => ({
        key: brandData._id,
        extId: null, 
        image: brandData.image,
        name: brandData.name,
        brandStatus: brandData.brandStatus,
        countPending: 0,
        countIncomplete: 0,
        myoManagementReference: brandData.myoManagementReference
      });
      setStorage("myo_selected_brand", transformBrandData(location.state?.allInOneHotel?.brand));
    } else {
      if (location.state?.allInOneHotelBreadCrumb) {
         const hotel = getStorage("myo_selected_hotel");
        loadForm(hotel?._id, hotel?.externalId);
        setAllInOneHotelBreadCrumb(
          location.state?.allInOneHotelBreadCrumb
        );
      }
    }
  }, [loadForm, location.state?.allInOneHotel, location.state?.allInOneHotelBreadCrumb]);
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const brand = getStorage("myo_selected_brand");
    setSelectedBrand(brand);
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (!location.state?.isBack && !location.state?.selectedHotel) {
      const params: any = {};

      if (location.state?.brandId) {
        params.brandId = location.state?.brandId;
      }

      getHotels(params);
    }
  }, [getHotels, location]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getHotelsError) {
      message.success(getHotelsError.message);
    }

    if (getHotelsSuccess) {
      setHotels(getHotelsSuccess.data);
    }
  }, [getHotelsError, getHotelsSuccess]);


  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const loadData = useCallback((id) => {
    setHotelId(id);
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (location.state?.isBack && location.state?.selectedHotel) {
      const params: any = {};
      if (location.state?.selectedHotel) {
        params.brandId = location.state?.selectedHotel?.brandId?._id;
      }
      getHotels(params);
    } else {
      if (location.state?.selectedHotel) {
        loadForm(
          location.state?.selectedHotel._id,
          location.state?.selectedHotel?.externalId
        );
      }
    }
  }, [loadForm, location, getHotels]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const refreshHotels = useCallback(() => {
    const params: any = {};

    if (location.state?.brandId) {
      params.brandId = location.state?.brandId;
    }
    if (location.state?.selectedHotel) {
      params.brandId = location.state?.selectedHotel.brandId?._id;;
    }
    getHotels(params);
  }, [getHotels, location]);
  return (
    <div className="hotel-home">
      <div className="flex justify-between" style={{ padding: "0 6px" }}>
        {!selectedBrand && <p className="text-2xl mb-10"></p>}
        {!formOpen && selectedBrand && (
          <Breadcrumb className="mb-4">
            <Breadcrumb.Item>
              <Link
                to={{
                  pathname: "/admin/brand-management",
                  state: { selectedBrand: selectedBrand },
                }}
              >
                {selectedBrand.name}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active={formOpen ? false : true}>
              <Link
                to=""
                onClick={(e) => {
                  e.preventDefault();
                  setFormOpen(false);
                  refreshHotels();
                }}
              >
                Hotel Management
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        )}
      </div>
      {!formOpen && (
        <HotelList
          setHotelId={setHotelId}
          setFormOpen={setFormOpen}
          formOpen={formOpen}
          selectedBrand={selectedBrand}
          hotelId={hotelId}
          hotels={hotels}
          loadForm={loadForm}
          refreshHotels={refreshHotels}
        />
      )}
      {formOpen && (
        <HotelForm
          allInOneHotelBreadCrumb={allInOneHotelBreadCrumb}
          tab={"hotels"}
          hotelId={hotelId}
          extId={extId}
          setFormOpen={setFormOpen}
          selectedBrand={selectedBrand}
          loadData={loadData}
          location = {location}
        />
      )}
    </div>
  );
};

export default HotelHome;
