import { Form, Col, Row, Button } from "react-bootstrap";
import { FC, useCallback, useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";

import { UserGroups } from "../../../../common/constants";
import { getLoggedUserType } from "../../../../common/functions";
import { useForm, SubmitHandler } from "react-hook-form";
import { RfpProcessCreateFormInput } from "../../../../common/interfaces";

import { toast } from 'react-toastify';
import { formButton } from "../../../../common/components-style";
import RFPBreadCrumbPage from "../../../common/RFPBredcrumPage";


const InvitationDetail: FC<any> = ({ rfpProcessId, programmeId, setFormOpen, reload, selectedClient, selectedProgramme }): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);

    const [selectedClientName, setSelectedClientName] = useState<string>("");
    const [selectedProgrammeName, setSelectedProgrammeName] = useState<string>("");

    const [selectedProgrammeId, setSelectedProgrammeId] = useState<string>("");
    const [rfpProcessDetails, setRfpProcessDetails] = useState<any>();



    const [editEnable, setEditEnable] = useState<boolean>(false);
    const { register, handleSubmit, formState: { errors }, } = useForm<RfpProcessCreateFormInput>();





    const {
        getCorporateProgrammeById,
        getClientLocationByProgrammeId,
        updateInvitationDetails,
        getRfpProcessById
    } = useStoreActions<any>((actions) => ({
        getClientLocationByProgrammeId: actions.client.getClientLocationByProgrammeId,
        getCorporateProgrammeById: actions.client.getCorporateProgrammeById,
        updateInvitationDetails: actions.rfpProcess.updateInvitationDetails,
        getRfpProcessById: actions.rfpProcess.getRfpProcessById,
    }))

    const {
        getCorporateProgrammeByIdSuccess,
        getCorporateProgrammeByIdError,
        getClientLocationByProgrammeIdSuccess,
        updateInvitationDetailsSuccess,
        rfpProcessByIdSuccess,
        updateInvitationDetailsError
    } = useStoreState<any>((state) => ({
        getCorporateProgrammeByIdSuccess: state.client.getCorporateProgrammeByIdSuccess,
        getCorporateProgrammeByIdError: state.client.getCorporateProgrammeByIdError,
        getClientLocationByProgrammeIdSuccess: state.client.getClientLocationByProgrammeIdSuccess,
        getClientLocationByProgrammeIdError: state.client.getClientLocationByProgrammeIdError,
        updateInvitationDetailsSuccess: state.rfpProcess.updateInvitationDetailsSuccess,
        rfpProcessByIdSuccess: state.rfpProcess.rfpProcessByIdSuccess,
        updateInvitationDetailsError: state.rfpProcess.updateInvitationDetailsError,
    }));



    const callClientLocationList = useCallback(() => {
        if (selectedProgrammeId) {
            getClientLocationByProgrammeId(selectedProgrammeId);
        }
    }, [getClientLocationByProgrammeId, selectedProgrammeId])

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        callClientLocationList();
    }, [callClientLocationList, selectedProgrammeId]);

    useEffect(() => {
        if (getClientLocationByProgrammeIdSuccess) {
        }

    }, [getClientLocationByProgrammeIdSuccess]);

    // set the programme id if exists ////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        setSelectedProgrammeId(programmeId);
    }, [programmeId]);
    // to call the api to get the rfp process by id ///////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (rfpProcessId) {
            getRfpProcessById(rfpProcessId);
            setEditEnable(false);
        } else {
            setEditEnable(true);
        }
    }, [getRfpProcessById, rfpProcessId]);

    // to set the getRfpProcessById response //////////////////////////////////////////////////////////////////////////////////////////


    useEffect(() => {
        if (rfpProcessByIdSuccess?.data) {

            setRfpProcessDetails(rfpProcessByIdSuccess?.data);

        }

    }, [rfpProcessByIdSuccess?.data]);


    useEffect(() => {
        if (updateInvitationDetailsSuccess) {

            setLoading(false);
            // reload();
            if (updateInvitationDetailsSuccess?.data) {
            toast.success("RFP process updated successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
            if (updateInvitationDetailsSuccess?.data?._id) {
                getRfpProcessById(updateInvitationDetailsSuccess?.data?._id);
            }
            setEditEnable(false);
        }
        if (updateInvitationDetailsError) {
            setLoading(false);
            toast.error(updateInvitationDetailsError?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEditEnable(false);
        }
        setLoading(false);
    }, [getRfpProcessById, updateInvitationDetailsError, updateInvitationDetailsSuccess]);


    // setting the programme data to the local state ///////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getCorporateProgrammeByIdSuccess?.data) {
            const { clientId, programmeName, _id } = getCorporateProgrammeByIdSuccess?.data;

            setSelectedClientName(clientId.companyName);
            setSelectedProgrammeId(_id);
            setSelectedProgrammeName(programmeName);
            if (!rfpProcessId) {
                // setFormSelectedStartDate(moment(new Date()));
            }
        }
        if (getCorporateProgrammeByIdError) {
            toast.error(getCorporateProgrammeByIdError?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });

        }
    }, [getCorporateProgrammeByIdError, getCorporateProgrammeByIdSuccess?.data, rfpProcessId]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (selectedProgrammeId) {
            getCorporateProgrammeById(selectedProgrammeId);
        }
    }, [getCorporateProgrammeById, selectedProgrammeId]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const onSubmit: SubmitHandler<RfpProcessCreateFormInput> = (values) => {
        setLoading(true);

        const formattedData = {
            _id: rfpProcessId,
            overviewForInvite: values?.overviewForInvite,
            keyClientRequirement: values?.keyClientRequirement
        }
        updateInvitationDetails(formattedData);


    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const changeFormEditable = useCallback(() => {
        if (editEnable) {
            setEditEnable(false);
        } else {
            setEditEnable(true);
        }
    }, [editEnable]);


    // 	---------------------------------------------------------------------------------------------------
    return (
        <>

            {rfpProcessId &&
                <RFPBreadCrumbPage isPageName="RFP" selectedClient={selectedClient} selectedProgramme={selectedProgramme} selectedClientName={selectedClientName} selectedProgrammeName={selectedProgrammeName} rfpProcessId={rfpProcessId} programmeId={programmeId} startDate={rfpProcessDetails?.rfpName}></RFPBreadCrumbPage>
            }
            <div className="create-update-wrapper" style={{ marginLeft: "40px" }}>
                <Form onSubmit={handleSubmit(onSubmit)} >
                    <Row className='formSpace'>
                        <Col md={3} style={{ textAlign: 'right', }}>
                            <Form.Label>{<span className='requiredMark'>*</span>} Overview for invite</Form.Label>
                        </Col>

                        <Col md={6} style={{ textAlign: 'left' }}>
                            {!editEnable && <p className="lead">{rfpProcessDetails?.overviewForInvite}</p>}
                            {editEnable && <Form.Control rows={5} as={`textarea`} className={`${errors.overviewForInvite ? 'is-invalid' : ''}`} defaultValue={rfpProcessDetails?.overviewForInvite} {...register("overviewForInvite", { required: true })} />}
                            {editEnable && <div className="invalid-feedback">Please input overviewForInvite!</div>}
                        </Col>
                    </Row>

                    <Row className='formSpace'>
                        <Col md={3} style={{ textAlign: 'right' }}>
                            <Form.Label>{<span className='requiredMark'>*</span>}Key client requirements</Form.Label>
                        </Col>

                        <Col md={6} style={{ textAlign: 'left' }}>
                            {!editEnable && <p className="lead">{rfpProcessDetails?.keyClientRequirement}</p>}
                            {editEnable && <Form.Control rows={5} as={`textarea`} className={`${errors.keyClientRequirement ? 'is-invalid' : ''}`} defaultValue={rfpProcessDetails?.keyClientRequirement} {...register("keyClientRequirement", { required: true })} />}
                            {editEnable && <div className="invalid-feedback">Please input keyClientRequirement!</div>}
                        </Col>
                    </Row>

                    <Row style={{ paddingTop: '30px' }} className='formSpace'>
                        <Col md={3} style={{ textAlign: 'right' }}>

                        </Col>
                        <Col md={3} >
                            {getLoggedUserType() === UserGroups.super_admin && !loading && editEnable && rfpProcessId && <Button id='create-button' type="submit">{'Update'}</Button>}

                            {
                                !loading && !editEnable && rfpProcessId && <Button id='edit-button' style={formButton} onClick={changeFormEditable} >Edit</Button>
                            }



                        </Col>
                    </Row>

                </Form>
            </div>

        </>
    )

}

export default InvitationDetail;
